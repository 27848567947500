import {Redirect, Route, withRouter} from 'react-router-dom';
import {
  IonApp,
  IonContent, IonIcon, IonItem,
  IonLabel, IonList, IonMenu, IonRouterLink,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs, IonToggle,
  isPlatform,
  setupIonicReact, useIonAlert,
  useIonLoading,
  useIonToast
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {
  app_alert, app_confirm,
  app_toast, change_use_bio_to_login, dismiss_active_modals,
  goto_dash,
  goto_dash_force,
  goto_home,
  goto_rm,
  hide_loading, hide_top_menu,
  isdef,
  isOnScreen,
  logout, ls_set,
  swl
} from "./Helper"
import {Device} from '@capacitor/device';
import {on} from './event'
import {StatusBar} from '@awesome-cordova-plugins/status-bar';
// import {SB} from 'cordova-plugin-statusbar-latest/www/statusbar';
// import {SecureStoragePlugin} from 'capacitor-secure-storage-plugin'
import Create from './pages/Create';
import Login from './pages/Login';
import ls from 'local-storage'

const lsm = ls
import Forget from './pages/Forget';
import Dashboard from './pages/Dashboard';
import _, {isNumber} from 'lodash'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/utils.bundle.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
// import './App.css';
import "./scss/ccm.scss";
import "./scss/dash.scss";
import User from "./User"
import './scss/rm.scss';
import './scss/vh.scss';
import Rm from "./pages/Rm"
import Vh from "./pages/Vh"
import Clog from "./pages/Clog"
import {useEffect, useState} from "react"
import {trigger} from './event'

import {NativeBiometric} from "capacitor-native-biometric";
import {StatusBarMB} from 'mb-statusbar-cplugin'
import {appsSharp, calendarSharp, carSharp, informationCircle} from "ionicons/icons"
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation'
import AppContext from "./components/AppContext.jsx"


const AUTO_LOGIN_THOLD = 3 //sec
// const BASE_IE511_URL = 'https://ie511.org/iecommuter/integrate';
const COMMUTER_URL = 'https://tdm.commuterconnections.org/mwcog/'
// const GMAP_KEY = 'AIzaSyBY-L-HhMKsNOeMDqH1kJZP7hS3G2SATWQ';//iecommuter key
export const MWCOG_URL = "com.mediabeef.mwcog"
let GEOCODER = null;
const GEOLOCATION_OPTIONS = {enableHighAccuracy: true};
window.GEOOPTIONS = {enableHighAccuracy: true}
const GMAP_KEY = 'AIzaSyC1RpnsU0y0yPoQSg1G_GyvmBmO5i1UH5E';//carpoolnow key
const is_login_and_commute_log = false
const wls = window.localStorage
import lsc from 'local-storage'
import {IS_DEBUG, IS_VERBOSE} from './env'

const GMAP_API_KEY = 'AIzaSyCyC1MXsxrpW-2xJOu1RgdmNqfP-8j9qFc'

setupIonicReact();
StatusBar.overlaysWebView(true)
StatusBar.styleLightContent()
StatusBar.backgroundColorByHexString('#001c42');
if (isPlatform('mobileweb') || isPlatform('desktop') || isPlatform('web')) {
  NativeBiometric.verifyIdentity = function () {
    return () => true
  }
  NativeBiometric.isAvailable = function () {
    return () => true
  }
  NativeBiometric.getCredentials = function () {
    return () => true
  }
  NativeBiometric.setCredentials = function () {
    return () => true
  }
}

import {App as Capp} from '@capacitor/app'
// import {Plugins} from '@capacitor/core'
// const {App: CPApp} = Plugins;
import moment from "moment"
import {menuController} from "@ionic/core/components"
import {useHistory} from "react-router"
import {bio_set_cred, performBiometricVerification} from "./BioHelper";

Capp.addListener('appUrlOpen', data => {
  console.log('App opened with URL:', data);
});

/*Capp.addListener('appRestoredResult', data => {
  console.log('CApp Restored state:', data)
});*/

const checkAppLaunchUrl = async () => {
  const {url} = await Capp.getLaunchUrl();

  console.log('App opened with URL: ' + url);
};

/**
 * @param mb_padding
 * @param ion_safe_area_bottom
 * @return {Promise<number[]>} The overridden values
 */
export async function fix_status_bar(mb_padding, ion_safe_area_bottom) {
  const dev_info = await Device.getInfo()
  const dev_model = dev_info?.model
  // app_toast(app,`dev model: ${dev_model}`, 20000)
  if (dev_model == 'Pixel 6a') {
    console.log(`adjust ${dev_model} 35px 38px`)
    return [35, 38]
  }
  return [mb_padding, ion_safe_area_bottom]
}

/**
 * cb for IonToggle bio_dont_ask_again
 * @param e
 */
const on_change_trigger_use_bio = function (e) {
  const new_bio_toggle = e.detail.checked
  console.log(`bio toggle e: `, new_bio_toggle)
  if (new_bio_toggle == false) {
    change_use_bio_to_login(false, app)
    ls_set('use_bio_to_login', false)
    lsm('BIO_DONT_ASK_AGAIN', true)
    return false
  }
  if (new_bio_toggle == true)
    lsm('BIO_DONT_ASK_AGAIN', false)
  change_use_bio_to_login(true, app)
}

export async function isBioAvail() {
  let result = false
  try {
    result = await NativeBiometric.isAvailable();
  } catch (e) {
    console.log(`NativeBiometric.isAvailable error: `, e, e?.errorCode)
    return result?.isAvailable == true
  }
  console.warn(`native bio avail: `, result)
  return !!result.isAvailable;
}

let app = {
  build_version: 'v3.3.5',
  is_ios: null, is_android: null, IS_LOCAL: false,
  present_toast: null,//must be set to useIonToast()
  dismiss_toast: null,//must be set to useIonToast()
  user: {
    password_saved: null,
    home_address_location: null,
    work_address_location: null,
    /**
     * extracts non-home address from .addresses
     * @returns null|{}
     */
    non_home_addresses: function () {
      let res = null;
      if (typeof this.addresses !== 'object' || this.addresses === null || this.addresses.length < 1) {
        return res;
      }
      res = this.addresses.filter(function (v) {
        return v.type !== 'HOME';
      });
      return res;
    },
    home_address_geodecode_success: function (result, status) {
      if (status === 'OK') {
        app.user.home_address_location = result.shift().geometry.location;
        if (typeof app.user.home_address_location.lat() === 'number') {
          app.user.start_address_lat = app.user.home_address_location.lat();
        }
        if (typeof app.user.home_address_location.lng() === 'number') {
          app.user.start_address_lng = app.user.home_address_location.lng();
        }
      }
    },
    work_address_geodecode_success: function (result, status) {
      if (status === 'OK') {
        app.user.work_address_location = result.shift().geometry.location;
        if (typeof app.user.work_address_location.lat() === 'number') {
          app.user.end_address_lat = app.user.work_address_location.lat();
        }
        if (typeof app.user.work_address_location.lng() === 'number') {
          app.user.end_address_lng = app.user.work_address_location.lng();
        }
      }
    },
    geodecode_saved_addresses: function () {
      $.each(this.non_home_addresses(), function (i, v) {
        /*GEOCODER.geocode({address: app.user.full_address_from_address_obj(v)}, function (result, status){
          if (status === 'OK') {
            result = result.shift();
            v.lat = result.geometry.location.lat();
            v.lng = result.geometry.location.lng();
          }
        });*/
      });
    },
    is_logged_in: function () {
      return this.id_member;
    },
    match_pref_abbr: function () {
      if (!app.user.hasOwnProperty('match_preference')) {
        return false;
      }
      let match_pref_abbr = 'MP3';
      switch (app.user.match_preference) {
        case 'All Matches':
          match_pref_abbr = 'MP1';
          break;
        case "Company Matching Only":
          match_pref_abbr = 'MP2';
          break;
        case 'Not Interested in Ridematching':
          match_pref_abbr = 'MP3';
          break;
        default:
          break;
      }
      return match_pref_abbr;
    }
  },
  curr_address: 'Please allow geolocation access in app settings',

  default_form_params: function () {
    if (Object.keys(app.user).length < 3 || _.isEmpty(app.user.username) || _.isEmpty(app.user.password) || _.isEmpty(app.user.id_member)) {
      return {
        // username: 'ryan@mediabeef.com',
        username: 'MB-COMMUTER@MEDIABEEF.COM',
        password: 'test1234',
        id_member: 795782
      };
    } else {
      return {
        username: app.user.username,
        password: app.user.password,
        id_member: app.user.id_member
      };
    }
  },
  geolocation: {
    position_watch_id: null,
    // onSuccess Callback
    // This method accepts a Position object, which contains the
    // current GPS coordinates
    onSuccess: function (position, pan_map) {
      console.log('Latitude: ' + position.coords.latitude + '\n' +
        'Longitude: ' + position.coords.longitude + '\n');
      app.current_pos = position.coords;
      wls.setItem('saved_coords', JSON.stringify({
        coords: {
          latitude: app.current_pos.latitude,
          longitude: app.current_pos.longitude
        }
      }))
      const latlng = {lat: app.current_pos.latitude, lng: app.current_pos.longitude};
      if (window.pan_to_map) {
        window.pan_to_map = false;
      }
      if (!(app.user instanceof User)) return
      if (!app.user.first_home_address()) {
        if ($('#start_address_lat').val() === '') {
          $('#start_address_lat').val(app.current_pos.latitude);
        }
        if ($('#start_address_lng').val() === '') {
          $('#start_address_lng').val(app.current_pos.longitude);
        }
      }
      // app.misc.geocodeLatLng(latlng, GEOCODER, app.geocode.onSuccess, null);
    },
    // onError Callback receives a PositionError object
    //
    onError: function (error) {
      console.info('code: ' + error.code + '\n' +
        'message: ' + error.message + '\n');
    }
  },
  // deviceready Event Handler
  //
  // Bind any cordova events here. Common events are:
  // 'pause', 'resume', etc.
  onDeviceReady: function () {
    /*navigator.geolocation.getCurrentPosition(() => {
    }, () => {
    }, GEOLOCATION_OPTIONS);*///asking for permission
    setTimeout(app.start_heartbeat, 5000);//wait until user accepts permissions
    if (wls.getItem('touchid_configured')) {
      this.signin_w_touch();
    }
    //determine android fingerprint is available
    const Fingerprint = {isAvailable: null}
    if (isdef('Fingerprint') && typeof Fingerprint === 'object' && typeof Fingerprint.isAvailable == 'function') {
      Fingerprint.isAvailable(() => app.android_has_fingerprint = true, (message) => {
        app.android_has_fingerprint = false;
        console.log(`Android fingerprint not avail ${message}`);
      });
    }
  },
  initialize: function (remember = false) {
    let username = wls.getItem("username");
    let hashedPassword = wls.getItem('hashedPassword');
    let hashed = false;
    $.support.cors = true;
//touchID

    //b3t login function
    $("#login_form").on("submit", app.login);
    GEOCODER = null
    // GEOCODER = new google.maps.Geocoder();
  },
  /**
   * Login with biometric
   * Re-verify that bio is enabled
   * Verify using bio
   * If verification success, grab username/pw from MWCOG_URL
   * @return boolean
   */
  login_w_bio: async function (setUsername, setPw) {
    if (isPlatform('mobileweb')) return
    let bio_avail = {}
    if (IS_DEBUG) bio_avail = {isAvailable: true}
    else bio_avail = await NativeBiometric.isAvailable()
    if (!bio_avail.isAvailable) {
      app_alert(app, `Biometric is not enabled`)
      return false
    }
    try {
      const [fingerprint_verify_res, fingerprint_verify_msg, fingerprint_verify_cred] = await performBiometricVerification(app)
      if (!fingerprint_verify_res) {
        app_alert(app, 'Authentication failed. Please try again. ' + fingerprint_verify_msg)
        return false
      }
      const {username: bio_username, password: bio_password} = fingerprint_verify_cred
      if (!bio_username || !bio_password) {
        //probably first time using bio?
        app_alert(app, 'Missing username and password. Please log in once using username and password')
        return false
      }
      await setUsername(bio_username)
      wls.setItem('username', bio_username)
      await setPw(bio_password)
      wls.setItem('password', bio_password)
      return true
    } catch (e) {
      return false
    }
  },

  /**
   *
   * @param e
   * @param remember
   * @param bio_just_verified_dont_do_it_again boolean During initial startup, when log in, must ask for bio. But if toggle `use_bio_to_login`
   *          just changed, we trigger login( ) but we should not verify anymore
   * @param login_preload_context string Preloaded context to the login page; sent by App.jsx
   * @param login_state_username Passed from Login.jsx
   * @param login_state_pw Passed from Login.jsx
   * @return {Promise<boolean>}
   */
  login: async function (e, remember, bio_just_verified_dont_do_it_again = false, login_preload_context = ''
    , login_state_username = '', login_state_pw = '') {
    let use_bio_to_login = lsc('use_bio_to_login')
    if (bio_just_verified_dont_do_it_again) { //skipping bio
    } else {
      if (use_bio_to_login) {
        try {
          const [fingerprint_verify_res, fingerprint_verify_msg, bio_cred] = await performBiometricVerification(app)
          if (!fingerprint_verify_res) {
            console.error(app, 'Biometric authentication failed. Please try again. ' + fingerprint_verify_msg)
            return false
          }
          console.info(`Saved bio cred: `, bio_cred.username, bio_cred.password)
          if (bio_cred.username > ' ' && bio_cred.username != 'csdf') $('input[name="username"]').val(bio_cred.username)
          if (bio_cred.password > ' ' && bio_cred.password != 'csdf') $('input[name="password"]').val(bio_cred.password)
        } catch (err) {
          //bio auth failed
          console.error('Biometric authentication failed. Please try again. ' + err.toString())
          return false
        }
      }
    }

    let hashed = true
    let hashedPassword = wls.getItem('hashedPassword');
    let saved_username = wls.getItem("username")
    if (isdef('is_login_and_commute_log') && is_login_and_commute_log) {
      if (e.preventDefault && (typeof e.preventDefault == 'function')) e.preventDefault()
      console.log('Login check only');//dont submit
    }
    let u = $('input[name="username"]')?.val()?.trim();
    if ((!u || u < ' ') && login_state_username > ' ') u = login_state_username
    let p = $('input[name="password"]').val();
    if (p < ' ' && login_state_pw > ' ') p = login_state_pw
    // let rememberMe = $("#remember").prop('checked') || use_bio_to_login;
    let rememberMe = remember
    wls.setItem('remember', rememberMe)

    if (rememberMe && !use_bio_to_login) {
      if (saved_username !== '' && (saved_username !== null) &&
        (u == '' || u == null) //no username has been entered
      ) {
        u = saved_username;
      }
    } else {
      wls.setItem('password', '');
      wls.setItem('hashedPassword', '');
      wls.setItem("username", '');
      wls.setItem('rem_password', '');
      wls.setItem('rem_hashedPassword', '');
      wls.setItem("rem_username", '');
      $("#password").val("");
      hashed = false;
    }
    // const ionic_loading = await app_toast(app, 'Logging in...', 3000, 0, false);
    const ionic_loading = await app_toast(app, 'Logging in...', 3000, 0, true);
    //disable the button, so we can't resubmit while we wait
    $("#login_btn", this).attr("disabled", "disabled");
    if (hashedPassword === p) {
      p = hashedPassword;// What does this mean? BN 4/18/22
      hashed = true;//should be false instead BN 4/18/22
    } else {
      hashed = false;//should be true instead BN 4/18/22
    }
    if (IS_DEBUG) {
      // u = 'redgar942';//tdm only, type 0
      // u = 'sfinafroc246';//tdm only, type ??
      // u = 'fakehemrycc';//mwcog type 0
      // u = 'jitubats';//mwcog type 1
      // u = 'cpnowtest';//tdm type 2
      // u = 'SteveOsborn';//mwcog type 2
      // p = 'changeme4';
      // hashed = false;
    }
    if (rememberMe && !_.isEmpty(hashedPassword)) {
      app.user.password_saved = hashedPassword;
      // p = hashedPassword;
    } else {
    }
    if ((u < ' ' || p < ' ') && login_state_username < ' ' && login_state_pw < ' ') {
      // app_toast(app, 'Please enter username and password');
      //re-enable the login button
      hide_loading();
      return false
    }
    app.user.username = u;
    app.user.password = p;
    wls.setItem('remember', remember)
    let login_successful = false
    let res = {}
    try {
      res = await $.get(COMMUTER_URL + "json?action=login&source=APPCP1&username=" + u + "&password=" + p + '&password_saved=' + hashed)
    } catch (err) {
      swl('Cannot login', JSON.stringify(err.message) + err.statusText, 5000)
      await change_use_bio_to_login(false, app)
      return false
      // todo parse e.statusText instead
    }
    ionic_loading?.dismiss()
    login_successful = (res.statusCode === 1)
    if (!login_successful) {
      swl(`Cannot login`, res?.statusDescription, 3000)
      await change_use_bio_to_login(false, app)
      return false
    }
    let comstatus_ok = true;//by default, we assume the comstatus is ok
    let comstatus = null;//by default there's no comstatus
    if (typeof res === "object" && res.hasOwnProperty('commuterData')) {
      if (typeof res.commuterData === "object" && res.commuterData !== null && res.commuterData.hasOwnProperty('comstatus')) {
        comstatus = String(res.commuterData.comstatus || res.commuterData.ccrsRegStatus);
        comstatus = comstatus.trim();
        if (!(comstatus === 'A' || comstatus === 'NR' || comstatus === 'I')) {
          app_alert(app, 'Your account is inactive. Please contact an administrator at ridematching@mwcog.org or 1-800-745-RIDE for assistance.',
            () => {
            }, 'Account Inactive', 'OK');
          console.log(`Comstatus: ${comstatus}`);
          login_successful = false;
          comstatus_ok = false;
          await change_use_bio_to_login(false, app)
          return false
        }
      }
    }

    //login_successful = true
    // if (!(typeof device === 'object' && device.hasOwnProperty('platform') && device.platform === 'Android' && device.hasOwnProperty('version') && device.version.indexOf('6.0') !== -1)){
    // app.start_heartbeat();
    // }
    //navigator.geolocation.getCurrentPosition((position) => {
    //   capp.geolocation.onSuccess(position)
    //   app.get_pnr_from_api()
    res.firstName = _.capitalize(res.firstName.toLowerCase());
    res.email = res.commuterData?.email.toLowerCase();

    wls.setItem("commuter_data", JSON.stringify(res));
    if (res.hasOwnProperty('commuterData')) {
      res.username = _.capitalize(res.commuterData.userName.toLowerCase());
      for (let key in res.commuterData) {
        if (!res.commuterData.hasOwnProperty(key)) {
          continue;
        }
        if (res.commuterData[key] == null) {
          continue;
        }
        if (['supFName', 'commuterName', 'empName', 'empState', 'supLName', 'lastName', 'supFName', 'comModeDesc',
          'firstName', 'empCity'].indexOf(key) !== -1) {
          res.commuterData[key] = _.capitalize(res.commuterData[key].toLowerCase());
        }
        if (['email', 'supEmail'].indexOf(key) !== -1) {
          res.commuterData[key] = res.commuterData[key].toLowerCase();
        }
        if (['empStreet1', 'addrStreet1', 'addrCity', 'commuterName'].indexOf(key) !== -1) {
          res.commuterData[key] = _.startCase(res.commuterData[key].toLowerCase());
        }
      }

      app.user.commuter_data = res.commuterData;
      // app.user.obj.commuter_data = res.commuterData; 10/23 b3t: is this needed?
      app.user.hashedPassword = res.hashedPassword
      app.user.username = res.username
      app.user.commuter = res.commuter
      app.user.inFlextimeIncentive = res.inFlextimeIncentive
      app.user.isVanpoolDriver = res.vanpoolDriver ?? false
      app.user.vanpoolID = res.vanpoolID ?? -1
    }
    if (res.hasOwnProperty('addresses')) {
      app.user.addresses = res.addresses;
    }
    app.user = new User(app.user) //convert to User object
    trigger('app.user:on_change')

    //only pass a portion of it to Backbone

    res.commuter_data = res.commuterData
    res = _.pick(res, 'statusCode', 'statusDescription', 'enrolled', 'commuter', 'firstName', 'hashedPassword', 'username', 'email', 'commuter_data', 'inFlextimeIncentive');
    let local_apns_id = wls.getItem('registrationId');
    if (local_apns_id) res.apns_device_reg_id = local_apns_id //if it's null, don't bother

    /*array_keys_to_underscore(res);
    app.user.save(res, {
      forceRefresh: true, success: function (response){
        app.user.fetch({success: app.menuView.hide_or_show_incen_menu});
      }
    });*/

    wls.setItem("justLoggedIn", "1");
    if (rememberMe) {
      wls.setItem('username', u);
      wls.setItem('password', p);
      wls.setItem('hashedPassword', res.hashedPassword);
    }
    /*}, (error) => {
      alert(`Please allow location access in order to use the app. Thank you`)
    }, GEOOPTIONS);
    get cur pos end */

    if (res.statusCode === 0 && res.statusDescription === 'This account has not yet been activated.') {
      // close modal $(app.homeView.el).find('#activate_account_modal').rmodal();
      await change_use_bio_to_login(false, app)
      return false;
    } else {
      if (!comstatus_ok) {//if comstatus not ok, we already showed a warning earlier
        app_toast(app, 'You have entered an invalid username and password, please try again');
        await change_use_bio_to_login(false, app)
        return false
      }
    }
    wls.setItem('remember', remember)
    if (remember) {
      wls.setItem('rem_username', u)
      wls.setItem('rem_password', p)
    }
    wls.setItem('user', JSON.stringify(app.user))
    //_L_01 After login; if BIO_DONT_ASK_AGAIN is true, do nothing
    const BIO_DONT_ASK_AGAIN = lsc('BIO_DONT_ASK_AGAIN')
    if (BIO_DONT_ASK_AGAIN) return true
    //_L_01b if BIO_DONT_ASK_AGAIN is false
    if (use_bio_to_login) {
      if (!(isPlatform('mobileweb')) && (!(isPlatform('desktop'))))
        await NativeBiometric.deleteCredentials({server: MWCOG_URL})
      await NativeBiometric.setCredentials({username: u, password: p, server: MWCOG_URL})
      const bio_cred = await NativeBiometric.getCredentials({server: MWCOG_URL})
      ls_set('use_bio_to_login', true)
    } else {//use_bio_to_login not set; probably first time successful login?
      const conf_buttons = [{text: 'Yes', role: 'confirm'}, {text: 'No', role: 'cancel'}, {text: "Don't ask again"}]
      const conf_res = await app_confirm(app, 'Do you want to enable Biometric login?', 'Enable Biometric Login', 'horizontal_btn', conf_buttons)
      console.log(`conf_res: `, conf_res)
      switch (conf_res) {
        case 'yes':
          const change_bio_res = await change_use_bio_to_login(true, app)
          if (change_bio_res == true) //change bio was successful
            await bio_set_cred({username: u, password: p, server: MWCOG_URL})
          break
        case 'dont_ask_again':
          on_change_trigger_use_bio({detail: {checked: false}})
          lsm('BIO_DONT_ASK_AGAIN', true)
          break
        case 'no':
        default:
          break //do nothing. Will keep asking again
      }
      let a = 1

    }

    return true //login succeeded
  },
  finger_authen_successful: function () {
    app_toast(app, 'Fingerprint configured successfully');
    wls.setItem('touchid_configured', true);
    wls.setItem('username', app.user.username);
    $("#remember").prop('checked', true);
    $('#configure_touch').removeProp('checked');
    wls.setItem('remember', 'true');
    wls.setItem('password', app.user.password_saved);
  }
  ,
  finger_authen_failed: function (msg) {
    app_alert(app, 'Invalid fingerprint. Please try again later');
    wls.setItem('touchid_configured', false);
    $("#remember").removeProp('checked');
    wls.setItem('remember', false);
    app.bind_touchid_ui();
    console.error(msg);
  }
  ,
  signin_w_touch: function () {
    if (app.is_ios) {
      try {
      } catch (e) {
        console.error(e);
      }
    }
    if (app.is_android) {
      Fingerprint.show({
          clientId: "CCM",
          clientSecret: "CCMpassword" //Only necessary for Android
        },
        () => {
          app.login(null, true);
        },
        () => {
          app_toast(app, 'Touch ID failed. Please log in manually or set up later');
        });
    }
  },
  create_account: function (e) {
    console.info(e);
  },
  tmp: {}
}

// const App: React.FC = () => (
function App({location}) {
  const [login_preload_context, set_login_preload_context] = useState('')
  const [side_menu_refresh, set_side_menu_refresh] = useState(false)
  const [present_toast, dismiss_toast] = useIonToast()
  const [present_load, dismiss_load] = useIonLoading()
  const history = useHistory()
  const [st_use_bio_to_login, set_st_use_bio_to_login] = useState(lsm('use_bio_to_login')) //state to map to local storage

  Capp.addListener('pause', async (data) => {
    console.log(`App going to pause soon.. `)
    Capp.removeAllListeners().then(() => {
      Capp.addListener('appStateChange', async ({isActive}) => {
        console.log('App state changed. Is active?', isActive);
        trigger('app.state:on_change', isActive)
        if (!isActive) return
        setTimeout(trigger_autologin, 2000)
      });
    })
  })


  async function trigger_autologin() {
    if (window.flag_autologin_running == true) return //semaphore
    window.flag_autologin_running = true
    if (IS_VERBOSE) console.log(`__trigger autologin func`)
    //check if app already ran
    let app_already_run = lsm('app_already_run')
    if (!app_already_run || app_already_run == '' || app_already_run < 2) {
      console.log(`Looks like app not already run (i.e. this is the first time the app runs). Aborting auto-login..`)
      //increase app_already_run
      if (!app_already_run || app_already_run == '') lsm('app_already_run', 1)
      if (isNumber(app_already_run)) lsm('app_already_run', Math.min(app_already_run + 1, 999))
      {
        window.flag_autologin_running = false
        return
      }
    }
    if ($('#page_login').length == 0 || $('#page_login').hasClass('ion-page-hidden')) // We are not at page login
    {
      window.flag_autologin_running = false
      return
    }
    // const is_last_state_88 = match?.params?.last_state === '88'
    const use_bio_to_login = lsm('use_bio_to_login')
    const remember = lsm('remember') || false
    // console.log(`is_last_state_88: `, is_last_state_88)
    if ((use_bio_to_login !== 'true' && use_bio_to_login !== true)) {
      console.log(`use_bio_to_login: `, use_bio_to_login)
      window.flag_autologin_running = false
      return
    }
    let last_bio_succeed = lsm('last_bio_success')
    if (!last_bio_succeed) last_bio_succeed = moment().subtract(5, 'minutes')
    else last_bio_succeed = moment(last_bio_succeed)
    const diff_since_last_bio_succeed = moment().diff(last_bio_succeed, 'seconds')
    if (diff_since_last_bio_succeed < AUTO_LOGIN_THOLD) {
      console.log(`Last bio succeed was ${diff_since_last_bio_succeed} secs ago. Aborting auto-login`)
      window.flag_autologin_running = false
      return
    }
    console.log(`Auto-logging in using biometric...`)
    const e = {target: document.getElementById('login_btn')}
    const login_res = await app.login(e, remember)
    if (login_res) {
      goto_dash_force(app, history)
    }
    window.flag_autologin_running = false
  }

  useEffect(async () => {
    console.log(`app useEffect called`)
    setTimeout(trigger_autologin, 2000)
    // trigger_autologin() //zsdf
    if (isPlatform('desktop')) console.log(`platform desktop`)
    if (isPlatform('web')) console.log(`platform web`)
    if (isPlatform('mobileweb')) console.log(`platform mobileweb`)
    if (isPlatform('android')) console.log(`platform android`)
    if (isPlatform('ios')) console.log(`platform ios`)
    if (isPlatform('iphone')) console.log(`platform iphone`)
    if (!(isPlatform('web')) && !(isPlatform('desktop')) && !isPlatform('mobileweb'))
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)

    const root = document.querySelector(':root');
    root.style.setProperty('--mb_statusbar_offset', '0px')
    let ion_safe_area_bottom = 0
    if (StatusBarMB) {
      let sb_height = 0
      if (StatusBarMB.height) sb_height = await StatusBarMB.height()
      if (sb_height.height_val) sb_height = sb_height.height_val
      console.info(`sb_height is`, sb_height)
      if (Number.isInteger(sb_height)) {
        let mb_padding = 16
        /*if (isPlatform('ios')) {
          switch (sb_height) {
            case 44: {
              mb_padding = 40
              break;
            }
            case 50: {
              mb_padding = 40
              break;
            }
            default:
              break;
          }
        } else */
        if (isPlatform('android')) {
          ion_safe_area_bottom = mb_padding //for android, assume safe area bottom = 16px. If this doesn't work, use mb-statusbar-plugin nbheight()
          switch (sb_height) {
            case 136: { //should match pixel 6a 118; mike pixel 6a is reporting 118
              mb_padding = 25
              ion_safe_area_bottom = 30
              break;
            }
            case 118: { //mike pixel 6a
              mb_padding = 25
              ion_safe_area_bottom = 30
              break;
            }
            case 72: {
              mb_padding = 20
              ion_safe_area_bottom = 25
              break;
            }
            case 60: {
              mb_padding = 18
              ion_safe_area_bottom = 22
              break;
            }
            default:
              break;
          }
          [mb_padding, ion_safe_area_bottom] = await fix_status_bar(mb_padding, ion_safe_area_bottom)//override mb_padding
          root.style.setProperty('--mb_statusbar_offset', `${mb_padding}px`);
          root.style.setProperty('--ion-safe-area-bottom', `${ion_safe_area_bottom}px`);
        }
      }
      /*if (sb_height >= 72) mb_padding = (sb_height - (72 - 23))
      else {
        if (sb_height >= 37) mb_padding = (sb_height - 37)
      }*!/
    } else if (isPlatform('mobileweb') || isPlatform('desktop')) {
      mb_padding = 0
    }
    root.style.setProperty('--statusbar_mb_padding', mb_padding + 'px')
  }
}*/

      let nb_height = 0
      try {
        if (StatusBarMB && StatusBarMB.nbheight) nb_height = await StatusBarMB.nbheight()
      } catch (e) {
        console.log(`StatusBarMB nbheight`, e)
      }
      if (nb_height.nbheight_val) nb_height = nb_height.nbheight_val
      console.info(`nb_height is`, nb_height)


    }//if StatusbarMB END

    on('app.user:on_change', () => {
      set_side_menu_refresh(!side_menu_refresh)
    })
  }, [lsm('use_bio_to_login'), app.user?.obj])//end useEffect

  window.addEventListener('storage', () => {
    // When local storage changes, dump the list to
    // the console.
    console.log(`app 836 storage changed`)
    set_st_use_bio_to_login(lsm('use_bio_to_login'))
  })

  window.addEventListener('ls_set', (e) => {
    // When local storage changes, dump the list to
    // the console.
    set_st_use_bio_to_login(lsm('use_bio_to_login'))
  })


  app.present_toast = present_toast
  app.dismiss_toast = dismiss_toast
  app.present_load = present_load
  app.dismiss_load = dismiss_load
  const tabbar_btn_clicked = function () {
    console.log(`tabbar btn clicked`)
    dismiss_active_modals()
  }
  const [present] = useIonAlert()
  app.present_ion_alert = present

  return (
    <AppContext.Provider value={{login_preload_context, set_login_preload_context}}>
      <IonApp>
        <IonContent id="menu_content">
        </IonContent>
        <IonReactRouter>
          <IonMenu menuId="top_non_dash" side="start" contentId="menu_content" side_menu_refresh={side_menu_refresh}>
            <IonContent>
              <br/><br/>
              <IonList>
                <IonItem detail="false">Welcome&nbsp;<span
                  id='username_menu'>{app.user.first_name && app.user.first_name()}</span></IonItem>
                <br/>
                {app.user?.obj?.isVanpoolDriver ? <IonItem routerLink='/vh' onClick={hide_top_menu} detail="false">VANHOPPR</IonItem> : false}
                <IonItem routerLink='/dash' onClick={hide_top_menu} detail="false">Dashboard</IonItem>
                <IonItem routerLink='/rm' detail="false" onClick={hide_top_menu}>Find Ridematches</IonItem>
                <IonItem routerLink='/clog' onClick={hide_top_menu} detail="false">Log Commute</IonItem>
                <IonItem routerLink='/login/88' id="goto_home_link" className="hidden"></IonItem>
                <IonItem detail="false">
                  <IonLabel className="small_text">Enable Biometric</IonLabel>
                  <IonToggle size="small" name="noCommute" id="noCommute" checked={st_use_bio_to_login}
                             onIonChange={(e) => on_change_trigger_use_bio(e)}>
                  </IonToggle>
                </IonItem>
                <IonItem onClick={
                  () => {
                    if (menuController.isOpen('top_non_dash')) menuController.close('top_non_dash')
                    present({
                      cssClass: 'my-css',
                      header: 'Log Out',
                      message: 'Are you sure you want to log out?',
                      buttons: [
                        'Cancel',
                        {
                          text: 'OK', handler: (d) => {
                            console.log('logout ok pressed')
                            wls.removeItem('user')
                            logout(app)
                            goto_home(app, history)
                          }
                        },
                      ],
                      // onDidDismiss: (e) => console.log('did dismiss'),
                    })
                  }
                } button detail="false">Log Out</IonItem>
                <IonItem style={{fontSize: "80%", color: 'grey'}}>
                  {app.build_version}
                </IonItem>
              </IonList>
            </IonContent>
          < /IonMenu>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/dash">
                <Dashboard app={app}/>
              </Route>
              <Route exact path="/rm">
                <Rm app={app}/>
              </Route>
              <Route exact path="/vh">
                <Vh app={app}/>
              </Route>
              <Route exact path="/clog">
                <Clog app={app}/>
              </Route>
              {/*<Route path={`${match.url}/users/:id`} component={UserDetailPage} />*/}
              <Route exact path="/login/:last_state" render={(props) => {
                const extra_props = props
                extra_props.app = app
                extra_props.Capp = Capp
                return <Login {...extra_props} />
              }}/>

              <Route exact path="/">
                <Redirect to="/login/1"/>
              </Route>
              <Route exact path="/create">
                <Create app={app}></Create>
              </Route>
              <Route exact path="/forget">
                <Forget app={app}></Forget>
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom" color="primary">
              <IonTabButton tab="dash" href="/dash" onclick={tabbar_btn_clicked}>
                <IonIcon icon={appsSharp}/>
                <IonLabel>Dashboard</IonLabel>
              </IonTabButton>
              <IonTabButton tab="rm" href="/rm">
                <IonIcon icon="assets/img/car_sharp.svg"/>
                <IonLabel>Find Ridematches</IonLabel>
              </IonTabButton>
              <IonTabButton tab="clog" href="/clog">
                <IonIcon icon={calendarSharp}/>
                <IonLabel>Log Commute</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    </AppContext.Provider>
  )
}

const WithRouterApp = withRouter(App)

export default WithRouterApp
