import {app_alert, app_toast} from "../Helper";
import {
  IonAlert,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  useIonAlert
} from '@ionic/react';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser'
import {logout, open_ext_url} from "../Helper"
import {Fragment, useEffect, useState} from "react"
import {useHistory} from "react-router"
import {on} from '../event'
import Menu from "../components/Menu"
import APISocal from '../apis'
import ls from 'local-storage'
import _ from "lodash";
import { useLocation } from 'react-router-dom';

window.lsm = ls

const apis = new APISocal()
apis.setup({url: `https://tdm.commuterconnections.org/mwcog/mobileapicontroller`})

const api_vp = new APISocal()
api_vp.setup({url: `https://tdm.commuterconnections.org/vanhopprCore/vanpoolQueueApi/`})
const VANPOOL_APIKEY = 'testchangeme4'

// const Dashboard: React.FC = () => {
const Dashboard = ({app}) => {
  const [does_show_grh, set_does_show_grh] = useState(false)
  let component_loaded = false
  let location = useLocation()
  const [new_vh_rider_requests_len, set_new_vh_rider_requests_len] = useState(0)
  const [is_new_vh_req_alert_open, set_is_new_vh_req_alert_open] = useState(false)
  const [present_alert] = useIonAlert()
  const [home_addr, set_home_addr] = useState(app && ((app.user.home_address_full && app.user.home_address_full()) || app.curr_address))
  const [work_addr] = useState((app.user.work_addr_full && app.user.work_addr_full()) || app.curr_address)
  const history = useHistory()

  useEffect(() => {
    console.log(`useeffect dash called`)
    let ignore = false
    set_home_addr((app.user.home_address_full && app.user.home_address_full()) || app.curr_address)
    on('app.user:on_change', () => {
      set_home_addr(app.user.home_address_full && app.user.home_address_full())
    })
    $('body').removeClass('offset_input')
    $('ion-toolbar').removeClass('hidden')
    $('ion-toolbar').show()
    //Determine whether to show `GRH` button
    const get_grh_avail = async () => {
      const commuter_id = app?.user?.obj?.commuter || -1
      let check_grh_avail_res = await apis.g('', {
        action: 'checkGRHAvailibility',
        siteId: 10001,
        commuterId: commuter_id
      })
      if (typeof check_grh_avail_res == 'object') check_grh_avail_res = check_grh_avail_res['GRH Availibility']
      if (check_grh_avail_res == false) set_does_show_grh(false)
      else set_does_show_grh(true)
      //zsdf
      // set_does_show_grh(true)
    }
    get_grh_avail().catch(console.warn)
    //Get VANHOPPR number of requests //https://tdm.commuterconnections.org/vanhopprCore/vanpoolQueueApi/getRequestsList?apiKey=testchangeme4&idVanpool=1282
    //ONLY calls this if user navigates INTO this component
    const get_vanhoppr_reqs = async () => {
      let vh_requests = await api_vp.g('getRequestsList', {
        apiKey: VANPOOL_APIKEY, idVanpool: app.user?.obj?.vanpoolID
      })//apiKey=testchangeme4&idVanpool=1282
      if (typeof vh_requests == 'object') vh_requests = vh_requests['requests']
      if (typeof vh_requests == "object" && vh_requests instanceof Array) {
        const vh_reqs_pend = vh_requests.filter((req) => (req.status == 'P'))
        if (!ignore) set_new_vh_rider_requests_len(vh_reqs_pend.length)
        subtract_vh_req_api_fr_ls(vh_reqs_pend, ignore)
      }
    }
    if (location?.pathname == '/dash') get_vanhoppr_reqs().catch(console.warn)
    component_loaded = true
    // return off('app.user:on_change')
    return () => { //cleanup
      ignore = true
      component_loaded = false
    }
  }, [app.user, app.user.home_address_full, ls('use_bio_to_login'), location])//only re-run the effect if those changes

  const upcoming = (e => {
    alert('This button will be hidden on the final release and is a placeholder for upcoming functionality in the next release.')
  })

  const show_grh_popup = (e => {
    console.log(`show_grh_popup called`)
    present_alert({
      cssClass: 'fullscreen_alert',
      header: 'Request GRH',
      message: `<br/>Please submit this form to request a ride home. An email will be sent to our dispatcher and a GRH representative will call you within one hour. The GRH service is available between the hours of 6:00 am and 10:00 pm and your ride MUST BE TAKEN BEFORE 10:00 pm.
<br/>
<p>Your ride home will be provided by taxi or rental car and may be supplemented by transit for part of the distance. The dispatcher will determine the best method to serve you in an unexpected emergency or unscheduled overtime.
As a reminder, Commuter Connections will cover the cost of your rental car and taxi fare. Some restrictions apply, 
<a href="https://www.commuterconnections.org/grh-participation-guidelines/" target="_blank">please click here for the full Participation Guidelines</a>. Commuter Connections will provide reimbursements for transit trips.</p>
<p>You may be asked by the taxi driver to present your GRH ID card. Please be sure to have it with you when taking your GRH trip.</p>
<strong>Please tell us a little about your request:</strong>
`,
      buttons: [
        'Close',
        {
          text: 'Send', handler: async (e, this_obj) => {
            console.log(`Sending GRH message`)
            if (typeof e !== "object") {
              app_toast(app, 'Error sending GRH message. Please enter a message')
              return false
            }
            const msg = e[0], commuter_id = app?.user?.obj?.commuter || -1
            let msg_sending_res = await apis.g('', {
              action: 'requestGRH',
              siteId: 10001,
              // commuterId: 742915,//this is the commuterId that can successfully send GRH
              commuterId: commuter_id,
              comments: msg
            })
            if (typeof msg_sending_res !== 'object') {
              app_toast(app, 'Error sending GRH message')
              return true
            }
            msg_sending_res = msg_sending_res['Request GRH'].toLocaleUpperCase()
            if (msg_sending_res != 'SUCCESS') {
              app_toast(app, `Error sending GRH message. ${msg_sending_res}`)
              return true
            }
            app_toast(app, `Your GRH request has been sent successfully`)
            return true
          }
        },
      ],
      inputs: [
        {
          type: 'textarea',
          attributes: {
            rows: 4,
            minRows: 4
          },
          placeholder: '',
          id: 'grh_textarea_id'
        }],
      onDidDismiss: (e) => console.log('alert dismissed'),
    })
  })
  /**
   * Compare new vh_reqs (from API) and existing vh_reqs stored in Local Storage.
   * Subtract API req from LocalStorage req, to obtain new unseen requests
   * Also show notification popup
   * @param vh_reqs Array API requests
   * @param ignore boolean useEffect cleanup
   */
  const subtract_vh_req_api_fr_ls = function (vh_reqs = [], ignore) {
    vh_reqs = _.keyBy(vh_reqs, 'idRequest')
    /** @var Object vh_reqs_ls **/
    let vh_reqs_ls = ls('vh_reqs_seen')
    if (typeof vh_reqs_ls == 'undefined' || vh_reqs_ls == null) vh_reqs_ls = {}
    let vh_reqs_unseen = []
    for (const idRequest in vh_reqs) {
      const vh_req = vh_reqs[idRequest]
      if (!vh_reqs_ls.hasOwnProperty(idRequest)) {
        vh_reqs_unseen.push(vh_req)
      }
    }
    if (vh_reqs_unseen.length > 0 && !ignore)
      set_is_new_vh_req_alert_open(true)
  }
  return (
    <IonPage id="page_dash" className="page_routed">
      {/*<Menu_dash username='dash_username' logout={{logout}} app={app} curpage="dash"></Menu_dash>*/}
      <Menu title={`Welcome ` + (app.user.first_name && app.user.first_name())} logout={{logout}} app={app}
            app_user={app.user} curpage="dash" back_to_dashboard={false}></Menu>

      <IonCard className="infobox">
        <IonItem>
          <IonLabel>Your Commute</IonLabel>
          {/*<IonButton fill="solid" slot="end" expand="block" size="default" className="upcoming_feature" onClick={upcoming}>Edit</IonButton>*/}
        </IonItem>
        <IonCardContent>
          <b>From</b><br/>
          <span>{home_addr}</span>
          <br/>
          <b>To</b><br/>
          <span>{work_addr}</span>
        </IonCardContent>
      </IonCard>
      <IonContent scrollY scroll-y id="dash_cont">

        <div id="menus">
          {app.user?.obj?.isVanpoolDriver && <IonItem detail button onclick={() => history.push('/vh')}>
            <IonLabel>
              <IonRow>
                <IonCol size="2">
                  <div><img className="menu_icon vanhoppr" src="../../assets/icon/vh_solid_bg.png" slot="start"></img></div>
                </IonCol>
                <IonCol size="9">
                  <b>VANHOPPR</b><br/>
                  <span className="purple font_bold">NEW RIDER REQUESTS: {new_vh_rider_requests_len}</span><br/>
                </IonCol>
              </IonRow>
            </IonLabel>
          </IonItem>}
          <IonItem detail button onclick={() => history.push('/rm')}>
            <IonLabel>
              <IonRow>
                <IonCol size="2">
                  <div><img className="menu_icon ridem" src="../../assets/icon/rm.svg" slot="start"></img></div>
                </IonCol>
                <IonCol size="9">
                  <b>FIND RIDEMATCHES</b><br/>
                </IonCol>
              </IonRow>
            </IonLabel>
          </IonItem>
          {does_show_grh && <IonItem detail button onclick={show_grh_popup}>
            <IonLabel>
              <IonRow>
                <IonCol size="2">
                  <div><img className="menu_icon grh" src="../../assets/icon/icon-grh.png" slot="start"></img></div>
                </IonCol>
                <IonCol size="9">
                  <b>REQUEST GRH TRIP</b><br/>
                </IonCol>
              </IonRow>
            </IonLabel>
          </IonItem>}
          {/*
          <IonItem detail>
            <IonLabel>
              <IonRow onClick={upcoming}>
                <IonCol size="2">
                  <div><img className="menu_icon ridem" src="../../assets/icon/guarantee_rh.svg" slot="start"></img></div>
                </IonCol>
                <IonCol size="9">
                  <b>GUARANTEED RIDE HOME</b><br />
                  3 rides remaining this year
                </IonCol>
              </IonRow>
            </IonLabel>
          </IonItem>
*/}
          <IonItem detail>
            <IonLabel>
              <IonRow onClick={() => present_alert({
                cssClass: 'my-css',
                header: 'IncenTrip',
                message: 'Click below to learn more about our new free incentive for DC commuters!',
                buttons: [
                  'Close',
                  {
                    text: 'Learn More', handler: (e) => {
                      // InAppBrowser.create('https://commuterconnections.org/incentrip-app', '_blank')
                      open_ext_url('com.futuremobilitylabs.incentrip', ''
                        , 'com.futuremobilitylabs.app.incentrip', 'incentrip/id1370576636', app)
                    }
                  },
                ],
                onDidDismiss: (e) => console.log('alert dismissed'),
              })}>
                <IonCol size="2">
                  <div><img className="menu_icon ridem" src="../../assets/icon/incentrip.svg" slot="start"></img></div>
                </IonCol>
                <IonCol size="9">
                  <b>INCENTRIP</b><br/>
                  {/*3,500 points*/}
                  &nbsp;
                </IonCol>
              </IonRow>
            </IonLabel>
          </IonItem>
          <IonItem detail>
            <IonLabel>
              <IonRow onClick={() => {
                $('#flex_reward_join_btn').trigger('click')
                // window.location.href = 'https://tdm.commuterconnections.org/mwcog/CCRegistration.jsp'
              }}>
                <IonCol size="2">
                  <div><img className="menu_icon ridem" src="../../assets/icon/flex_reward.svg" slot="start"></img>
                  </div>
                </IonCol>
                <IonCol size="9">
                  <b>FLEXTIME REWARDS</b><br/>
                  <a href="https://tdm.commuterconnections.org/mwcog/CCRegistration.jsp" id="flex_reward_join_btn"
                     target="_blank">JOIN NOW</a>
                </IonCol>
              </IonRow>
            </IonLabel>
          </IonItem>
          {/*
          <IonItem detail>
            <IonLabel>
              <IonRow onClick={upcoming}>
                <IonCol size="2">
                  <div><img className="menu_icon ridem" src="../../assets/icon/pnr.svg" slot="start"></img></div>
                </IonCol>
                <IonCol size="9">
                  <b>PARK & RIDE LOTS</b><br />
                  3 lots near you
                </IonCol>
              </IonRow>
            </IonLabel>
          </IonItem>
*/}
          <IonItem detail button onclick={() => history.push('/clog')}>
            <IonLabel>
              <IonRow>
                <IonCol size="2">
                  <div><img className="menu_icon ridem" src="../../assets/icon/cal.svg" slot="start"></img></div>
                </IonCol>
                <IonCol size="9" style={{paddingTop: "14px"}}>
                  <b>LOG COMMUTE</b><br/>
                </IonCol>
              </IonRow>
            </IonLabel>
          </IonItem>
          {/*<IonItem detail>
            <IonLabel>
              <IonRow onClick={upcoming}>
                <IonCol size="2">
                  <div><img className="menu_icon ridem" src="../../assets/icon/cpn.svg" slot="start"></img></div>
                </IonCol>
                <IonCol size="9" style={{paddingTop: "14px"}}>
                  <b>CARPOOL NOW</b><br />
                </IonCol>
              </IonRow>
            </IonLabel>
          </IonItem>*/}
          <br/><br/>
        </div>
        <IonAlert
          isOpen={is_new_vh_req_alert_open}
          header="New Rider Request"
          message="New commuter(s) has asked to join your vanpool"
          buttons={[{
            text: 'More Info',
            role: 'confirm',
            handler: () => {
            },
          }, {
            text: 'Cancel',
            role: 'cancel',
          }]
          }
          onDidDismiss={({detail}) => {
            set_is_new_vh_req_alert_open(false)
            if (detail.role == 'confirm') history.push('/vh')
          }}
        ></IonAlert>
      </IonContent>
    </IonPage>
  )
    ;
};

export default Dashboard;
