import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {Fragment, useEffect, useState} from "react"
import $ from 'jquery'
import {MWCOG_BASE_URL} from "../mwcog/core"
import {app_alert, app_toast} from "../Helper"
import axios from 'axios'
import Menu from "../components/Menu"
import {withRouter} from "react-router-dom"

// const Forget: React.FC = () => {
const Forget = ({app}) => {
    const [answer, setAnswer] = useState()
    const [username, setUsername] = useState()
    const [pw, setPw] = useState()
    const [question, setQuestion] = useState()
    const [remember_pw, setRemember_pw] = useState()

    const grab_question = async function (e){
      setUsername(e.target.value)
      let question_res
      try {
        question_res = await $.get(MWCOG_BASE_URL + 'RecoverPasswordServlet?userName=' + e.target.value)
        setQuestion(question_res)
      } catch (data) {
        const text = data.responseText.split("<")[0];
        setQuestion(text)
      }
    }

    const req_new_pw = async function (e){
      if (answer === "" || pw === "" || typeof pw == 'undefined') {
        app_alert(app,  "Password Recovery Answer and New Password are both required");
        return false;
      }
      let success = false;
      try {
        let recover_url = MWCOG_BASE_URL + 'json?action=recoverpassword&userName=' + username + '&newPassword=' + pw + '&pwdQuestion='
          + question + '&pwdAnswer=' + answer
        let recover_res = await axios({
          method: 'GET',
          url: recover_url,
          withCredentials: false,
        })
        recover_res = recover_res?.data
        if (recover_res.response === "success") {
          app_toast(app, "Your password has been changed.  Click the Back button to return to the login screen.");
        } else {
          app_toast(app, "We were unable to change your password.  Please verify your Password Recovery Answer");
        }

      } catch (error) {
        app_alert(app, "We were unable to change your password.  Please verify your Password Recovery Answer " + JSON.stringify(error));
      }
    }

    useEffect(() => {
      $('ion-tab-bar').hide()
    });
    return (
      <IonPage id="forget" className="page_routed">
        <IonContent fullscreen>
          <Menu app={app} title="Forgot Password" hide_hamburger={true} back_to_login={true}></Menu>
          <div id="forgot_content" className="has_pad">
            <div id="forgot_box">
              <br />
              Forgot your password? Reset it here
              <IonList>
                <IonItem>
                  <IonInput value={username} placeholder="Username" onBlur={grab_question}></IonInput>
                </IonItem>
                <br />
                <IonLabel>Password Recovery Question:</IonLabel>
                <br />
                <IonLabel>{question}</IonLabel>
                <IonItem>
                  <IonInput value={answer} placeholder="Answer" onIonChange={e => setAnswer(e.target.value)}></IonInput>
                </IonItem>
                <br />
                <IonLabel>New Password</IonLabel>
                <IonItem>
                  <IonInput value={pw} type="password" placeholder="New Password" onIonChange={e => setPw(e.target.value)} required="true"></IonInput>
                </IonItem>
                <br />
                <IonButton expand="block" onClick={req_new_pw}>Update password</IonButton>

              </IonList>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
;

export default withRouter(Forget)
