import axios from "axios";

let instance
let globalState = {
  color: ""
}

/**
 * The REST API helper
 * Usage: import apis; apis.setup(env); apis.c() apis.g() apis.p() apis.put() apis.d()
 */
class APISocal {
  ENV = {
    url: ''
  }

  static get axios_config() {
    return {
      withCredentials: false,
      headers: {
        "content-type": "application/json"
      }
    }
  }

  constructor() {
    /*if (instance) {
      throw new Error("New instance cannot be created!!")
    }*/
    instance = this
  }

  /** Should be called just once */
  setup(env) {
    this.ENV = Object.assign(this.ENV, env) //no need for _.cloneDeep for now. Keep it simple
  }

  //CRUD
  /**
   * CREATE
   * @param ep e.g. cuser
   * @param new_object Object {}
   * @param null_object boolean If true; do NOT pass new_object
   */
  async c(ep, new_object = {}, null_object = false) {
    Object.keys(new_object).forEach(key => {
      if (typeof new_object[key] == 'undefined') delete new_object[key]
    })
    if (null_object) new_object = null
    let res
    try {
      res = await axios.post(this.ENV.url + ep, new_object)
    } catch (e) {
      console.error(`Error sending request: ${e}`)
      return false
    }
    if (!res.data) {
      console.error(`Error posting data to backend: `, res.headers)
      return false
    }
    return res.data
  }

  /**
   * GET
   * @param ep e.g. cuser
   * @param query Object {}
   * @param extra_headers
   * @param skipped_param //do not send these query params. For example, do not send user_id; in order to get all
   */
  async g(ep, query = {}, extra_headers = {}, skipped_param = []) {
    if (!query.maxrows) query.maxrows = 100
    Object.keys(query).forEach(key => {
      if (typeof query[key] == 'undefined') delete query[key]
    })
    const query_str = new URLSearchParams(query)
    const axios_config = APISocal.axios_config

    const url = this.ENV.url + ep + '?' + query_str.toString()
    // const res = await axios.get(url)
    const axios_params = Object.assign({
      method: 'get',
      url
    }, axios_config)
    const res = await axios(axios_params)

    if (!res.data) {
      console.error(`Error getting data from backend: `, res.headers)
      return []
    }
    return res.data
  }

  /**
   * PATCH
   * @param ep e.g. cuser
   * @param obj_new_content Object {}
   */
  async p(ep, obj_new_content = {}) {
    Object.keys(obj_new_content).forEach(key => {
      if (typeof obj_new_content[key] == 'undefined') delete obj_new_content[key]
    })
    if (!obj_new_content || !obj_new_content.id) return [false, 'must provide id']
    const res = await axios.patch(this.ENV.url + ep + '/' + obj_new_content.id, obj_new_content)
    return res
  }

  getPropertyByName(propertyName) {
    return globalState[propertyName]
  }

  setPropertyValue(propertyName, propertyValue) {
    globalState[propertyName] = propertyValue
  }

  async test(input) {
    //console.info('ENV: ', APISocal.ENV)
    return `test successful. Input was : ${input}`
  }
}

// let apis = Object.freeze(new APISocal())
// let apis = new APISocal()

// export apis
export default APISocal
