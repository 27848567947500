/**
 * Vanhoppr match results
 *
 * */

import {
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSegment,
  IonSegmentButton,
  useIonAlert,
  useIonLoading
} from '@ionic/react';
import {hide_loading, logout, p, date_time_to_rm_time} from "../Helper"
import React, {Fragment, useEffect, useRef, useState} from "react"
import {useHistory} from "react-router"
import Menu from "../components/Menu"
import {withRouter} from "react-router-dom"
import {GoogleMap, LoadScript} from '@react-google-maps/api';
import ls from 'local-storage'
import $ from 'jquery'
import _, {isNumber} from 'lodash'
import User from "../User"
import {BASE_OPTION_DEFAULTS} from "@fullcalendar/react"
import {IonDatetimeS} from "../components/ion-datetime-s-react"
import {IS_DEBUG} from "../env";
import APISocal from "../apis";
import VhRider from "../components/VhRider";

const api_vp = new APISocal()
api_vp.setup({url: `https://tdm.commuterconnections.org/vanhopprCore/vanpoolQueueApi/`})
const VANPOOL_APIKEY = 'testchangeme4'

const baseUrl = 'https://tdm.commuterconnections.org/mwcog/';
const ie511_url = 'https://tdm.commuterconnections.org/mwcog/integrate'

let map = undefined //will init to be google.maps.Map
const DEF_LAT = 38.85
const DEF_LNG = -77.17

let trafficLayer = {};
let toggleState = 0;
let directionsDisplay = {}, directionsService = {}, info = {}, directionsRenderer = {}

function Vh({app}) {
  const [rider_pend_list, set_rider_pend_list] = useState([]) //riders that are pending (status P)
  const [rider_approved_list, set_rider_approved_list] = useState([]) //riders that are approved (status A)
  const [refresh_flag, set_refresh_flag] = useState(false)
  const get_vanhoppr_reqs = async () => {
    if (!app.user?.obj?.vanpoolID) return false
    let vh_requests = await api_vp.g('getRequestsList', {
      apiKey: VANPOOL_APIKEY, idVanpool: app.user?.obj?.vanpoolID
    })//apiKey=testchangeme4&idVanpool=1282
    if (typeof vh_requests == 'object') vh_requests = vh_requests['requests']
    if (typeof vh_requests == "object" && vh_requests instanceof Array) {
      const vh_pend_requests = _.filter(vh_requests, (req) => (req.status == 'P'))
      set_rider_pend_list(vh_pend_requests)
      const vh_approved_requests = _.filter(vh_requests, (req) => (req.status == 'A'))
      set_rider_approved_list(vh_approved_requests)

      //save pending ride requests to local-storage
      let vh_reqs_seen = {}
      let vh_pend_requests_grp_idGroup = _.keyBy(vh_pend_requests, 'idRequest')
      ls('vh_reqs_seen', vh_pend_requests_grp_idGroup)
    }
  }
  useEffect(() => {
    console.info(`Vanhoppr useEffect called`)
    const use_effect_async = async () => {
      await get_vanhoppr_reqs()
    }
    use_effect_async().catch(console.warn)
    set_refresh_flag(false)
  }, [app.user, refresh_flag])

  const il_focus = function ({lat, lng}) {
  }
  //Callback for vhRider component
  const vh_rider_cb = async function (approve_or_deny_res) {
    set_refresh_flag(true)
  }
  return <IonPage>
    <Menu title={`Vanpool Actions `} logout={{logout}} app={app}
          app_user={app.user} curpage="vh"></Menu>
    <IonContent className="vh">
      <div>
        <div className={"drider_list_wrapper"}>
          <IonList className={"drider_list"}>
            <IonLabel className={"list_label"}>PICKUP REQUESTS</IonLabel>
            { //VH Pending Riders list
              rider_pend_list.map((rider, i) =>
                <IonItem className={"tiny"} key={i} text-wrap button detail="false" onclick={() => il_focus(rider)}>
                  <VhRider app={app} rider={rider} cb={vh_rider_cb}></VhRider>
                </IonItem>)
            }
          </IonList>
        </div>
        <div id="list_separator"></div>
        <div className={"drider_list_wrapper"}>
          <IonList className={"drider_list"}>
            <IonLabel className={"list_label"}>ACTIVE RIDERS</IonLabel>
            { //VH Approved Riders list
              rider_approved_list.map((rider, i) =>
                <IonItem className={"tiny"} key={i} text-wrap button detail="false" onclick={() => il_focus(rider)}>
                  <VhRider app={app} rider={rider} cb={vh_rider_cb}></VhRider>
                </IonItem>)
            }
          </IonList>
        </div>
      </div>
    </IonContent>
  </IonPage>
}

export default withRouter(Vh);
