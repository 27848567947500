import {title_case} from "./Helper"
import ls from "local-storage"

/**
 * Class User.
 * Usage: pull from local storage; then pass to constructor: let u = new User(user_obj_from_ls)
 */
export default class User {
  constructor(init_user_obj){
    this.obj = init_user_obj
    if (this.obj.hasOwnProperty('obj')) this.obj = this.obj.obj
    const google = window.google
    //ironing data. Also geocode all addresses; store to lat, lng and latlng
    this.obj.addresses?.forEach(addr => {
      addr.full_address_text = User.full_address_from_address_obj(addr) //add full_address_text for each address entry
      addr.full_address_text_w_type = addr.full_address_text + ` (${addr.addrType})` //add full_address_text_w_type for each address entry
    })
    //ironing data END
  }

  static full_address_from_address_obj(address_obj, ignore_address_2){
    let address_lines = ''
    if (! address_obj || typeof address_obj !== 'object') return ''
    if (typeof ignore_address_2 === "undefined" || ! ignore_address_2) {
      ignore_address_2 = false;
    }
    if (ignore_address_2) {
      address_lines = address_obj.addrStreet1;
    } else {
      address_lines = $.grep([address_obj.addrStreet1, address_obj.addrStreet2], Boolean).join(' ');
    }
    return title_case((address_lines + ', ' + address_obj.addrCity + ', ' + address_obj.addrState + ' ' + address_obj.addrZip).trim().toLowerCase())
  }

  /**
   * Callback when gmap is ready
   */
  gmap_ready_cb(){
    this.obj.addresses.forEach(addr => {
      if (! (window.geocoder instanceof google.maps.Geocoder)) return
      window.geocoder.geocode({address: addr.full_address_text}, (result) => {
        result = result.pop();
        if (typeof result !== "object" || ! result.hasOwnProperty('geometry')) return;
        let geo = result.geometry;
        if (! geo.hasOwnProperty('location')) return;
        let location = geo.location;
        [addr.lat, addr.lng] = [location.lat(), location.lng()];
        addr.latlng = new google.maps.LatLng(addr.lat, addr.lng)
      })

    })
  }

  /**
   * extracts first home address from .addresses
   * @returns null|{}
   */
  first_home_address(){
    let res = this.home_addresses()
    if (typeof res.shift == 'function') res = res.shift()
    return res;
  }

  /**
   * extracts first work address from .addresses
   * @returns null|{}
   */
  first_work_address(){
    let res = this.work_addresses()
    if (typeof res.shift == 'function') res = res.shift()
    return res;
  }

  //
  // static full_address_from_address_obj(address_obj, ignore_address_2 = false){
  //   let address_lines = ''
  //   if (! address_obj || typeof address_obj !== 'object') return ''
  //   if (typeof ignore_address_2 === "undefined" || ! ignore_address_2) {
  //     ignore_address_2 = false;
  //   }
  //   if (ignore_address_2) {
  //     address_lines = address_obj.addrStreet1;
  //   } else {
  //     address_lines = $.grep([address_obj.addrStreet1, address_obj.addrStreet2], Boolean).join(' ');
  //   }
  //   return title_case((address_lines + ', ' + address_obj.addrCity + ', ' + address_obj.addrState + ' ' + address_obj.addrZip).trim().toLowerCase())
  // }

  home_address_full(){
    return User.full_address_from_address_obj(this.first_home_address())
  }

  //returns the index of the first home address
  first_home_address_idx(){
    let res = 0
    this.obj.addresses.forEach((addr, i) => {
      if (addr.addrType === 'HOME') res = i
    })
    return res;
  }

  //returns the index of the first work address
  first_work_address_idx(){
    let res = 0
    this.obj.addresses.forEach((addr, i) => {
      if (addr.addrType === 'WORK') res = i
    })
    return res;
  }

  work_addr_full(){
    return User.full_address_from_address_obj(this.first_work_address())
  }

  /**
   * extracts home addresses from .addresses
   * @returns null|{}
   */
  home_addresses(){
    let res = 0;
    if (typeof this.obj.addresses !== 'object' || this.obj.addresses === null || this.obj.addresses.length < 1) {
      return res;
    }
    res = this.obj.addresses.filter(function (v){
      return v.addrType === 'HOME';
    })
    return res;
  }

  reset(){
    const ls = window.localStorage
    this.password_saved = null;
    this.home_address_location = null;
    this.work_address_location = null;
    this.addresses = null;
    this.id_member = null;
    ls.removeItem('addresses');
    ls.removeItem('commuterData');
  }

  setCommuteType(comtype){
    this.commute_type = comtype
    ls('user', this)
  }

  /**
   * extracts work addresses from .addresses
   * @returns null|{}
   */
  work_addresses(){
    let res = 0;
    if (typeof this.obj.addresses !== 'object' || this.obj.addresses === null || this.obj.addresses.length < 1) {
      return res;
    }
    if (this.obj.addresses.length > 1) res = 1 //default to the second address in the list
    res = this.obj.addresses.filter(function (v){
      return v.addrType === 'WORK';
    })
    return res;
  }

  // startingTime(){return this.obj.startHour + ":" + this.obj.startMin + " " + this.obj.startAmPm}
  startingTime(){
    return this.obj?.commuter_data?.fromHRS + ":" + this.obj?.commuter_data?.fromMNS + " " + this.obj?.commuter_data?.fromAMPM
  }

  // endingTime(){ return this.obj.endHour + ":" + this.obj.endMin + " " + this.obj.endAmPm}
  endingTime(){
    return this.obj?.commuter_data?.toHRS + ":" + this.obj?.commuter_data?.toMNS + " " + this.obj?.commuter_data?.toAMPM
  }

  full_name(){
    return this?.obj?.commuter_data?.commuterName || 'N/A'
  }

  first_name(){
    return this?.obj?.commuter_data?.firstName || ' '
  }

  arriveAfter(){
    return this.obj.flexibility
  }

  /**
   * Grab workStartTime, and then append curdate to it. This is because ionDateTime always asks for date
   * from 0800 to 2021-06-04T14:23:00-04:00
   */
  work_start_time_n_date(){
    const wst = this?.obj?.commute_data?.workStartTime.trim()
    if (ls('DEBUG')) console.log(`commute_data.workStartTime is`, wst)
    if (! wst) return '0900' //default to 09:00
    if (! (wst.match(/^\d{4}$/) instanceof Array)) return '0900' //default to 09:00
    //now wst = \d{4}
    return wst
  }

  work_end_time_n_date(){
    const wet = this?.obj?.commute_data?.workEndTime.trim()
    if (ls('DEBUG')) console.log(`commute_data.workEndTime is`, wet)
    if (! wet) return '1700' //default to 09:00
    if (! (wet.match(/^\d{4}$/) instanceof Array)) return '1700' //default to 09:00
    return wet
  }

}
