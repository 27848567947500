import {
  IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonModal, IonPage, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonToast
} from '@ionic/react';
import React, {Fragment, useEffect, useRef, useState} from "react"
// import $ from 'jquery'
import {useHistory} from "react-router"
import Menu from "../components/Menu"
import {app_alert, app_toast, goto_home, logout, mil_time_to_iso, military_time_to_moment} from "../Helper"
import {build_query, MWCOG_ROOT, USER_TYPES} from "../mwcog/core"
import {checkCommuteMode, checkSecondLeg, clearLogs, saveCommuteLogsWithAdditionalLegs} from '../mwcog/TripDiaryValidation'
import {computeTotalVanDriveTime, roundNumber, saveDailyVanLogs} from '../mwcog/IncentivesValidation'
import ls from 'local-storage'
import moment from 'moment'
import _ from 'lodash'

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

// import "@fullcalendar/common/main.css";
// import "@fullcalendar/daygrid/main.css";

import '../scss/clog.scss'
import {input_blurred, input_focused} from "../HelperIonic"
import {chevronBack} from "ionicons/icons"
import {IonDatetimeS} from "../components/ion-datetime-s-react"
import {NULL_DATE} from "../components/ion-datetime-s"

const BASE_IE511_URL = 'https://ie511.org/iecommuter/integrate';
const COMMUTER_URL = 'https://tdm.commuterconnections.org/mwcog/'
const GEOLOCATION_OPTIONS = {enableHighAccuracy: true};
const IS_DEBUG = false
const IS_LOCAL = false

/**
 * CLOG: FullCalendar. Each button is add_edit_button. Upon add edit button clicked; edit_log is triggered, corresponding modal will show.
 * In the modal, trips are shown. Each trip has some legs. Click add_leg will trigger addLegs
 */

export default function Clog({app}){
  const [log_date_clicked, set_log_date_clicked] = useState(null)
  const [username, setUsername] = useState('')
  const [pw, setPw] = useState('')
  const [remember, setRemember] = useState(false)
  const [present_toast, dismiss_toast] = useIonToast()
  const history = useHistory()
  const [oh_end, set_oh_end] = useState()
  const [oh_start, set_oh_start] = useState()
  const [ow_end, set_ow_end] = useState()
  const [ow_start, set_ow_start] = useState()
  const [miles_driven, set_miles_driven] = useState(null)
  const [present_loading, dismiss_loading] = useIonLoading()
  const [cur_date_mdy, set_cur_date_mdy] = useState()
  const [vl_no_commute, set_vl_no_commute] = useState(false)
  const [vl_no_commute_reason, set_vl_no_commute_reason] = useState(false)
  const [sel_date_api, set_sel_date_api] = useState()
  const [show_commute_log_van, set_show_commute_log_van] = useState(false) //whether Commute Log Van Modal should be shown
  const commute_log_van_modal = useRef(null)

  const [show_commute_log_entry_page, set_show_commute_log_entry_page] = useState(false) //whether Commute Log Entry Page should be shown
  const [show_commute_log_flex, set_show_commute_log_flex] = useState(false) //whether Commute Log Entry Page should be shown
  const [toHomeLegAlternateDepartureTimeString, set_toHomeLegAlternateDepartureTimeString] = useState('1888-08-08T08:08:08+00:00') //flex log toHomeLegAlternateDepartureTimeString, will be split to Hour, minute when calling API
  const [toWorkLegAlternateDepartureTimeString, set_toWorkLegAlternateDepartureTimeString] = useState('1888-08-08T08:08:08+00:00') //flex log toWorkLegAlternateDepartureTimeString, will be split to Hour, minute when calling API
  window.calendarRef = React.useRef()

  const commute_log_van_did_dismiss = () => {
    console.log(`Commute log van modal did dismiss`)
    initialize()
  }
  const commute_log_entry_page_did_dismiss = () => {
    console.log(`Commute log entry page did dismiss`)
    initialize()
  }
  const commute_log_flex_did_dismiss = () => {
    console.log(`Commute log flex did dismiss`)
    initialize()
  }
  const commute_log_entry_page_modal = useRef(null);

  useEffect(async () => {
    console.log(`CLOG init called`)
    await initialize()
  }, [])

// App logic

  const [present] = useIonAlert()
  document.querySelector('#login_box') && document.querySelector('#login_box').addEventListener('keypress', function (e){
    if (e.key === 'Enter') {
      // code for enter
      console.log(`login box enter pressed`)
      $('#login_btn').trigger('click')
    }
  })

  "use strict";
  var C = C || {};
  var DATE_FORMAT = 'MM/DD/YYYY';
  var DATE_FORMAT_API = 'M/DD/YYYY';
  var DATE_FORMAT_HTML = 'M/D/YYYY';
  const User_clog_type = useRef({
    type: -1,
    days: [],
    pool_id: ''
  })
  var today = moment.utc(), _10_days_ago = moment.utc().subtract(10, "days"),
    _11_days_ago = moment.utc().subtract(11, "days");


  const COMMUTE_PLACE = {
    101: 'Home',
    102: 'Work',
    103: 'Park & Ride Lot',
    104: 'Bus Stop',
    106: 'Telework Center',
    107: 'Other'
  };

  const COMMUTE_TRAVEL_MODE = {
    0: 'TRAVEL MODE',
    78: 'Drive Alone',
    79: 'Transit',
    80: 'Carpool',
    81: 'Vanpool',
    82: 'Bike',
    83: 'Walk',
    84: 'Telework'
  };
  var full_calendar = {};

  function triggerDialogClose(){
    $('div.ui-dialog-contain a.ui-icon-delete').trigger('click');
  }

  function add_button_to_calendar(){
    if (typeof User_clog_type === "undefined" || ! User_clog_type) {
      return false;
    }
    User_clog_type.current.days = User_clog_type.current.days || [];
    for (let i = 0, day = _.cloneDeep(_10_days_ago); i <= 10; i++, day.add(1, "days")) {
      let date_td = $('td.fc-day[data-date="' + day.format('YYYY-MM-DD') + '"]');
      if (! _.isArray(User_clog_type.current.days)) {
        continue;
      }
      date_td.find('.add_edit_btn_wrapper').remove();
      if (User_clog_type.current.days.indexOf(day.format('DD-MM-YYYY')) !== -1) {
        date_td.find('.fc-daygrid-day-top').append('<div class="add_edit_btn_wrapper"><div class="clearfix"></div><input type="button" class="button add_edit_btn" value="Edit" /></div>');
        $('td.fc-day[data-date="' + day.format('YYYY-MM-DD') + '"]').addClass('has_log');
      } else {
        date_td.find('.fc-daygrid-day-top').append('<div class="add_edit_btn_wrapper"><div class="clearfix"></div><input type="button" class="button add_edit_btn" value="Add" /></div>');
      }
      date_td.unbind('click').on('click', edit_log);
    }
  }

  //called after commute_log_entry modal presented
  async function on_commute_log_entry_page_did_present(){
    console.log(`commute_log_entry modal presented`)
    const date_api = moment(log_date_clicked, 'YYYY-MM-DD').format(DATE_FORMAT_API);
    await get_commute_type(date_api, true);
    await get_saved_days();
    const clog_type = app.user.User_clog_type?.type
    if (clog_type == USER_TYPES.TYPE_CIP) $('.addLegButton').hide();
  }

  /**
   * This also destroys and recreates FullCalendar
   */
  async function get_saved_days(){
    // $('#calendar').fullCalendar('destroy');
    let url = MWCOG_ROOT + "?action=getcalendar&" + build_query();
    let days_return = $.get(url, {}, function (data){
      User_clog_type.current.days = data;
    }, 'json').fail(function (jqXHR, textStatus, errorThrown){
      console.log('Cant get days');
    });
    days_return.then(add_button_to_calendar);
    return days_return;
  }

  async function initialize(){
    $('table.fc-scrollgrid').hide()
    setTimeout(() => {
      if (! calendarRef) return false
      calendarRef.current.getApi().updateSize()
      $('table.fc-scrollgrid').show()
    }, 100)
    setTimeout(() => {
      if (! calendarRef) return false
      calendarRef.current.getApi().updateSize()
      $('table.fc-scrollgrid').show()
    }, 200)
    $.ajaxSetup({crossDomain: true});
    const yesterday = moment.utc().subtract(1, "days");
    //call API to retrieve list of the days (within the last 10 days) that have saved data so those days can be marked green on the calendar
    get_saved_days();
    get_commute_type(today, false);//first call, don't update html yet
  }

  async function zsdf(){
    console.info(`zsdf clicked`)
    let a = 1
    calendarRef.current.getApi().updateSize()
    calendarRef.current.getApi().next()
    calendarRef.current.getApi().prev()

  }

  /**
   * Get Commute Type. Also populate some default trip values.
   * Also print leg html; if button is Edit
   * For type=2 vanlog; also populate #passenger_table inside commute_log_van
   * @param log_date
   * @param  is_update_html bool Whether we update html. Default to false
   */
  async function get_commute_type(log_date, is_update_html = false){
    let leg = {}, trip = {}, leg_index = 1, trip_index = 1, still_has_trip = false, still_has_leg = false,
      trip_n_leg = '';
    //get commute log type
    if (typeof log_date === 'undefined') {
      log_date = moment().format('M/D/YYYY');
    }
    if (typeof log_date === 'object' && (log_date.constructor.name === 'moment' || log_date._isAMomentObject)) {
      log_date = log_date.format('M/D/YYYY');
    }
    const url = MWCOG_ROOT + "?action=getCommuteLogType&" + build_query({'log_date': log_date});
    // $('body').addClass('whirl');
    let promise
    promise = present_loading({message: "Getting commute type from API", duration: 100})
    let type_return = await $.ajax(url);
    User_clog_type.current = type_return
    if (typeof User_clog_type.current.type === "undefined" || ! User_clog_type.current.type) {
      User_clog_type.current.type = 0;
    }
    if (User_clog_type.current.type == 0 && app.user.inFlextimeIncentive == 1) User_clog_type.current.type = USER_TYPES.TYPE_FLEX //flex
    if (! app.user) return
    if (app.user.hasOwnProperty('setCommuteType')) app.user.setCommuteType(User_clog_type.current.type)
    ls('commute_type', User_clog_type.current.type)
    switch (User_clog_type.current.type) {
      case USER_TYPES.TYPE_GENERAL:
      case USER_TYPES.TYPE_FLEX:
      case USER_TYPES.TYPE_CIP: {
        User_clog_type.current.trips = [];
        const trip1alternateDepartTime = type_return?.trip1alternateDepartTime
        const new_work_leg_alt_dep_time_str = mil_time_to_iso(trip1alternateDepartTime)
        if (trip1alternateDepartTime > ' ') set_toWorkLegAlternateDepartureTimeString(new_work_leg_alt_dep_time_str)
        const trip2alternateDepartTime = type_return?.trip2alternateDepartTime
        if (trip2alternateDepartTime > ' ') set_toHomeLegAlternateDepartureTimeString(mil_time_to_iso(trip2alternateDepartTime))
        leg = {};
        trip = {};
        still_has_trip = User_clog_type.current.hasOwnProperty('trip' + trip_index + 'leg1From');
        while (still_has_trip) {
          trip = {
            index: trip_index,
            commute: (User_clog_type.current['trip' + trip_index + 'NoCommute'] === 'N'),
            legs: []
          };
          still_has_leg = false;
          leg_index = 1;
          do {
            trip_n_leg = 'trip' + trip_index + 'leg' + leg_index;
            leg = {
              index: leg_index,
              distance: User_clog_type.current[trip_n_leg + 'Distance'],
              from: User_clog_type.current[trip_n_leg + 'From'],
              mode: User_clog_type.current[trip_n_leg + 'Mode'],
              to: User_clog_type.current[trip_n_leg + 'To']
            };
            if (! (leg.from === 0 || leg.to === 0 || leg.mode === '0')) {
              trip.legs.push(leg);
            }
            leg_index++;
            still_has_leg = User_clog_type.current.hasOwnProperty('trip' + trip_index + 'leg' + leg_index + 'From');
          } while (still_has_leg);
          User_clog_type.current.trips.push(trip);
          trip_index++;
          still_has_trip = User_clog_type.current.hasOwnProperty('trip' + trip_index + 'leg1From');
        }
        if (! is_update_html) {
          break;
        }
        // if (User.trips.length < 2 && User.type === 0) {//for this date there's no data, but this is general log, so we default some values here
        //     User.trips = [{legs: [{from: CM_HOME, to: CM_WORK, mode: CM_CARPOOL, distance: null}]},
        //         {legs: [{from: CM_WORK, to: CM_HOME, mode: CM_CARPOOL, distance: null}]}];
        // }
        //now assign to html
        let trip_form = $('form').filter(
          (i, form) => {
            let user_types_applicable = $(form).data('user_types');
            if (_.isUndefined(user_types_applicable)) {
              return false;
            }
            user_types_applicable = user_types_applicable.toString();
            if (_.isEmpty(user_types_applicable)) {
              return false;
            }
            user_types_applicable = user_types_applicable.split(',');
            return (user_types_applicable.includes(User_clog_type.current.type.toString()));
          }
        );
        let $trips = trip_form.find('.trip_table');

        for (trip_index = 1; trip_index <= User_clog_type.current.trips.length; trip_index++) {
          trip = User_clog_type.current.trips[trip_index - 1];
          let $trip = $($trips[trip_index - 1]);
          $trip.find('.leg').remove();
          for (let leg_index = 1; leg_index <= trip.legs.length; leg_index++) {
            $trip.append(print_leg(leg_index, trip_index, trip.legs[leg_index - 1]));
          }
        }
        if (User_clog_type.current.type !== USER_TYPES.TYPE_FLEX) {
          break;
        }
        //extra logic for TYPE_FLEX
        if (User_clog_type.current.hasOwnProperty('trip1alternateDepartTime')) {
          const trip1AlterDepartTime = military_time_to_moment(User_clog_type.current.trip1alternateDepartTime);
          trip_form.find('input[name="toWorkLegAlternateDepartureTime_hour"]').val(trip1AlterDepartTime.format('hh'));
          trip_form.find('input[name="toWorkLegAlternateDepartureTime_minute"]').val(_.padStart(trip1AlterDepartTime.minute(), 2, '0'));
          trip_form.find('input[name="toWorkLegAlternateDepartureTime_ampm"]').val(trip1AlterDepartTime.hour() >= 12);
        }
        if (User_clog_type.current.hasOwnProperty('trip2alternateDepartTime')) {
          const trip2AlterDepartTime = military_time_to_moment(User_clog_type.current.trip2alternateDepartTime);
          trip_form.find('input[name="toHomeLegAlternateDepartureTime_hour"]').val(trip2AlterDepartTime.format('hh'));
          trip_form.find('input[name="toHomeLegAlternateDepartureTime_minute"]').val(_.padStart(trip2AlterDepartTime.minute(), 2, '0'));
          trip_form.find('input[name="toHomeLegAlternateDepartureTime_ampm"]').val(trip2AlterDepartTime.hour() >= 12);
        }
        let trip1_verified = User_clog_type.current.hasOwnProperty('trip1Verified') && User_clog_type.current.trip1Verified === 'Y';
        trip_form.find('.trip_table[data-trip-index="1"] :input').prop('disabled', trip1_verified);
        trip_form.find('.trip_table[data-trip-index="1"] div.ui-select').toggleClass('ui-state-disabled', trip1_verified);//this is for jQM
        trip_form.find('button.addLegButton').toggle(! trip1_verified);//remove add leg buttons
        trip_form.find('.trip_table_wrapper[data-trip-index="1"]').find('.verified_span').toggle(trip1_verified);//show -verified- span

        let trip2_verified = User_clog_type.current.hasOwnProperty('trip2Verified') && User_clog_type.current.trip2Verified === 'Y';
        trip_form.find('.trip_table[data-trip-index="2"] :input').prop('disabled', trip2_verified);
        trip_form.find('.trip_table[data-trip-index="2"] div.ui-select').toggleClass('ui-state-disabled', trip2_verified);//this is for jQM
        trip_form.find('button.addLegButton').toggle(! trip2_verified);//remove add leg buttons
        trip_form.find('.trip_table_wrapper[data-trip-index="2"]').find('.verified_span').toggle(trip2_verified);//show -verified- span
        break;
      }
      case USER_TYPES.TYPE_VIEW_ONLY_VIP:
      case USER_TYPES.TYPE_VIP: {
        app.user.pool_id = User_clog_type.current.commuter1PoolID;
        let new_vl_noCommute = false
        if (User_clog_type.current.noCommute == 'Y' || User_clog_type.current.noCommuteReason > '') new_vl_noCommute = true
        if (User_clog_type.current.noCommute == 'N') new_vl_noCommute = false
        set_vl_no_commute(new_vl_noCommute)
        let new_vl_noCommuteReason = false
        if (User_clog_type.current.noCommuteReason > '') new_vl_noCommuteReason = User_clog_type.current.noCommuteReason
        set_vl_no_commute_reason(new_vl_noCommuteReason)

        User_clog_type.current.commuters = [];
        let still_has_commuter = false;
        let index = 1;

        do {
          const commuter = {
            index: index,
            id: User_clog_type.current['commuter' + index + 'Id'],
            first_name: User_clog_type.current['commuter' + index + 'FirstName'],
            last_name: User_clog_type.current['commuter' + index + 'LastName'],
            pool_id: User_clog_type.current['commuter' + index + 'PoolID'],
            hb_miles: User_clog_type.current['commuter' + index + 'hbMiles'],
            hb_yes_commute: (User_clog_type.current['commuter' + index + 'hbNoCommute'] == 'N'),
            wb_miles: User_clog_type.current['commuter' + index + 'wbMiles'],
            wb_yes_commute: (User_clog_type.current['commuter' + index + 'wbNoCommute'] == 'N')
          };
          User_clog_type.current.commuters.push(commuter);
          index++;
          still_has_commuter = User_clog_type.current.hasOwnProperty('commuter' + index + 'Id');

        } while (still_has_commuter);

        //populating HTML
        $('.passenger').remove();
        let $passenger_table = $('#passenger_table');
        for (let i = 0; i < User_clog_type.current.commuters.length; i++) {
          const passenger = User_clog_type.current.commuters[i];
          let div_passenger = $('<div/>').addClass('passenger tr');
          div_passenger.attr('data-index', passenger.index - 1);
          let $span = $('<span/>').addClass('td w30');
          $span.append($('<input/>').attr('name', `passenger${i + 1}id`).addClass('id').prop('type', 'hidden').prop('value', passenger.id));
          let passenger_full_name = [passenger.first_name, passenger.last_name].join(' ')
          passenger_full_name = passenger_full_name.toLowerCase()
          $span.append($('<span/>').addClass('name').addClass('text_cap').html(passenger_full_name));
          div_passenger.append($span);

          $span = $('<span/>').addClass('td w20');
          $span.append($('<ion-input/>').addClass('wbMiles').prop('type', 'text').attr('name', `passenger${i + 1}wbMiles`).prop('size', 4).val(passenger.wb_miles));
          div_passenger.append($span);

          $span = $('<span/>').addClass('td w15');
          $span.append($('<ion-checkbox/>').addClass('wbCommute toWork').val(passenger.wb_yes_commute).attr('name', `passenger${i + 1}wbYesCommute`).prop('checked', passenger.wb_yes_commute));
          div_passenger.append($span);

          $span = $('<span/>').addClass('td w20');
          $span.append($('<ion-input/>').addClass('hbMiles').attr('name', `passenger${i + 1}hbMiles`).prop('size', 4).val(passenger.hb_miles));
          div_passenger.append($span);

          $span = $('<span/>');
          $span.append($('<ion-checkbox/>').addClass('hbCommute toHome').attr('name', `passenger${i + 1}hbYesCommute`).val(passenger.hb_yes_commute).prop('checked', passenger.hb_yes_commute));
          div_passenger.append($span);
          $passenger_table.append(div_passenger);
        }
        if (User_clog_type.current.type === USER_TYPES.TYPE_VIEW_ONLY_VIP) {
          $('#view_only_vip_message').removeClass('hidden')
          $('#updateVIPlogButton').addClass('hidden')
        } else {
          $('#view_only_vip_message').addClass('hidden')
          $('#updateVIPlogButton').removeClass('hidden')
        }
        const User_wo_commuter = _.pickBy(User_clog_type.current, function (v, k){
          return k.indexOf('commuter') === -1;
        });
        const van_log_inputs = $('#commute_log_van form :input[data-api_field], #commute_log_van form ion-input[data-api_field]');
        _.each(van_log_inputs, function (v){
          let api_key = $(v).data('api_field');
          if (User_clog_type.current.hasOwnProperty(api_key)) {
            $(v).val(User_clog_type.current[api_key]);
          } else {
            //START add custom defaults if value = null
            switch (v.id) {
              case 'other':
              case 'toll':
              case 'park':
              case 'pricePerGallon':
              case 'gallons':
              case 'gas':
                $(v).val('0');
                break;
              default:
                $(v).val('');
            }
            // END
          }
        });
        break;
      }
      default:
        break;
    }
    if (User_clog_type.current.type === USER_TYPES.TYPE_CIP || User_clog_type.current.type === USER_TYPES.TYPE_FLEX) {
      $('.cip_message').show();
    } else {
      $('.cip_message').hide();
    }

    app.user.User_clog_type = User_clog_type.current
    // reset fields in the popup
    calc_odometer()

    dismiss_loading()
  }

  async function edit_log(e){
    e.preventDefault()
    e.stopPropagation()
    console.log(`edit_log called`)
    let $e = $($(e.target).closest('td'));
    let date_api = moment($e.data().date, 'YYYY-MM-DD').format(DATE_FORMAT_API);
    set_log_date_clicked($e.data().date)
    // $('body').addClass('has_dialog');
    $('.cur_date').html(date_api);
    set_cur_date_mdy(moment($e.data().date, 'YYYY-MM-DD').format(DATE_FORMAT_HTML));
    setTimeout(() => {
      $('.cur_date_val').val(date_api)
    }, 2000) //Future set cur_date_val properly
    set_sel_date_api(date_api)
    $('#createdBy').val(window.localStorage.getItem('username'));
    switch (User_clog_type.current.type) {
      case 0: {
        console.log(`Setting show_commute_log_entry_page true`)
        set_show_commute_log_entry_page(true)
        $('.addLegButton').show();
        await get_commute_type(date_api, true);
        await get_saved_days();
        break;
      }
      case 1: {
        return set_show_commute_log_entry_page(true)
      }
      case 2:
      case 3: {
        // $("body").pagecontainer("change", "#commute_log_van", {role: "dialog"});
        // await set_show_commute_log_van(true)
        commute_log_van_modal.current?.present()
        await get_commute_type(date_api, true);
        computeTotalVanDriveTime()
        calc_odometer()
        await get_saved_days();
        break;
      }
      case 4: {//flex
        set_show_commute_log_flex(true)
        await get_commute_type(date_api, true);
        await get_saved_days();
        break;
      }
      default:
        break;
    }

  }

// function save_and_close() {
//     $('body').removeClass('has_dialog');
// }

  /**
   * showHideDropDown - copied from tdm.commuterconnections.org page
   * 10/7/22 BN rewrite for React
   * @param e React SyntheticEvent, from the triggering checkbox
   * @param box
   * @param id
   */
  function showHideDropDown(e, box, id){
    let toggle_value = false
    toggle_value = e?.detail?.checked
    let t1l1from = document.getElementById("T1L1From") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t1l1mode = document.getElementById("T1L1Mode") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t1l1to = document.getElementById("T1L1To") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t1l1dist = document.getElementById("T1L1Distance") || {
      value: null,
      disabled: null,
      style: {backgroundColor: null}
    }
    let t1l2from = document.getElementById("T1L2From") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t1l2mode = document.getElementById("T1L2Mode") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t1l2dist = document.getElementById("T1L2Distance") || {
      value: null,
      disabled: null,
      style: {backgroundColor: null}
    }
    let t1l2to = document.getElementById("T1L2To") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t2l1from = document.getElementById("T2L1From") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t2l1to = document.getElementById("T2L1To") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t2l1mode = document.getElementById("T2L1Mode") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t2l1dist = document.getElementById("T2L1Distance") || {
      value: null,
      disabled: null,
      style: {backgroundColor: null}
    }
    let t2l2from = document.getElementById("T2L2From") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t2l2to = document.getElementById("T2L2To") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t2l2mode = document.getElementById("T2L2Mode") || {value: null, disabled: null, style: {backgroundColor: null}}
    let t2l2dist = document.getElementById("T2L2Distance") || {
      value: null,
      disabled: null,
      style: {backgroundColor: null}
    }

    if (toggle_value) {
      document.getElementById(box).style.display = '';//show the target element

      t1l1from.value = '0';
      t1l1from.disabled = true;
      t1l1from.style.backgroundColor = '#C4C4C4';
      t1l2from.value = '0';
      t1l2from.disabled = true;
      t1l2from.style.backgroundColor = '#C4C4C4';
      t2l1from.value = '0';
      t2l1from.disabled = true;
      t2l1from.style.backgroundColor = '#C4C4C4';
      t2l2from.value = '0';
      t2l2from.disabled = true;
      t2l2from.style.backgroundColor = '#C4C4C4';

      t1l1to.value = '0';
      t1l1to.disabled = true;
      t1l1to.style.backgroundColor = '#C4C4C4';
      t1l2to.value = '0';
      t1l2to.disabled = true;
      t1l2to.style.backgroundColor = '#C4C4C4';
      t2l1to.value = '0';
      t2l1to.disabled = true;
      t2l1to.style.backgroundColor = '#C4C4C4';
      t2l2to.value = '0';
      t2l2to.disabled = true;
      t2l2to.style.backgroundColor = '#C4C4C4';

      t1l1mode.value = '0';
      t1l1mode.disabled = true;
      t1l1mode.style.backgroundColor = '#C4C4C4';
      t1l2mode.value = '0';
      t1l2mode.disabled = true;
      t1l2mode.style.backgroundColor = '#C4C4C4';
      t2l1mode.value = '0';
      t2l1mode.disabled = true;
      t2l1mode.style.backgroundColor = '#C4C4C4';
      t2l2mode.value = '0';
      t2l2mode.disabled = true;
      t2l2mode.style.backgroundColor = '#C4C4C4';


      t1l1dist.value = '0';
      t1l2dist.value = '0';
      t2l1dist.value = '0';
      t2l2dist.value = '0';
      t1l1dist.readOnly = true;
      t1l2dist.readOnly = true;
      t2l1dist.readOnly = true;
      t2l2dist.readOnly = true;
      t1l1dist.style.backgroundColor = '#C4C4C4';
      t1l2dist.style.backgroundColor = '#C4C4C4';
      t2l1dist.style.backgroundColor = '#C4C4C4';
      t2l2dist.style.backgroundColor = '#C4C4C4';
    } else {
      document.getElementById(box).style.display = 'none';

      t1l1from.disabled = false
      t1l1from.style.backgroundColor = 'white';
      t1l2from.disabled = false;
      t1l2from.style.backgroundColor = 'white';
      t2l1from.disabled = false;
      t2l1from.style.backgroundColor = 'white';
      t2l2from.disabled = false;
      t2l2from.style.backgroundColor = 'white';

      t1l1to.disabled = false;
      t1l1to.style.backgroundColor = 'white';
      t1l2to.disabled = false;
      t1l2to.style.backgroundColor = 'white';
      t2l1to.disabled = false;
      t2l1to.style.backgroundColor = 'white';
      t2l2to.disabled = false;
      t2l2to.style.backgroundColor = 'white';


      t1l1mode.disabled = false;
      t1l1mode.style.backgroundColor = 'white';
      t1l2mode.disabled = false;
      t1l2mode.style.backgroundColor = 'white';
      t2l1mode.disabled = false;
      t2l1mode.style.backgroundColor = 'white';
      t2l2mode.disabled = false;
      t2l2mode.style.backgroundColor = 'white';

      t1l1dist.readOnly = false;
      t1l2dist.readOnly = false;
      t2l1dist.readOnly = false;
      t2l2dist.readOnly = false;
      t1l1dist.style.backgroundColor = 'white';
      t1l2dist.style.backgroundColor = 'white';
      t2l1dist.style.backgroundColor = 'white';
      t2l2dist.style.backgroundColor = 'white';

    }

  }

  function print_leg(index, trip_index, data){
    if (data.from === null) {
      data.from = 0;
    }
    if (data.to === null) {
      data.to = 0;
    }
    if (data.mode === null) {
      data.mode = 0;
    }
    let div_leg = $('<div class="leg" data-leg-index="' + index + '">'), i = 0, T1L1 = 'T' + trip_index + 'L' + index;
    div_leg.html('<div class="header"><b class="ui-table-cell-label">LEG <span class="index">' + index + '</span></b></div>');
    let span_form = $('<span class="w48 data">');
    let select = $('<ion-select interface="action-sheet" name="' + T1L1 + 'From" placeholder="From" class=" select1 from">')
    select.append('<ion-select-option value="0">FROM</ion-select-option>');
    $.each(COMMUTE_PLACE, function (k, v){
      select.append('<ion-select-option value="' + k + '">' + v + '</ion-select-option>');
    });
    select.val(data.from);
    select.on('ionChange', function (e){
      e.preventDefault()
      e.stopPropagation()
      checkSecondLeg(this, trip_index)
    })
    span_form.append(select);
    div_leg.append(span_form);

    let span_to = $('<span class="w48 data pull-right">');
    select = $('<ion-select name="' + T1L1 + 'To" placeholder="To" interface="action-sheet" class="select1 to">').prop('onChange', 'checkSecondLeg(this,' + trip_index + ')');
    select.append('<ion-select-option value="0">TO</ion-select-option>');
    $.each(COMMUTE_PLACE, function (k, v){
      select.append('<ion-select-option value="' + k + '">' + v + '</ion-select-option>');
    });
    select.val(data.to);
    span_to.append(select);
    div_leg.append(span_to);

    let span_mode = $('<span class="w48">');
    select = $('<ion-select name="' + T1L1 + 'Mode" placeholder="Travel Mode" interface="action-sheet" class="select1 mode">').prop('onChange', 'checkCommuteMode(this,"T' + trip_index + 'L' + index + 'Distance")');
    $.each(COMMUTE_TRAVEL_MODE, function (k, v){
      select.append('<ion-select-option value="' + k + '">' + v + '</ion-select-option>');
    });
    select.val(data.mode);
    span_mode.append(select);
    div_leg.append(span_mode);

    div_leg.append('<span class="w48 pull-right"><ion-input class="textsm distance" name="' + T1L1 + 'Distance" type="number" size="1" maxLength="3" ' +
      'id="' + T1L1 + 'Distance" value="' + data.distance + '" placeholder="Distance (miles)"></ion-input></span>');

    return div_leg;
  }

  function addLeg(e){
    console.log(`Add Leg`)
    let $trip_table = $(e.target).closest('.trip_table');
    let trip_index = $trip_table.data('trip-index');
    let num_leg = $trip_table.find('.leg').length;//e.g. 2
    $trip_table.append(print_leg(num_leg + 1, trip_index, {from: 0, to: 0, mode: 0, distance: ''}));
    // $('input[type=number]').textinput();
  }

  function selectAllPassenger(){
    // let is_checked = document.getElementById("selectAll").checked;
    $('.passenger .toWork,.passenger .toHome').prop('checked', true);

  }

  function selectNonePassenger(){
    // let is_checked = document.getElementById("selectAll").checked;
    $('.passenger .toWork,.passenger .toHome').prop('checked', false);

  }

  /**
   * Calculate odometer values
   */
  function calc_odometer(){
    let startToWork = parseFloat(ow_start);
    let endToWork = parseFloat(ow_end);
    let startToHome = parseFloat(oh_start);
    let endToHome = parseFloat(oh_end);
    // Fill in the widget, rounded to 10ths:
    const sum_miles_driven = roundNumber(((endToWork - startToWork) + (endToHome - startToHome)), 1)
    if (! isNaN(sum_miles_driven)) set_miles_driven(sum_miles_driven)
    else set_miles_driven(null)
  }

  return (
    <IonPage id="page_clog" className="page_routed">
      <Menu title="Commute Log Calendar" logout={{logout}} app={app} curpage="clog"></Menu>
      <IonContent fullscreen background="none" id="rm_cont">
        <p className="p03">Tap a date in the calendar below to log trips for that date</p>
        {/*<IonButton onClick={() => {
          let a = 687
          zsdf()
        }}>zsdf</IonButton>*/}
        <div id="fullcalendar_wrapper">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            viewClassNames="borderless"
            datesSet={async function (info){
              console.log(`fc dates set`)
              await get_saved_days();
              //enable other month's days if they are in range
              $('div.fc').find('.fc-day-top, .fc-day').each((i, e) => {
                let date = moment($(e).data('date'));
                if (date >= _10_days_ago && date <= today) {
                  $(e).addClass('fc-force_show').removeClass('disabled');
                }
              });
              /*
        const full_calendar = new FullCalendar.Calendar(cal_el,
          {
            height: 470,
            dayRender: function (date, cell){
              if (date < _10_days_ago || date > today) {
                $(cell).addClass('disabled');
              } else {
                $(cell).removeClass('fc-other-month');
              }
            },
            eventAfterAllRender: async function (){
              console.log(`event after render`);
            }
          }
        )

        full_calendar.render()
    */
            }}

            // events={events}
          />
        </div>

        <IonContent id="calendar_wrapper">

          {/*<div id="calendar"></div>*/}
          <script>
            var today = new Date();
            var today_string = today.getMonth() + '/' + today.getDay()
          </script>

          {/*//for user type VIP - 3*/}
          <IonModal ref={commute_log_van_modal} trigger="open_commute_log_van_modal" onDidDismiss={commute_log_van_did_dismiss}
                    onWillPresent={() => {
                      console.log(`Commute log van will present`)
                    }} className="full_width full_height">
            {/*<IonModal isOpen={show_commute_log_van} onDidDismiss={commute_log_van_did_dismiss}
                    onWillPresent={() => {
                      console.log(`Commute log van will present`)
                    }} className="full_width full_height">*/}
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton slot="start" onClick={() => commute_log_van_modal.current?.dismiss()}>
                    {/*<span><svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path fill="none" stroke="currentColor" strokeLinecap="round"
                                                                                                                                             strokeLinejoin="round" strokeWidth="48"
                                                                                                                                             d="M328 112L184 256l144 144" /></svg></span>*/}
                    <IonIcon icon={chevronBack}>

                    </IonIcon>
                  </IonButton>
                </IonButtons>
                <IonTitle>Pool Rewards</IonTitle>
                <IonButtons slot="primary">
                  <IonMenuButton auto-hide="false" className="darkblue_invi" menu="none"></IonMenuButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent id="commute_log_van">
              <form className="padding_sm" name="vanlog" data-user_types="2">
                <div id="view_only_vip_message">
                  <br />
                  Only the van driver or pool coordinator is allowed to add to or change the log.
                  Please contact the person responsible for logging trips if you need help.
                </div>
                <div>
                  <span>Update Van Log For:</span>
                  <br /><span className="cur_date_mdy">{cur_date_mdy}</span><span className="cur_date hidden"></span>
                  <br />
                </div>
                <input type="hidden" name="tripDate" id="tripDate" className="cur_date_val" />
                <input type="hidden" readOnly name="action" id="action" value="saveCommuteLogVIP" />
                <input type="hidden" className="createdDate" name="createdT" id="createdT" />
                <input type="hidden" name="createdBy" id="createdBy" />
                <input type="hidden" name="isAdmin" id="isAdmin" value="false" />
                <div id="van_log">
                  <div className="full_width_title">Passenger Information</div>
                  <div id="passenger_table">
                    <div className="th heading">
                      <span className="td w30">Passenger Name</span>
                      <span className="td w20">To Work</span>
                      <span className="td w15">Present</span>
                      <span className="td w20">To Home</span>
                      <span className="td w15">Present</span>
                    </div>
                    <div>
                            <span>
                              <IonButton size="small" name="selectAll" id="selectAll" onClick={selectAllPassenger}>Select All</IonButton>
                            </span>
                      <span>
                              <IonButton size="small" name="selectAll" id="selectAll" onClick={selectNonePassenger}>Select None</IonButton>
                            </span>
                      <span className="float_right">
                                <IonItem><IonLabel>Did Not Operate</IonLabel>
                                  <IonToggle size="small" name="noCommute" id="noCommute" checked={vl_no_commute}
                                             onIonChange={(e) => showHideDropDown(e, 'noCommuteDropDown', 'toWork', 'toHome', 'wbMiles', 'hbMiles')}>
                                </IonToggle>
                                  </IonItem>
                                <IonItem id="noCommuteDropDown" style={{display: "none"}}>
                                  <IonLabel>Reason for No-Commute</IonLabel>
                                  <IonSelect name="noCommuteReason" className="select1" value={vl_no_commute_reason} interface="action-sheet">
                                    <IonSelectOption value="0">No Reason</IonSelectOption>
                                    <IonSelectOption value="110">Inclement Weather</IonSelectOption>
                                    <IonSelectOption value="111" selected>Flex Day</IonSelectOption>
                                    <IonSelectOption value="112">Illness / Sick Day</IonSelectOption>
                                    <IonSelectOption value="113">Holiday</IonSelectOption>
                                  </IonSelect>
                                  </IonItem>
                        </span>
                    </div>
                    <br />
                    {/*end buttons*/}
                  </div>
                  {/*end passenger table*/}
                  <div className="full_width_title">Time Record</div>
                  <div className="table p03">
                    <div className="th">
                      <div className="td w30">Traveling from</div>
                      <div className="td w30">Departed</div>
                      <div className="td w30">Arrived</div>
                    </div>
                    <div className="tr">
                      <span className="td w30">Home to Work</span>
                      <span className="td w30"><IonInput maxLength="8" size="7" type="text"
                                                         name="twStart" id="twStart" data-api_field="toWorkStartTime" onBlur={(e) => {
                        computeTotalVanDriveTime(e)
                      }}
                      />
                            </span>
                      <span className="td w30"><IonInput maxLength="8" size="7" type="text"
                                                         name="twEnd" id="twEnd" data-api_field="toWorkEndTime" onBlur={(e) => {
                        computeTotalVanDriveTime(e)
                      }} /></span>
                    </div>
                    <div className="tr">
                      <span className="td w30">Work to Home</span>
                      <span className="td w30"><IonInput maxLength="8" size="7" type="text"
                                                         name="thStart" id="thStart" data-api_field="toHomeStartTime" onBlur={(e) => {
                        computeTotalVanDriveTime(e)
                      }} />
                            </span>
                      <span className="td w30"><IonInput maxLength="8" size="7" type="text"
                                                         name="thEnd" id="thEnd" data-api_field="toHomeEndTime" onBlur={(e) => {
                        computeTotalVanDriveTime(e)
                      }} /></span>
                    </div>
                    <div className="tr">
                      <span className="td w30">Total Van Travel Time</span>
                      <span className="td w30"><IonInput size="7" type="text"
                                                         name="totVanDriveTime" id="totVanDriveTime" readOnly />
                            </span>
                    </div>
                  </div>
                  <br />
                  <div className="full_width_title">Odometer Reading</div>
                  <div className="table p03">
                    <div className="th">
                      <div className="td w30">Traveling from</div>
                      <div className="td w35">Odometer Start</div>
                      <div className="td w35">Odometer End</div>
                    </div>
                    <div className="tr">
                      <span className="td w30">Home to Work</span>
                      <span className="td w35">
                        <IonInput onBlur={(e) => {
                          set_ow_start(e.target.value)
                          calc_odometer()
                        }}
                                  onIonBlur={(e) => {
                                    set_ow_start(e.target.value)
                                    calc_odometer()
                                  }}
                                  size="7" type="text" name="owStart" id="owStart" value={ow_start} data-api_field="toWorkOdoStart" />
                            </span>
                      <span className="td w35"><IonInput onBlur={(e) => {
                        set_ow_end(e.target.value)
                        calc_odometer()
                      }}
                                                         onIonBlur={(e) => {
                                                           set_ow_end(e.target.value)
                                                           calc_odometer()
                                                         }}
                                                         size="7" type="text" name="owEnd" id="owEnd" value={ow_end} data-api_field="toWorkOdoEnd" />
                            </span>
                    </div>
                    <div className="tr">
                      <span className="td w30">Work to Home</span>
                      <span className="td w35"><IonInput onBlur={(e) => {
                        set_oh_start(e.target.value)
                        calc_odometer()
                      }}
                                                         onIonBlur={(e) => {
                                                           set_oh_start(e.target.value)
                                                           calc_odometer()
                                                         }}
                                                         size="7" type="text" name="ohStart" id="ohStart" value={oh_start} data-api_field="toHomeOdoStart" />
                            </span>
                      <span className="td w35">
                        <IonInput onBlur={(e) => {
                          set_oh_end(e.target.value)
                          calc_odometer()
                        }}
                                  onIonBlur={(e) => {
                                    set_oh_end(e.target.value)
                                    calc_odometer()
                                  }}
                                  size="7" type="text" name="ohEnd" id="ohEnd" value={oh_end} data-api_field="toHomeOdoEnd" />
                      </span>
                    </div>
                    <div className="tr">
                      <span className="td w30">Miles Driven Today</span>
                      <span className="td w35"><IonInput size="7" type="text" name="milesDriven"
                                                         id="milesDriven" value={miles_driven} readOnly />
                            </span>
                    </div>
                  </div>
                  <br />
                  <div className="full_width_title">Expense Record</div>
                  <div className="table p03">
                    <div className="th">
                      <div className="td w30">Type</div>
                      <div className="td w70">Amount</div>
                    </div>
                    <div className="tr">
                      <span className="td w30">Gas Total $</span>
                      <span className="td w70"><IonInput size="17" type="text" name="gas" id="gas" data-api_field="gasTotal" /></span>
                    </div>
                    <div className="tr">
                      <span className="td w30">Gallons</span>
                      <span className="td w70"><IonInput size="17" type="text" name="gallons"
                                                         id="gallons" data-api_field="gallons" />
                            </span>
                    </div>
                    <div className="tr">
                      <span className="td w30">Price per Gallon $</span>
                      <span className="td w70"><IonInput size="17" type="text" name="pricePerGallon"
                                                         id="pricePerGallon" data-api_field="gasPerGallon" onIonFocus={input_focused} onBlur={input_blurred} />
                            </span>
                    </div>
                    <div className="tr">
                      <span className="td w30">Parking $</span>
                      <span className="td w70"><IonInput size="17" type="text" name="park" id="park" data-api_field="parking" onIonFocus={input_focused} onBlur={input_blurred} />
                            </span>
                    </div>
                    <div className="tr">
                      <span className="td w30">Tolls $</span>
                      <span className="td w70"><IonInput size="17" type="text" name="toll" id="toll" data-api_field="tolls" onIonFocus={input_focused} onBlur={input_blurred} />
                            </span>
                    </div>
                    <div className="tr">
                      <span className="td w30">Other $</span>
                      <span className="td w70"><IonInput size="17" type="text" name="other"
                                                         id="other" data-api_field="otherExpenses" onIonFocus={input_focused} onBlur={input_blurred} />
                            </span>
                    </div>
                    <div className="tr">
                      <span className="td w30">Description</span>
                      <span className="td w70"><IonInput size="17" type="text" maxLength="200" defaultValue="" name="specify" id="specify" data-api_field="otherExpensesDesc"
                                                         onIonFocus={input_focused} onBlur={input_blurred} />
                            </span>
                    </div>
                  </div>
                  {/*end expense record table*/}
                  <div>
                      <span>
                        <div id="updateVIPlogButton">
                          <IonButton className="button-medium"
                                     onClick={(e) => saveDailyVanLogs(e, app)}>Update Log</IonButton>
                        </div>

                        <IonButton expand="block"
                          // onClick={() => set_show_commute_log_van(false)}>
                                   onClick={() => commute_log_van_modal.current?.dismiss()}>
                          Back
                        </IonButton>
                      </span>
                  </div>
                </div>
                {/*end van_log*/}


              </form>
            </IonContent>
          </IonModal>
          {/*// for user type FLEX - 4*/}
          <IonModal isOpen={show_commute_log_flex} onDidDismiss={commute_log_flex_did_dismiss}
                    onWillPresent={() => {
                      console.log(`Commute log flex will present`)
                    }} className="full_width full_height">
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  {/*<button type="button" onClick={() => {
                    set_show_commute_log_van(false)
                    set_show_commute_log_flex(false)
                  }}
                          className="button-native icn back" aria-label="menu">
                  </button>*/}
                  <IonIcon icon={chevronBack} onClick={() => {
                    set_show_commute_log_van(false)
                    set_show_commute_log_flex(false)
                  }
                  }>

                  </IonIcon>
                </IonButtons>
                <IonTitle>Flex Commute Log</IonTitle>
                <IonButtons slot="primary" className="hidden">
                  <IonMenuButton auto-hide="false" className="darkblue_invi" menu="none"></IonMenuButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent id="commute_log_flex">
              <form name="TripDiaryList" data-action="saveCommuteLogFlex" data-user_types="4">
                <input type="hidden" name="tripDate" className="cur_date_val" />
                <div className="ui-table-reflow">
                  <div>
                    <div className="w100 text_center">
                      <span className="cur_date_mdy text_center" style={{fontSize: "1.6em"}}>{cur_date_mdy}</span>
                      <span className="cur_date hidden"></span>
                    </div>

                    <div className="cip_message">
                        <span align="left">If you are a Pool Rewards applicant, please note your pool must be approved by
                          Commuter Connections staff before you can start logging trips to earn incentive payments.
                          <br /><br />
                          Once your carpool partners have joined your carpool and your application is approved, you
                          will receive an email with a start date and additional instructions for logging your
                          commute.
                          <br /><br />
                          You will not be eligible to earn rewards before the designated start date.
                        </span>
                    </div>

                    <div>
                      <div className="data trip_table_wrapper" data-trip-index="1">
                        <h3 className="TripHeader">Trip 1: Home To Work <span className="verified_span"
                                                                              style={{display: "none"}}> - verified - </span>
                        </h3>
                        <div className="ui-table-reflow tall_table LegCommuteTable1">
                          <div className="trip_table home_to_work_wrapper" data-trip-index="1">
                            <div className="leg" data-leg-index="1">
                              <span className="header"><b className="ui-table-cell-label">LEG <span
                                className="index">1</span></b>
                              </span>
                              <span className="w48 data">
                                <select name="T1L1From" className="select1 from T1L1From" defaultValue="101"
                                        onChange={(e) => {
                                          checkSecondLeg(e, '1')
                                        }}>
                                  <option value="0"></option>
                                  <option value="101">Home</option>
                                  <option value="102">Work</option>
                                  <option value="103">Park &amp; Ride Lot</option>
                                  <option value="104">Bus Stop</option>
                                  <option value="106">Telework Center</option>
                                  <option value="107">Other</option>
                                </select>
                              </span>
                              <span className="w48 pull-right data">
                                <select name="T1L1To" className="select1 to T1L1To" defaultValue="102"
                                        onChange={(e) => {
                                          checkSecondLeg(e, '1')
                                        }}>
                                  <option value="0"></option>
                                  <option value="101">Home</option>
                                  <option value="102">Work</option>
                                  <option value="103">Park &amp; Ride Lot</option>
                                  <option value="104">Bus Stop</option>
                                  <option value="106">Telework Center</option>
                                  <option value="107">Other</option>
                                </select>
                              </span>
                              <span className="w48 data">
                                <select name="T1L1Mode" className="select1 mode T1L1Mode" defaultValue="78"
                                        onChange={(e) => {
                                          checkCommuteMode(e, 'T1L1Distance')
                                        }}>
                                  <option value="0"></option>
                                  <option value="78">Drive Alone</option>
                                  <option value="79">Transit</option>
                                  <option value="80">Carpool</option>
                                  <option value="81">Vanpool</option>
                                  <option value="82">Bike</option>
                                  <option value="83">Walk</option>
                                  <option value="84">Telework</option>
                                </select>
                              </span>
                              <span className="w48 pull-right data">
                                <input className="textsm distance T1L1Distance" type="number" size="1" maxLength="3"
                                       name="T1L1Distance" defaultValue="2" />
                              </span>
                            </div>
                            <div className="leg" data-leg-index="2">
                              <span className="header"><b className="ui-table-cell-label">LEG <span
                                className="index">2</span></b>
                              </span>
                              <span className="w48 data">
                                <select name="T1L2From" className="select1 from T1L2From" defaultValue="0"
                                        onChange={(e) => {
                                          checkSecondLeg(e, '1')
                                        }}>
                                  <option value="0">From</option>
                                  <option value="101">Home</option>
                                  <option value="102">Work</option>
                                  <option value="103">Park &amp; Ride Lot</option>
                                  <option value="104">Bus Stop</option>
                                  <option value="106">Telework Center</option>
                                  <option value="107">Other</option>

                                </select>
                              </span>
                              <span className="w48 pull-right data">
                                <select name="T1L2To" className="select1 to T1L2To" defaultValue="0" onChange={(e) => {
                                  checkSecondLeg(e, '1')
                                }}>
                                  <option value="0">To</option>
                                  <option value="101">Home</option>
                                  <option value="102">Work</option>
                                  <option value="103">Park &amp; Ride Lot</option>
                                  <option value="104">Bus Stop</option>
                                  <option value="106">Telework Center</option>
                                  <option value="107">Other</option>
                                </select>
                              </span>
                              <span className="w48 data">
                                <select name="T1L2Mode" className="select1 mode T1L2Mode" defaultValue="0"
                                        onChange={(e) => {
                                          checkCommuteMode(e, 'T1L2Distance')
                                        }}>
                                  <option value="0">Travel Mode</option>
                                  <option value="78">Drive Alone</option>
                                  <option value="79">Transit</option>
                                  <option value="80">Carpool</option>
                                  <option value="81">Vanpool</option>
                                  <option value="82">Bike</option>
                                  <option value="83">Walk</option>
                                  <option value="84">Telework</option>
                                </select>
                              </span>
                              <span className=" w48 pull-right data">
                                <input className="textsm distance T1L2Distance" type="text" size="1" maxLength="3"
                                       name="T1L2Distance" defaultValue="" />
                              </span>
                            </div>
                            <div className="pull-right w100">
                              <IonButton type="button" className="addLegButton bk_green button-medium"
                                         onClick={addLeg}>Add Leg
                              </IonButton>
                            </div>
                            <div className="w-100">
                              {/*<IonButton onClick={()=> {
                                set_toWorkLegAlternateDepartureTimeString('2023-01-01T15:45+00:00')
                              }
                              }>
                                zsdf set alt dep time
                              </IonButton>
                              <IonButton onClick={()=> {
                                set_toWorkLegAlternateDepartureTimeString('2023-01-01T16:45+00:00')
                              }
                              }>
                                zsdf set alt dep time 2
                              </IonButton>*/}
                              <span className="data ion_dts_inline_label">Alternate Departure Time:<span className="red">*</span></span>
                              {/*<span className="w33 float_left"><IonInput type="number" placeholder="hh" name="toWorkLegAlternateDepartureTime_hour"
                                                                         step="1" min="0" max="12" /></span>
                              <span className="w33 float_left"><IonInput type="number" placeholder="mm"
                                                                         name="toWorkLegAlternateDepartureTime_minute" step="1"
                                                                         min="0" max="59" /></span>
                              <span className="w33 float_left"><IonSelect interface="action-sheet" defaultValue="am" value="am"
                                                                          name="toWorkLegAlternateDepartureTime_ampm">
                                <IonSelectOption value="am">AM</IonSelectOption>
                                <IonSelectOption value="pm">PM</IonSelectOption>
                              </IonSelect></span>
                              todob remove this
                              */}
                              <IonDatetimeS extraClass="ion-float-right" noyear={true} id="toWorkLegAlternateDepartureTimeString" placeholder="" isDateEnabled={() => false}
                                            name="toWorkLegAlternateDepartureTimeString"
                                            pickerFormat="hh:mm:ss A" preferWheel="false"
                                            onIonChange={(e) => {
                                              console.log(`new toWorkLegAlternateDepartureTimeString time: `, e.target.value)
                                              set_toWorkLegAlternateDepartureTimeString(e.target.value)
                                            }
                                            }
                                            minuteValues="0,15,30,45"
                                            value={toWorkLegAlternateDepartureTimeString}
                              > <span slot="time-label"></span>
                              </IonDatetimeS>
                            </div>


                          </div>{/*<!-- end home_to_work_wrapper -->*/}
                        </div>
                      </div>
                    </div>

                    <div>
                        <span className="data trip_table_wrapper" data-trip-index="2">
                          <h3 className="TripHeader">Trip 2: Work To Home <span className="verified_span"
                                                                                style={{display: "none"}}> - verified - </span>
                          </h3>
                          <div className="tall_table LegCommuteTable2 w-100">
                            <div className="trip_table work_to_home_wrapper" data-trip-index="2">
                            <div className="leg" data-leg-index="1">
                              <span className="header"><b className="ui-table-cell-label">LEG <span
                                className="index">1</span></b></span>
                              <span className="data w48">
                                <select name="T2L1From" className="select1 from T2L1From" defaultValue="102"
                                        onChange={(e) => {
                                          checkSecondLeg(e, '2')
                                        }}>
                                  <option value="0"></option>
                                  <option value="101">Home</option>
                                  <option value="102">Work</option>
                                  <option value="103">Park &amp; Ride Lot</option>
                                  <option value="104">Bus Stop</option>
                                  <option value="106">Telework Center</option>
                                  <option value="107">Other</option>
                                </select>
                              </span>
                              <span className="data w48 pull-right">
                                <select name="T2L1To" className="select1 to T2L1To" defaultValue="101"
                                        onChange={(e) => {
                                          checkSecondLeg(e, '2')
                                        }}>
                                  <option value="0"></option>
                                  <option value="101">Home</option>
                                  <option value="102">Work</option>
                                  <option value="103">Park &amp; Ride Lot</option>
                                  <option value="104">Bus Stop</option>
                                  <option value="106">Telework Center</option>
                                  <option value="107">Other</option>
                                </select>
                              </span>
                              <span className="data w48">
                                <select name="T2L1Mode" className="select1 mode T2L1Mode" defaultValue="80"
                                        onChange={(e) => {
                                          checkCommuteMode(e, 'T2L1Distance')
                                        }}>
                                  <option value="0"></option>
                                  <option value="78">Drive Alone</option>
                                  <option value="79">Transit</option>
                                  <option value="80">Carpool</option>
                                  <option value="81">Vanpool</option>
                                  <option value="82">Bike</option>
                                  <option value="83">Walk</option>
                                  <option value="84">Telework</option>
                                </select>
                              </span>
                              <span className="data w48 pull-right">
                                <input className="textsm mode T2L1Distance" type="text" size="1" maxLength="3"
                                       name="T2L1Distance" defaultValue="2" />
                              </span>
                            </div>
                            <div className="leg" data-leg-index="2">
                              <span className="header"><b className="ui-table-cell-label">LEG <span
                                className="index">2</span></b>
                              </span>
                              <span className="data w48">
                                <select name="T2L2From" className="select1 from T2L2From" onChange={(e) => {
                                  checkSecondLeg(e, '2')
                                }}>
                                  <option value="0">From</option>
                                  <option value="101">Home</option>
                                  <option value="102">Work</option>
                                  <option value="103">Park &amp; Ride Lot</option>
                                  <option value="104">Bus Stop</option>
                                  <option value="106">Telework Center</option>
                                  <option value="107">Other</option>
                                </select>
                              </span>
                              <span className="data w48 pull-right">
                                <select name="T2L2To" className="select1 to T2L2To" onChange={(e) => {
                                  checkSecondLeg(e, '2')
                                }}>
                                  <option value="0">To</option>
                                  <option value="101">Home</option>
                                  <option value="102">Work</option>
                                  <option value="103">Park &amp; Ride Lot</option>
                                  <option value="104">Bus Stop</option>
                                  <option value="106">Telework Center</option>
                                  <option value="107">Other</option>
                                </select>
                              </span>
                              <span className="data w48">
                                <select name="T2L2Mode" className="select1 mode T2L2Mode" onChange={(e) => {
                                  checkCommuteMode(e, 'T2L2Distance')
                                }}>
                                  <option value="0">Travel Mode</option>
                                  <option value="78">Drive Alone</option>
                                  <option value="79">Transit</option>
                                  <option value="80">Carpool</option>
                                  <option value="81">Vanpool</option>
                                  <option value="82">Bike</option>
                                  <option value="83">Walk</option>
                                  <option value="84">Telework</option>
                                </select>
                              </span>
                              <span className="data w48 pull-right">
                                <input className="textsm distance T2L2Distance" type="text" size="1" maxLength="3"
                                       name="T2L2Distance" defaultValue="" />
                              </span>
                            </div>
                            <div className="pull-right w100">
                              <span>
                                <IonButton className="addLegButton bk_green" onClick={addLeg}
                                           data-mini="true">Add Leg
                                </IonButton>
                              </span>
                            </div>
                            <div className="w-100">
                              <span className="data">Alternate Departure Time:<span className="red">*</span></span>
                            <IonDatetimeS extraClass="ion-float-right" noyear={true} id="toHomeLegAlternateDepartureTimeString" placeholder="" isDateEnabled={() => false}
                                          name="toHomeLegAlternateDepartureTimeString"
                                          pickerFormat="hh:mm:ss A" preferWheel="false"
                                          onIonChange={(e) => {
                                            console.log(`new toHomeLegAlternateDepartureTimeString time: `, e.target.value)
                                            set_toHomeLegAlternateDepartureTimeString(e.target.value)
                                          }
                                          }
                                          minuteValues="0,15,30,45"
                                          value={toHomeLegAlternateDepartureTimeString}
                            > <span slot="time-label"></span>
                              </IonDatetimeS>
                            </div>
                            </div>
                          </div>
                        </span>
                    </div>

                    <div>
                      <span> &nbsp;</span>
                    </div>

                    <div>
                        <span className="w100">
                          <IonButton className="inline_block w48 button-medium"
                                     onClick={() => set_show_commute_log_flex(false)}>
                            Back
                          </IonButton>
                          <IonButton className="inline_block w48 pull-right button-medium"
                                     onClick={(e) => {
                                       console.log(`null date`, NULL_DATE)
                                       set_toHomeLegAlternateDepartureTimeString(NULL_DATE)
                                       set_toWorkLegAlternateDepartureTimeString(NULL_DATE)
                                       const $form = $(e.target).closest('form')
                                       if ($form.length != 1) return false
                                       clearLogs($form[0])
                                     }}>Clear Log
                          </IonButton>
                        </span>
                    </div>
                    <div>
                        <span className="w100">
                          <IonButton className="w100 text_center button-medium"
                                     onClick={async (e) => {
                                       const [save_log_res, save_log_msg] = await saveCommuteLogsWithAdditionalLegs(e, User_clog_type.current)
                                       if (save_log_res) {
                                         app_toast(app, 'Commute Log saved successfully')
                                         set_show_commute_log_flex(false)
                                         setTimeout(get_saved_days, 2000)//wait a few secs for API to register data
                                       } else {
                                         app_alert(app, save_log_msg)
                                       }
                                     }}
                          >Save Log >>
                          </IonButton>
                        </span>
                    </div>
                    <div>
                      <span> &nbsp;</span>
                    </div>
                  </div>
                </div>
              </form>
            </IonContent>
          </IonModal>
          {/*//for user type general*/}
          <IonModal isOpen={show_commute_log_entry_page} ref={commute_log_entry_page_modal} onDidDismiss={commute_log_entry_page_did_dismiss} trigger="show_commute_log_entry_page_btn"
                    onWillPresent={() => {
                      console.log(`Commute log general type will present`)
                    }}
                    onDidPresent={on_commute_log_entry_page_did_present}
                    className="full_width full_height">
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton slot="start" onClick={() => set_show_commute_log_entry_page(false)}>
                    {/*
                    <span><svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path fill="none" stroke="currentColor" strokeLinecap="round"
                                                                                                                                             strokeLinejoin="round" strokeWidth="48"
                                                                                                                                             d="M328 112L184 256l144 144" /></svg></span>
*/}
                    <IonIcon icon={chevronBack}>

                    </IonIcon>
                  </IonButton>
                </IonButtons>
                <IonTitle>Edit Commute Log</IonTitle>
                <IonButtons slot="primary" className="hidden">
                  <IonMenuButton auto-hide="false" className="darkblue_invi" menu="none"></IonMenuButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent id="commute_log_entry_page">
              <form name="TripDiaryList" className="tripdiarycontrol.action padding_sm" data-user_types="0,1">
                <input type="hidden" name="tripDate" className="cur_date_val" />
                <div className="ui-table-reflow">
                  <div>
                    <div className="w100 text_center">
                      <span className="cur_date_mdy text_center" style={{fontSize: "1.6em"}}>{cur_date_mdy}</span>
                      <span className="cur_date hidden"></span>
                    </div>

                    <div className="cip_message">
                      <span align="left">If you are a Pool Rewards applicant, please note your pool must be approved by
                        Commuter Connections staff before you can start logging trips to earn incentive payments.
                        <br /><br />
                        Once your carpool partners have joined your carpool and your application is approved, you
                        will receive an email with a start date and additional instructions for logging your
                        commute.
                        <br /><br />
                        You will not be eligible to earn rewards before the designated start date.
                      </span>
                    </div>
                    <div>
                      <span> &nbsp;</span>
                    </div>

                    <div>
                      <span className="data trip_table_wrapper">
                        <div className="TripHeader">Trip 1: Home To Work</div>
                        <div className="ui-table-reflow tall_table" id="LegCommuteTable1" width="100%">
                          <div id="home_to_work_wrapper" className="trip_table" data-trip-index="1">
                          <div className="pull-right">
                            <span>
                              <IonButton size="small" name="addLeg" type="button" className="addLegButton bk_green" onClick={addLeg}>Add Leg</IonButton>
                            </span>
                          </div>
                          </div>
                        </div>
                      </span>
                    </div>

                    <div>
                      <span> &nbsp;</span>
                    </div>

                    <div>
                      <span className="data trip_table_wrapper">
                        <div className="TripHeader">Trip 2: Work To Home</div>
                        <div id="LegCommuteTable2" className="tall_table w-100">
                          <div id="work_to_home_wrapper" className="trip_table" data-trip-index="2">
                          <div className="pull-right">
                            <span colSpan="2">
                              <IonButton size="small" name="addLeg" type="button" className="addLegButton bk_green" onClick={addLeg}>Add Leg</IonButton>
                            </span>
                          </div>
                          </div>
                        </div>
                      </span>
                    </div>

                    <div>
                      <span> &nbsp;</span>
                    </div>

                    <div>
                      <span className="w100">
                        <IonButton name="clearLogs" type="button" className="inline_block pull-right button-medium"
                                   onClick={clearLogs}>Clear Log
                        </IonButton>
                      </span>
                    </div>
                    <div>
                      <span>
                        <IonButton name="saveLog" className="w100 text_center button-medium"
                                   onClick={async (e) => {
                                     const [save_log_res, save_log_msg] = await saveCommuteLogsWithAdditionalLegs(e, User_clog_type.current)
                                     if (save_log_res) {
                                       app_toast(app, 'Commute Log saved successfully')
                                       set_show_commute_log_entry_page(false)
                                       await get_saved_days()
                                     } else {
                                       app_alert(app, save_log_msg)
                                     }
                                   }}>Save Log >>
                        </IonButton>
                        <IonButton expand="block" onClick={() => {
                          console.log(`back clicked`)
                          commute_log_entry_page_modal.current?.dismiss()
                          set_show_commute_log_entry_page(false)
                        }}>
                          Back
                        </IonButton>
                        </span>
                    </div>
                  </div>
                </div>
              </form>
            </IonContent>
          </IonModal>
        </IonContent>
      </IonContent>
    </IonPage>
  )
}


// export default withRouter(Clog)
