"use strict";
/**
 * TripDiaryValidation.js
 * Also handles saving CIP log: save_commute_logs_ajax
 */

import {build_basic_params, CM, flat_array_to_assoc, MWCOG_ROOT} from "./core";
import {app_alert, app_toast, date_time_to_rm_time, fix_ionic_form, swl} from "../Helper"

export function setLogDate(formObj){
  if (formObj.tripDate.value == null || formObj.tripDate.value == "") {
    alert("Select the commute trip date and click on 'Continue' button.");
    return false;
  }
  var dateVal = document.getElementById("tripDate");
  var dateExp = /^\d{1,2}(\-|\/|\.)\d{1,2}\1\d{4}$/;
  var myDate = new Date(dateVal.value);
  var today = new Date();

  if (! dateVal.value.match(dateExp)) {
    alert("Please enter the date in MM/DD/YYYY format or select from calendar widget.");
    dateVal.focus();
    return false;
  } else if (myDate > today) {
    alert("Trip Date cannot be later than today.");
    dateVal.focus();
    return false;
  }

  formObj.action.value = "setlogdate";
  formObj.submit();
  return true;
}

export function checkSecondLeg(point, str){
  console.log(`CheckSecondLeg called`)
  if (! point) return
  let formObj = null
  if (point.hasOwnProperty('form')) formObj = point.form
  else formObj = $(point).closest('form')
  let $form = $(formObj);
  let t1l1_from = $($form.find(':input[name="T1L1From"]'));
  let t1l1_to = $($form.find(':input[name="T1L1To"]'));
  let t1l2_from = $($form.find(':input[name="T1L2From"]'));
  let t1l2_to = $($form.find(':input[name="T1L2To"]'));
  let t1l2_mode = $($form.find(':input[name="T1L2Mode"]'));
  let t1l2_distance = $($form.find(':input[name="T1L2Distance"]'));

  let t2l1_from = $($form.find(':input[name="T2L1From"]'));
  let t2l1_to = $($form.find(':input[name="T2L1To"]'));
  let t2l2_from = $($form.find(':input[name="T2L2From"]'));
  let t2l2_to = $($form.find(':input[name="T2L2To"]'));
  let t2l2_mode = $($form.find(':input[name="T2L2Mode"]'));
  let t2l2_distance = $($form.find(':input[name="T2L2Distance"]'));

  if (t1l1_from.val() === CM.HOME && t1l1_to.val() === CM.WORK) {
    t1l2_from.val('0')
    t1l2_to.val('0')
    t1l2_mode.val('0')
    t1l2_distance.val('');
  }
  if (t2l1_from.val() === CM.WORK && t2l1_to.val() === CM.HOME) {
    t2l2_from.val('0')
    t2l2_to.val('0')
    t2l2_mode.val('0')
    t2l2_distance.val('');
  }

  if (t1l1_from.val() === CM.HOME && t1l1_to.val() === CM.TELEWORK) {
    t1l2_from.val('0')
    t1l2_to.val('0')
    t1l2_mode.val('0')
    t1l2_distance.val('');

    t2l1_from.val(CM.TELEWORK)
    t2l1_to.val(CM.HOME)
    t2l2_from.val("0")
    t2l2_to.val("0")
  }
  return true;
}

window.checkSecondLeg = checkSecondLeg

export function checkCommuteMode(cmode, str){
  console.log(`checkCommuteMode`)
  if (cmode.options[cmode.selectedIndex].value == '109' ||
    cmode.options[cmode.selectedIndex].value == '110') {
    $('[name="T1L1To"]').val(102)
    $('[name="T1L2From"]').val(0)
    $('[name="T1L2To"]').val(0)
    $('[name="T2L1From"]').val(102)
    $('[name="T2L1To"]').val(101)
    $('[name="T2L2From"]').val(0)
    $('[name="T2L2To"]').val(0)
    $('[name="T1L2Mode"]').val(0)
    $('[name="T2L2Mode"]').val(0)
    $('[name="T1L2Distance"]').val();
    $('[name="T2L2Distance"]').val();
    $('[name="T2L1Mode"]').val(cmode.options[cmode.selectedIndex].value);
    $('[name="T1L1Distance"]').val($('[name="tripDist"]').value); //"0)
    $('[name="T2L1Distance"]').val($('[name="tripDist"]').value); //"0)
    $('[name="T2L1Mode"]').prop('disabled', true); //**
    $('[name="T1L1Distance"]').prop('disabled', true);
    $('[name="T1L2Distance"]').prop('disabled', true);
    $('[name="T2L1Distance"]').prop('disabled', true);
    $('[name="T2L2Distance"]').prop('disabled', true);
  } else if (cmode.options[cmode.selectedIndex].value == '111') {
    $('[name="T1L1From"]').val(101)
    $('[name="T1L1To"]').val(102)
    $('[name="T1L2From"]').val(0)
    $('[name="T1L2To"]').val(0)
    $('[name="T2L1From"]').val(102)
    $('[name="T2L1To"]').val(101)
    $('[name="T2L2From"]').val(0)
    $('[name="T2L2To"]').val(0)
    $('[name="T1L2Mode"]').val(0)
    $('[name="T2L1Mode"]').val(111)
    $('[name="T2L2Mode"]').val(0)
    $('[name="T1L1Distance"]').val(0);
    $('[name="T1L2Distance"]').val();
    $('[name="T2L1Distance"]').val(0);
    $('[name="T2L2Distance"]').val();
    $('[name="T2L1Mode"]').prop('disabled', true); //**
    $('[name="T1L1Distance"]').prop('disabled', true);
    $('[name="T1L2Distance"]').prop('disabled', true);
    $('[name="T2L1Distance"]').prop('disabled', true);
    $('[name="T2L2Distance"]').prop('disabled', true);
  } else {
    //$('[name=str).prop('disabled', false);
    $('[name="T2L1Mode"]').prop('disabled', false);
    $('[name="T1L1Distance"]').prop('disabled', false);
    $('[name="T1L2Distance"]').prop('disabled', false);
    $('[name="T2L1Distance"]').prop('disabled', false);
    $('[name="T2L2Distance"]').prop('disabled', false);
  }
  return true;
}

window.checkCommuteMode = checkCommuteMode

/**
 * Save commute log with additional legs.
 * To be called by React app.
 * Does some validations before calling other method
 * @param e Event
 * @param User
 * @return {Promise<string[]>}
 */
export async function saveCommuteLogsWithAdditionalLegs(e, User){
  console.log(`saveCommuteLogsWithAdditionalLegs called`)
  let res = false, msg = 'Start saving commute logs with additional legs'
  if (! e.target) {
    msg = `Error: invalid event`
    return [false, msg]
  }
  const $form = $($(e.target).closest('form'))
  if ($form.length != 1) {
    msg = `Error: cannot find form`
    return [false, msg]
  }
  let formObj = $form[0]
  if (document.getElementById("noCommute") !== null && document.getElementById("noCommute").checked === true) {
    if (formObj.noCommuteReason.value == '0') {
      msg = "no commute reason must be selected"
      return [false, msg]
    } else {
      return [save_commute_logs_ajax(formObj, User), ''] //todob rewrite this
    }
  }

  if (formObj.hasOwnProperty('toWorkLegAlternateDepartureTime_hour')) {
    if (formObj.toWorkLegAlternateDepartureTime_hour.value === "" || parseInt(formObj.toWorkLegAlternateDepartureTime_hour.value) > 12) {
      msg = 'Please enter 0-12 for Home to Work Alternate Departure hour'
      // app_toast('Please enter 0-12 for Home to Work Alternate Departure hour'
      return [false, msg]
    }
  }
  if (formObj.hasOwnProperty('toHomeLegAlternateDepartureTime_hour') && parseInt(formObj.toHomeLegAlternateDepartureTime_hour.value) > 12) {
    if (formObj.toHomeLegAlternateDepartureTime_hour.value === "" || parseInt(formObj.toHomeLegAlternateDepartureTime_hour.value) > 12) {
      msg = 'Please enter 0-12 for Work to Home Alternate Departure hour'
      // app_toast('Please enter 0-12 for Work to Home Alternate Departure hour'
    }
    return [false, msg]
  }
  if (formObj.hasOwnProperty('toWorkLegAlternateDepartureTime_minute') && formObj.toWorkLegAlternateDepartureTime_minute.value === '') {
    msg = 'Please enter Home to Work alternate departure time minute'
    // app_toast('Please enter Home to Work alternate departure time minute'
    return [false, msg]
  }
  if (formObj.hasOwnProperty('toHomeLegAlternateDepartureTime_minute') && formObj.toHomeLegAlternateDepartureTime_minute.value === '') {
    msg = 'Please enter Work to Home alternate departure time minute'
    // app_toast('Please enter Work to Home alternate departure time minute'
    return [false, msg]
  }
  if (document.getElementById("T1L3From") === null && document.getElementById("T1L3To") === null && document.getElementById("T2L3From") === null && document.getElementById("T2L3To") === null) {
    if (User?.type == 4) //type flex
      return [save_commute_logs_ajax(formObj, User), '']
    return saveCommuteLogs(formObj, User)
  } else {
    //msg = "multi leg verification");
    //check for start location
    if (formObj.T1L1From.value == '0') {
      msg = "Select Start for Leg 1 of Trip 1."
      formObj.T1L1From.focus();
      return [false, msg]
    }
    if (formObj.T1L1To.value == '0') {
      msg = "Select To for Leg 1 of Trip 1."
      formObj.T1L1To.focus();
      return [false, msg]
    }

    if (typeof formObj.T1L2From !== 'undefined' && formObj.T1L2From.value == CM.HOME) {
      msg = "Start of leg 2 of Trip 1 cannot be from 'Home'."
      formObj.T1L2From.value = formObj.T1L1To.value;
      return [false, msg]
    }

    if (formObj.T1L1Mode.value == '0') {
      msg = "Select Commute Mode for Leg 1 of Trip 1."
      formObj.T1L1Mode.focus();
      return [false, msg]
    }
    if (formObj.T1L1Distance.value == '') {
      msg = "Enter Commute Distance for Leg 1 of Trip 1."
      formObj.T1L1Distance.focus();
      return [false, msg]
    }
    if (formObj.T2L1From.value == '0') {
      msg = "Select Start for Leg 1 of Trip 2."
      formObj.T2L1From.focus();
      return [false, msg]
    }
    if (formObj.T2L1To.value == '0') {
      msg = "Select To for Leg 1 of Trip 2."
      formObj.T2L1To.focus();
      return [false, msg]
    }
    if (formObj.T2L1Mode.value == '0') {
      msg = "Select Commute Mode for Leg 1 of Trip 2."
      formObj.T2L1Mode.focus();
      return [false, msg]
    }
    if (formObj.T2L1Distance.value == '') {
      msg = "Enter Commute Distance for Leg 1 of Trip 2."
      formObj.T2L1Distance.focus();
      return [false, msg]
    }

    //leg 2 cannot be null if leg 3 is added
    if (document.getElementById("T1L3From") != null || document.getElementById("T1L3To") != null || document.getElementById("T1L3Mode") != null || document.getElementById("T1L3Distance") != null) {
      if (formObj.T1L2From.value == '0') {
        msg = "Select Start for Leg 2 of Trip 1."
        formObj.T1L2From.focus();
        return [false, msg]
      }
      if (formObj.T2L1To.value == '0') {
        msg = "Select To for Leg 2 of Trip 1."
        formObj.T1L2To.focus();
        return [false, msg]
      }

      if (formObj.T1L2Mode.value == '0') {
        msg = "Select Commute Mode for Leg 2 of Trip 1."
        formObj.T1L2Mode.focus();
        return [false, msg]
      }
      if (formObj.T1L2Distance.value == '') {
        msg = "Enter Commute Distance for Leg 2 of Trip 1."
        formObj.T1L2Distance.focus();
        return [false, msg]
      }
    }

    //leg 2 cannot be null if leg 3 is added
    if (document.getElementById("T2L3From") != null || document.getElementById("T2L3To") != null || document.getElementById("T2L3Mode") != null || document.getElementById("T2L3Distance") != null) {

      //leg 2 can be null if leg3 is empty
      if (document.getElementById("T2L3From").value == '0' && document.getElementById("T2L3To").value == '0' && document.getElementById("T2L3Mode").value == '0' && document.getElementById("T2L3Distance").value == '') {

      } else {
        if (formObj.T2L2From.value == '0') {
          msg = "Select Start for Leg 2 of Trip 2."
          formObj.T2L1From.focus();
          return [false, msg]
        }
        if (formObj.T2L2To.value == '0') {
          msg = "Select To for Leg 2 of Trip 2."
          formObj.T2L2To.focus();
          return [false, msg]
        }
        if (formObj.T2L2Mode.value == '0') {
          msg = "Select Commute Mode for Leg 2 of Trip 2."
          formObj.T2L2Mode.focus();
          return [false, msg]
        }
        if (formObj.T2L1Distance.value == '') {
          msg = "Enter Commute Distance for Leg 2 of Trip 2."
          formObj.T2L1Distance.focus();
          return [false, msg]
        }
      }
    }


    for (let i = 1; i < 3; i++) {
      //number of legs for each table
      var tableRowCount = document.getElementById("LegCommuteTable" + i).rows.length - 2;


      //iterate through each of legs for table i
      for (let j = 1; j <= tableRowCount; j++) {

        //if from to are the same, and not unselected
        if (document.getElementById("T" + i + "L" + j + "From").value == document.getElementById("T" + i + "L" + j + "To").value) {
          if (document.getElementById("T" + i + "L" + j + "From").value != '0') {
            msg = "Start and destination for Leg" + j + "of Trip " + i + " cannot be the same."
            return [false, msg]
          }
        }

        if (j > 1) {
          if (document.getElementById("T" + i + "L" + j + "From").value != '0') {
            if (document.getElementById("T" + i + "L" + j + "From").value != document.getElementById("T" + i + "L" + (j - 1) + "To").value) {
              msg = "Start for Leg " + j + " must match Destination for leg " + (j - 1) + " on Trip " + i
              return [false, msg]
            }
          }
        }


        if (j > 2) {
          if (document.getElementById("T" + i + "L" + j + "From").value == '0' && document.getElementById("T" + i + "L" + j + "To").value == '0' && document.getElementById("T" + i + "L" + j + "Mode").value == '0' && document.getElementById("T" + i + "L" + j + "Distance").value == '') {

          } else {

            if (document.getElementById("T" + i + "L" + j + "From").value == '0') {
              msg = "Select Start for Leg " + j + " of Trip " + i + "."
              return [false, msg]
            }
            if (document.getElementById("T" + i + "L" + j + "To").value == '0') {
              msg = "Select To for Leg " + j + " of Trip " + i + "."
              return [false, msg]
            }
            if (document.getElementById("T" + i + "L" + j + "Mode").value == '0') {
              msg = "Select Mode for Leg " + j + " of Trip " + i + "."
              return [false, msg]
            }
            if (document.getElementById("T" + i + "L" + j + "Distance").value == '') {
              msg = "Enter Commute Distance for Leg " + j + " of Trip " + i + "."
              return [false, msg]
            }
          }
        }

        //final row check for table1
        if (j == tableRowCount && i == 1) {

        }
      }

    }

    //round all distance numbers
    return [save_commute_logs_ajax(formObj, User), '']
  }
  return [res, msg]
}//end Save Commute Logs with Additional Legs

/**
 * Send Ajax request to submit to calendar API service
 * @param formObj
 * @param User
 * @param is_update //b3t forgot why we needed this
 * @return boolean
 */
export async function save_commute_logs_ajax(formObj, User, is_update = false){
  let form_array = $(formObj).serializeArray(), success = false, res = false;
  if (typeof is_update === "undefined") {
    is_update = false;
  }
  for (var i = 0; i < form_array.length; i++) {
    var v = form_array[i];
    if (typeof v !== "undefined") {
      if (v.name === 'idPool' || v.name === 'logType') {
        form_array.splice(i, 1);
        i--;
        continue;
      }
      v.name = v.name.replace('T1L', 'toWorkleg');
      v.name = v.name.replace('T2L', 'toHomeleg');
    }
  }
  var url = MWCOG_ROOT;
  var extra_params = {};
  extra_params.action = 'saveCommuteLog';
  extra_params.noCommute = false;
  switch (User.type) {
    case 0:
      extra_params.action += 'General';
      $.each(form_array, function (i, v){
        if (v.name === 'tripDate') {
          v.value = moment(v.value, 'M/DD/YYYY').format('MM/DD/YYYY');
        }
      });
      break;
    case 1:
      extra_params.action += 'CIP';
      break;
    case 2:
      extra_params.action += 'VIP';
      break;
    case 4: // FLEX
      extra_params.action += 'Flex';
      if (User.trips[0].legs.length === 1) {
        extra_params = $.extend(extra_params, {
          toHomeleg2From: 0,
          toHomeleg2To: 0,
          toHomeleg2Mode: 0,
          toHomeleg2Distance: 0,
          toWorkleg2From: 0,
          toWorkleg2To: 0,
          toWorkleg2Mode: 0,
          toWorkleg2Distance: 0
        });
      }

      const toWorkLegAlternateDepartureTimeString = $(formObj).find('input[name="toWorkLegAlternateDepartureTimeString"]').val()
      const to_work_leg_alt_dep = date_time_to_rm_time(toWorkLegAlternateDepartureTimeString, 'HHmm')
      extra_params.toWorkLegAlternateDepartureTime = to_work_leg_alt_dep

      const toHomeLegAlternateDepartureTimeString = $(formObj).find('input[name="toHomeLegAlternateDepartureTimeString"]').val()
      const to_home_leg_alt_dep = date_time_to_rm_time(toHomeLegAlternateDepartureTimeString, 'HHmm')
      extra_params.toHomeLegAlternateDepartureTime = to_home_leg_alt_dep

      form_array = form_array.filter(function (e){
        return (! e.name.includes('LegAlternateDepartureTime'));
      });
      break;
  }
  let params = build_basic_params();
  $.extend(params, extra_params, flat_array_to_assoc(form_array));
  //to match with API requirements, even if we don't have some legs, we need to set them as 0
  if (User.type === 0) {
    for (let property_name of ['toHomeleg2From', 'toHomeleg2To', 'toHomeleg2Mode', 'toHomeleg2Distance', 'toWorkleg2From', 'toWorkleg2To', 'toWorkleg2Mode', 'toWorkleg2Distance']) {
      if (! params.hasOwnProperty(property_name)) {
        params[property_name] = 0;
      }
    }
  }
  //end to match with API requirements, even if we don't have some legs, we need to set them as 0
  $.get(url, params, function (result){
    if (! result) {
      alert('Error saving commute log. Please contact our team')
      return false
    }
    console.info(result);
    if (result.status === 200 ||
      (typeof result.status === 'string' && result.status.indexOf('success') !== -1)) {
      return true
    } else {
      alert(result.status || result.statusDescription);
      // app_toast(result.status);
    }
  }, 'json').fail(function (error){
    console.info(error);
    window.error = error.responseText;
    if (error.status === 200 || result.status.indexOf('success') !== -1) {
      alert('Your commute log has been saved. Click OK to return to the Commute Log Calendar.');
      // app_toast('Your commute log has been saved. Click OK to return to the Commute Log Calendar.');
      $('div.ui-dialog-contain a.ui-icon-delete').trigger('click');
      return true
    }
  });

  //fix weird validation
  $(':input[name="T1L2From"]').val(0);
  $(':input[name="T2L2From"]').val(0);
  return res;
}


/**
 * Send Ajax request to submit to calendar API service
 * @param formObj
 * @param app React global app
 * @return [boolean, object]
 */
export async function save_van_log_ajax(formObj, app){
  fix_ionic_form(formObj)
  const form_array = $(formObj).serializeArray(), success = false, res = false;
  const form_array_obj = flat_array_to_assoc(form_array)
  delete form_array_obj.createdBy
  delete form_array_obj.createdT
  delete form_array_obj.isAdmin
  delete form_array_obj.hashedpassword
  form_array_obj.idPool = app.user.pool_id

  //noCommute = !commute
  if (form_array_obj.noCommute === '') {
    form_array_obj.noCommute = 'false'
    form_array_obj.noCommuteReason = 0
  }
  if (form_array_obj.noCommute === 'on') {
  } else {
  }
  if (form_array_obj.passenger1wbYesCommute == 'true') form_array_obj.passenger1wbNoCommute = false
  if (form_array_obj.passenger1wbYesCommute == 'false') form_array_obj.passenger1wbNoCommute = true
  if (form_array_obj.passenger1hbYesCommute == 'true') form_array_obj.passenger1hbNoCommute = false
  if (form_array_obj.passenger1hbYesCommute == 'false') form_array_obj.passenger1hbNoCommute = true

  if (form_array_obj.passenger2wbYesCommute == 'true') form_array_obj.passenger2wbNoCommute = false
  if (form_array_obj.passenger2wbYesCommute == 'false') form_array_obj.passenger2wbNoCommute = true
  if (form_array_obj.passenger2hbYesCommute == 'true') form_array_obj.passenger2hbNoCommute = false
  if (form_array_obj.passenger2hbYesCommute == 'false') form_array_obj.passenger2hbNoCommute = true

  if (form_array_obj.passenger3wbYesCommute == 'true') form_array_obj.passenger3wbNoCommute = false
  if (form_array_obj.passenger3wbYesCommute == 'false') form_array_obj.passenger3wbNoCommute = true
  if (form_array_obj.passenger3hbYesCommute == 'true') form_array_obj.passenger3hbNoCommute = false
  if (form_array_obj.passenger3hbYesCommute == 'false') form_array_obj.passenger3hbNoCommute = true

  delete form_array_obj.passenger1wbYesCommute
  delete form_array_obj.passenger1hbYesCommute
  delete form_array_obj.passenger2wbYesCommute
  delete form_array_obj.passenger2hbYesCommute
  delete form_array_obj.passenger3wbYesCommute
  delete form_array_obj.passenger3hbYesCommute

  let url = MWCOG_ROOT;
  let extra_params = {};
  let params = build_basic_params();
  $.extend(params, extra_params, form_array_obj)
  try {
    url += ('?' + (new URLSearchParams(params)).toString())
    const f_config = {
      method: 'GET',
    }
    const ajax_res = await fetch(url)
    if (ajax_res.status === 200 || (typeof ajax_res.status === 'string' && ajax_res.status.indexOf('success') !== -1)) {
      const ajax_res_body = await ajax_res.json()
      console.info(`ajax res body`, ajax_res_body)
      if (ajax_res_body.statusDescription && ajax_res_body.statusDescription.includes('rror')) {
        swl('Error', ajax_res_body.statusDescription)
        return [false, ajax_res_body.statusDescription]
      }
      swl('Success', 'Your commute log has been saved. Click Back to return to the Commute Log Calendar.');
      return [true, {}]
    } else {
      swl('Success', ajax_res.status || ajax_res.statusDescription);
      return [false, ajax_res.status]
    }
  } catch (error) {
    console.error(error);
    window.error = error.responseText;
    if (error.status === 200) { // || error.status.indexOf('success') !== -1) {
      swl('Success', 'Your commute log has been saved. Click Back to return to the Commute Log Calendar.');
      return [true, {}]
    }
    return [false, error]
  }
}

/**
 * Save Commute Logs. Called by saveCommuteLogsAjax
 * @param formObj
 * @param User
 * @returns {String[]}
 */
export function saveCommuteLogs(formObj, User){
  let res = false, msg = 'Saving commute logs.. '
  /*if (!e.target) return
  let $form = $(e.target).closest('form')
  if ($form.length != 1) {
      console.error(`Error: save commute log cannot find form.`)
      return false
  }
  let formObj = $form[0]*/
  if (formObj.T1L1From.value == '0') {
    msg = "Select Start for Leg 1 of Trip 1."
    formObj.T1L1From.focus();
    return [false, msg]
  }
  if (formObj.T1L1To.value == '0') {
    msg = "Select To for Leg 1 of Trip 1."
    formObj.T1L1To.focus();
    return [false, msg]
  }
  if (formObj.T1L1Mode.value == '0') {
    msg = "Select Commute Mode for Leg 1 of Trip 1."
    formObj.T1L1Mode.focus();
    return [false, msg]
  }
  if (formObj.T1L1Distance.value == '') {
    msg = "Enter Commute Distance for Leg 1 of Trip 1."
    formObj.T1L1Distance.focus();
    return [false, msg]
  } else
    formObj.T1L1Distance.value = Math.round(formObj.T1L1Distance.value);

  if (formObj.T2L1From.value == '0') {
    msg = "Select Start for Leg 1 of Trip 2."
    formObj.T2L1From.focus();
    return [false, msg]
  }
  if (formObj.T2L1To.value == '0') {
    msg = "Select To for Leg 1 of Trip 2."
    formObj.T2L1To.focus();
    return [false, msg]
  }
  if (formObj.T2L1Mode.value == '0') {
    msg = "Select Commute Mode for Leg 1 of Trip 2."
    formObj.T2L1Mode.focus();
    return [false, msg]
  }
  if (formObj.T2L1Distance.value == '') {
    msg = "Enter Commute Distance for Leg 1 of Trip 2."
    formObj.T2L1Distance.focus();
    return [false, msg]
  } else
    formObj.T2L1Distance.value = Math.round(formObj.T2L1Distance.value);

  if ((formObj.T1L1From.value == CM.HOME || formObj.T1L1From.value == CM.WORK) &&
    (formObj.T1L1From.value == formObj.T1L1To.value)) {
    msg = "Start and destination for Leg 1 of Trip 1 cannot be same."
    formObj.T1L1From.focus();
    return [false, msg]
  }
  if ((formObj.T2L1From.value == CM.HOME || formObj.T2L1From.value == CM.WORK) &&
    (formObj.T2L1From.value == formObj.T2L1To.value)) {
    msg = "Start and destination for Leg 1 of Trip 2 cannot be same."
    formObj.T2L1From.focus();
    return [false, msg]
  }

  if (typeof formObj.T1L2From !== 'undefined') {
    if ((formObj.T1L2From.value == CM.HOME || formObj.T1L2From.value == CM.WORK) &&
      (formObj.T1L2From.value == formObj.T1L2To.value)) {
      msg = "Start and destination for Leg 2 of Trip 1 cannot be same."
      formObj.T1L2From.focus();
      return [false, msg]
    }
    // added to verify leg2 validity
    if ((formObj.T1L2From.value == '0' || formObj.T1L2To.value == '0') &&
      (formObj.T1L2Mode.value != 0 || formObj.T1L2Distance.value != '' || formObj.T1L2Distance.value > 0)) {
      msg = "Leg 2 of Trip 1 is not valid."
      formObj.T1L2Mode.value = '0';
      formObj.T1L2Distance.value = '';
      formObj.T1L2From.focus();
      return [false, msg]
    }
  }
  if (typeof formObj.T2L2From !== 'undefined') {
    if ((formObj.T2L2From.value == CM.HOME || formObj.T2L2From.value == CM.WORK) &&
      (formObj.T2L2From.value == formObj.T2L2To.value)) {
      msg = "Start and destination for Leg 2 of Trip 2 cannot be same."
      formObj.T2L2From.focus();
      return [false, msg]
    }

    if ((formObj.T2L2From.value == '0' || formObj.T2L2To.value == '0') &&
      (formObj.T2L2Mode.value != 0 || formObj.T2L2Distance.value != '' || formObj.T2L2Distance.value > 0)) {
      msg = "Leg 2 of Trip 2 is not valid."
      formObj.T2L2Mode.value = '0';
      formObj.T2L2Distance.value = '';
      formObj.T2L2From.focus();
      return [false, msg]
    }
  }
  if (formObj.T1L1From.value == CM.HOME && formObj.T1L1To.value != CM.WORK && formObj.T1L1To.value != CM.TELEWORK && typeof formObj.T1L2From !== 'undefined') {
    if (formObj.T1L2From.value == CM.HOME) {
      msg = "Start of leg 2 of Trip 1 cannot be from 'Home'."
      formObj.T1L2From.value = formObj.T1L1To.value;
      return [false, msg]
    }
    if (formObj.T1L2From.value == '0' || formObj.T1L2From.value != formObj.T1L1To.value) {
      msg = "Trip 1 is not complete. Final destination must be either 'Work' or 'Telework Center'. \nStart of Leg 2 must be same as Destination of leg 1"
      formObj.T1L2From.value = formObj.T1L1To.value;
      return [false, msg]
    }
    if (formObj.T1L2To.value != CM.WORK && formObj.T1L2To.value != CM.TELEWORK) {
      msg = "Trip 1 is not complete. Final destination must be either 'Work' or 'Telework Center'."
      formObj.T1L2To.value = CM.WORK;
      return [false, msg]
    }
    if (formObj.T1L2Mode.value == '0') {
      msg = "Select travel mode for Leg 2 of Trip 1."
      formObj.T1L2Mode.focus();
      return [false, msg]
    }
    if (formObj.T1L2Distance.value == '' || formObj.T1L2Distance.value == '0') {
      msg = "Travel distance of leg 2 of Trip 1 must be greater than zero."
      formObj.T1L2Distance.focus();
      return [false, msg]
    }
  }

  if ((formObj.T2L1From.value == CM.WORK || formObj.T2L1From.value == CM.TELEWORK) && formObj.T2L1To.value != CM.HOME && typeof formObj.T2L2From !== 'undefined') {
    if (formObj.T2L2From.value == CM.WORK) {
      msg = "Start of leg 2 of Trip 2 cannot be from 'Work'."
      formObj.T2L2From.value = formObj.T2L1To.value;
      return [false, msg]
    }
    if (formObj.T2L2From.value == '0' || formObj.T2L2From.value != formObj.T2L1To.value) {
      msg = "Trip 2 is not complete. Final destination must be 'Home'. \nStart of Leg 2 must be same as Destination of leg 1."
      formObj.T2L2From.value = formObj.T2L1To.value;
      return [false, msg]
    }
    if (formObj.T2L2To.value != CM.HOME) {
      msg = "Trip 2 is not complete. Final destination must be 'Home'."
      formObj.T2L2To.value = CM.HOME;
      return [false, msg]
    }
    if (formObj.T2L2Mode.value == '0') {
      msg = "Select travel mode for Leg 2 of Trip 2."
      formObj.T2L2Mode.focus();
      return [false, msg]
    }
    if (formObj.T2L2Distance.value == '' || formObj.T2L2Distance.value == '0') {
      msg = "Travel distance of leg 2 of Trip 2 must be greater than zero."
      formObj.T2L2Distance.focus();
      return [false, msg]
    }
  }
  // end of verify leg2 validity
  $.each($(':input.distance'), function (i, v){
    $(v).val(Math.round(v.value));
  })

  // formObj.submit();
  return [save_commute_logs_ajax(formObj, User), '']
}//end save Commute Logs

export function updateCommuteLogs(formObj){
  if (formObj.T1L1From.value == '0') {
    alert("Select Start for Leg 1 of Trip 1.");
    formObj.T1L1From.focus();
    return false;
  }
  if (formObj.T1L1To.value == '0') {
    alert("Select To for Leg 1 of Trip 1.");
    formObj.T1L1To.focus();
    return false;
  }
  if (formObj.T1L1Mode.value == '0') {
    alert("Select Commute Mode for Leg 1 of Trip 1.");
    formObj.T1L1Mode.focus();
    return false;
  }
  if (formObj.T1L1Distance.value == '') {
    alert("Enter Commute Distance for Leg 1 of Trip 1.");
    formObj.T1L1Distance.focus();
    return false;
  } else
    formObj.T1L1Distance.value = Math.round(formObj.T1L1Distance.value);

  if (formObj.T2L1From.value == '0') {
    alert("Select Start for Leg 1 of Trip 2.");
    formObj.T2L1From.focus();
    return false;
  }
  if (formObj.T2L1To.value == '0') {
    alert("Select To for Leg 1 of Trip 2.");
    formObj.T2L1To.focus();
    return false;
  }
  if (formObj.T2L1Mode.value == '0') {
    alert("Select Commute Mode for Leg 1 of Trip 2.");
    formObj.T2L1Mode.focus();
    return false;
  }
  if (formObj.T2L1Distance.value == '') {
    alert("Enter Commute Distance for Leg 1 of Trip 2.");
    formObj.T2L1Distance.focus();
    return false;
  } else
    formObj.T2L1Distance.value = Math.round(formObj.T2L1Distance.value);

  if ((formObj.T1L1From.value == CM.HOME || formObj.T1L1From.value == CM.WORK) &&
    (formObj.T1L1From.value == formObj.T1L1To.value)) {
    alert("Start and destination for Leg 1 of Trip 1 cannot be same.");
    formObj.T1L1From.focus();
    return false;
  }
  if ((formObj.T2L1From.value == CM.HOME || formObj.T2L1From.value == CM.WORK) &&
    (formObj.T2L1From.value == formObj.T2L1To.value)) {
    alert("Start and destination for Leg 1 of Trip 2 cannot be same.");
    formObj.T2L1From.focus();
    return false;
  }

  if ((formObj.T1L2From.value == CM.HOME || formObj.T1L2From.value == CM.WORK) &&
    (formObj.T1L2From.value == formObj.T1L2To.value)) {
    alert("Start and destination for Leg 2 of Trip 1 cannot be same.");
    formObj.T1L2From.focus();
    return false;
  }
  if ((formObj.T2L2From.value == CM.HOME || formObj.T2L2From.value == CM.WORK) &&
    (formObj.T2L2From.value == formObj.T2L2To.value)) {
    alert("Start and destination for Leg 2 of Trip 2 cannot be same.");
    formObj.T2L2From.focus();
    return false;
  }

  // added to verify leg2 validity
  if ((formObj.T1L2From.value == '0' || formObj.T1L2To.value == '0') &&
    (formObj.T1L2Mode.value != 0 || formObj.T1L2Distance.value != '' || formObj.T1L2Distance.value > 0)) {
    alert("Leg 2 of Trip 1 is not valid.");
    formObj.T1L2Mode.value = '0';
    formObj.T1L2Distance.value = '';
    formObj.T1L2From.focus();
    return false;
  }

  if ((formObj.T2L2From.value == '0' || formObj.T2L2To.value == '0') &&
    (formObj.T2L2Mode.value != 0 || formObj.T2L2Distance.value != '' || formObj.T2L2Distance.value > 0)) {
    alert("Leg 2 of Trip 2 is not valid.");
    formObj.T2L2Mode.value = '0';
    formObj.T2L2Distance.value = '';
    formObj.T2L2From.focus();
    return false;
  }

  if (formObj.T1L1From.value == CM.HOME && formObj.T1L1To.value != CM.WORK && formObj.T1L1To.value != CM.TELEWORK) {
    if (formObj.T1L2From.value == CM.HOME) {
      alert("Start of leg 2 of Trip 1 cannot be from 'Home'.");
      formObj.T1L2From.value = formObj.T1L1To.value;
      return false;
    }
    if (formObj.T1L2From.value == '0' || formObj.T1L2From.value != formObj.T1L1To.value) {
      alert("Trip 1 is not complete. Final destination must be either 'Work' or 'Telework Center'. \nStart of Leg 2 must be same as Destination of leg 1.");
      formObj.T1L2From.value = formObj.T1L1To.value;
      return false;
    }
    if (formObj.T1L2To.value != CM.WORK && formObj.T1L2To.value != CM.TELEWORK) {
      alert("Trip 1 is not complete. Final destination must be either 'Work' or 'Telework Center'.");
      formObj.T1L2To.value = CM.WORK;
      return false;
    }
    if (formObj.T1L2Mode.value == '0') {
      alert("Select travel mode for Leg 2 of Trip 1.");
      formObj.T1L2Mode.focus();
      return false;
    }
    if (formObj.T1L2Distance.value == '' || formObj.T1L2Distance.value == '0') {
      alert("Travel distance of leg 2 of Trip 1 must be greater than zero.");
      formObj.T1L2Distance.focus();
      return false;
    }
  }

  if ((formObj.T2L1From.value == CM.WORK || formObj.T2L1From.value == CM.TELEWORK) && formObj.T2L1To.value != CM.HOME) {
    if (formObj.T2L2From.value == CM.WORK) {
      alert("Start of leg 2 of Trip 2 cannot be from 'Work'.");
      formObj.T2L2From.value = formObj.T2L1To.value;
      return false;
    }
    if (formObj.T2L2From.value == '0' || formObj.T2L2From.value != formObj.T2L1To.value) {
      alert("Trip 2 is not complete. Final destination must be 'Home'. \nStart of Leg 2 must be same as Destination of leg 1.");
      formObj.T2L2From.value = formObj.T2L1To.value;
      return false;
    }
    if (formObj.T2L2To.value != CM.HOME) {
      alert("Trip 2 is not complete. Final destination must be 'Home'.");
      formObj.T2L2To.value = CM.HOME;
      return false;
    }
    if (formObj.T2L2Mode.value == '0') {
      alert("Select travel mode for Leg 2 of Trip 2.");
      formObj.T2L2Mode.focus();
      return false;
    }
    if (formObj.T2L2Distance.value == '' || formObj.T2L2Distance.value == '0') {
      alert("Travel distance of leg 2 of Trip 2 must be greater than zero.");
      formObj.T2L2Distance.focus();
      return false;
    }
  }
  // end of verify leg2 validity

  if (formObj.T1L2Distance.value == '')
    formObj.T1L2Distance.value = "0";
  else
    formObj.T1L2Distance.value = Math.round(formObj.T1L2Distance.value);
  if (formObj.T2L2Distance.value == '')
    formObj.T2L2Distance.value = "0";
  else
    formObj.T2L2Distance.value = Math.round(formObj.T2L2Distance.value);

  return save_commute_logs_ajax(formObj, true);
}

export function clearLogs(formObj){
  if (typeof formObj.T1L1From === 'object') {
    formObj.T1L1From.value = "0";
  }
  if (typeof formObj.T1L1To === 'object') {
    formObj.T1L1To.value = "0";
  }
  if (typeof formObj.T1L1Mode === 'object') {
    formObj.T1L1Mode.value = "0";
  }
  if (typeof formObj.T1L1Distance === 'object') {
    formObj.T1L1Distance.value = "";
  }
  if (typeof formObj.T1L2From === 'object') {
    formObj.T1L2From.value = "0";
  }
  if (typeof formObj.T1L2To === 'object') {
    formObj.T1L2To.value = "0";
  }
  if (typeof formObj.T1L2Mode === 'object') {
    formObj.T1L2Mode.value = "0";
  }
  if (typeof formObj.T1L2Distance === 'object') {
    formObj.T1L2Distance.value = "";
  }
  if (typeof formObj.T2L1From === 'object') {
    formObj.T2L1From.value = "0";
  }
  if (typeof formObj.T2L1To === 'object') {
    formObj.T2L1To.value = "0";
  }
  if (typeof formObj.T2L1Mode === 'object') {
    formObj.T2L1Mode.value = "0";
  }
  if (typeof formObj.T2L1Distance === 'object') {
    formObj.T2L1Distance.value = "";
  }
  if (typeof formObj.T2L2From === 'object') {
    formObj.T2L2From.value = "0";
  }
  if (typeof formObj.T2L2To === 'object') {
    formObj.T2L2To.value = "0";
  }
  if (typeof formObj.T2L2Mode === 'object') {
    formObj.T2L2Mode.value = "0";
  }
  if (typeof formObj.T2L2Distance === 'object') {
    formObj.T2L2Distance.value = "";
  }

  //todob Rewrite to use form.reset
  var table1RowCount = document.getElementById("LegCommuteTable1")?.rows?.length - 2;

  var table2RowCount = document.getElementById("LegCommuteTable2")?.rows?.length - 2;


  for (let i = 3; i <= table1RowCount; i++) {
    document.getElementById("T1" + "L" + i + "From").value = "0";
    document.getElementById("T1" + "L" + i + "To").value = "0";
    document.getElementById("T1" + "L" + i + "Mode").value = "0";
    document.getElementById("T1" + "L" + i + "Distance").value = "";
  }

  for (let j = 3; j <= table2RowCount; j++) {
    document.getElementById("T2" + "L" + j + "From").value = "0";
    document.getElementById("T2" + "L" + j + "To").value = "0";
    document.getElementById("T2" + "L" + j + "Mode").value = "0";
    document.getElementById("T2" + "L" + j + "Distance").value = "";
  }

}

var httpRequest;

export function getTripDetails(url, event){
  var url = url;
  try {
    httpRequest = new XMLHttpRequest();
  } catch (e) {
    try {
      httpRequest = new ActiveXObject("Msxml2.XMLHTTP");
    } catch (e) {
      try {
        httpRequest = new ActiveXObject("Microsoft.XMLHTTP");
      } catch (e) {
        alert("Your browser does not support AJAX!");
        return false;
      }
    }
  }
  httpRequest.open("GET", url, true);
  httpRequest.onreadystatechange = function (){
    stateChanged();
  };
  httpRequest.send(null);

  function stateChanged(){
    if (httpRequest.readyState == 4) {
      if (event)
        document.getElementById("tooltip").innerHTML = url;
      else
        document.getElementById("tooltip").innerHTML = httpRequest.responseText;
    }
  }

  function GetXmlHttpObject(){
    if (window.XMLHttpRequest) {
      return new XMLHttpRequest();
    }
    if (window.ActiveXObject) {
      return new ActiveXObject("Microsoft.XMLHTTP");
    }
    return null;
  }
}
