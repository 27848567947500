/**
 * Ride match results
 * Important function: setRmList() - rm_list (line 767)
 *                      rm_init() - initialize data, grab data from localstorage, trigger
 *                      ask_md: The modal that asks for start and end
 *                      ask_md_perform_search(): called from ask_md
 *                      populate_ridematch_manual: Add gmap markers
 *                      populate_vh_rm_manual: Add gmap markers for vh matches
 */

import {
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  useIonAlert,
  useIonLoading
} from '@ionic/react';
import {hide_loading, logout, p, date_time_to_rm_time, geocode} from "../Helper"
import React, {Fragment, useEffect, useRef, useState} from "react"
import {useHistory} from "react-router"
import Menu from "../components/Menu"
import {withRouter} from "react-router-dom"
import {GoogleMap, LoadScript} from '@react-google-maps/api';
import ls from 'local-storage'
import $ from 'jquery'
import _, {isNumber} from 'lodash'
import User from "../User"
import {BASE_OPTION_DEFAULTS} from "@fullcalendar/react"
// import {IonDatetimeS} from "../components/ion-datetime-s"
import {IonDatetimeS} from "../components/ion-datetime-s-react"
import {IS_DEBUG} from "../env";
import APISocal from "../apis";
import axios from "axios";
import vh from "./Vh";
import {MWCOG_BASE_URL} from "../mwcog/core";

const GMAP_API_KEY = 'AIzaSyCyC1MXsxrpW-2xJOu1RgdmNqfP-8j9qFc'

//todofuture IMPORTANT: override use MWCOG here
const baseUrl = 'https://tdm.commuterconnections.org/mwcog/';
const ie511_url = 'https://tdm.commuterconnections.org/mwcog/integrate'

let map = undefined //will init to be google.maps.Map
const DEF_LAT = 38.85
const DEF_LNG = -77.17

let trafficLayer = {};
let toggleState = 0;
let directionsDisplay = {}, directionsService = {}, info = {}, directionsRenderer = {}
const api_vp = new APISocal()
api_vp.setup({url: `https://tdm.commuterconnections.org/vanhopprCore/vanpoolQueueApi/`})
const VANPOOL_APIKEY = 'testchangeme4'

/*zsdf*/
// window.ls = ls


// const Rm: React.FC = () => {
function Rm({app}) {
  const [present_alert] = useIonAlert()
  const [present_loading, dismiss_loading] = useIonLoading()
  let [stored_map_cent, set_stored_map_cent] = useState() //storing map center before showing modal
  const [gmap_key, set_gmap_key] = useState(-1)
  const gmap_markers_ref = useRef([]);

  const ask_rm_modal_ref = useRef(null);


  /**
   * Ask modal button `Search` clicked. We perform the search, then dismiss modal
   */
  const ask_md_perform_search = async () => {
    await rm_call_api()
    ask_rm_modal_ref.current?.dismiss()
  }
  /**
   * Ask modal did dismiss. After the modal is dismissed, we clean up and restore lost gmap center
   */
  const ask_md_did_dismiss = () => {
    /*    console.warn(`Modal done dismissing. Stored gmap center is: `, stored_map_cent, `. Restoring to that center...`)
        p(stored_map_cent)
        if (! (stored_map_cent instanceof google.maps.LatLng)) stored_map_cent = new google.maps.LatLng(DEF_LAT, DEF_LNG)
        window.map.setCenter(stored_map_cent)
        window.map.panTo(stored_map_cent)*/
  }
  //b3t usehook start
  const [user, setUser] = useState()
  /** @var User user */
  let infowindow

  // rm_init_user() //populate user from ls
  const [start_addr_idx, set_start_addr_idx] = useState(user?.first_home_address_idx() || 0) //the Start Address Index that people chose
  const [end_addr_idx, set_end_addr_idx] = useState(user?.first_work_address_idx() || 0) //the Start Address Index that people chose
  const [start_radius, set_start_radius] = useState(user?.obj?.start_radius || 5) //the Start Range that people chose
  const [end_radius, set_end_radius] = useState(user?.obj?.end_radius || 3) //the End Range that people chose
  const [end_time, set_end_time] = useState(user?.work_end_time_n_date() || '2022-01-01T17:00:00') //
  const [flex_time_min, set_flex_time_min] = useState(Math.max(parseInt(user?.obj?.commuter_data?.leaveBefore), parseInt(user?.obj?.commuter_data?.arriveBefore)) || 0) //
  const [start_time, set_start_time] = useState(user?.work_start_time_n_date() || '2022-01-01T09:00:00') //
  const [is_gmap_scr_loaded, set_is_gmap_scr_loaded] = useState(false)
  const [vh_rms, set_vh_rms] = useState([])


  const [map, setMap] = React.useState(null)
  //callback fn after gmap script is loaded from internet
  const on_gmap_scr_loaded = () => {
    set_is_gmap_scr_loaded(true)
    user.gmap_ready_cb()
    console.log(`gmap script loaded`)
  }
  const on_react_map_loaded = async (map) => {
    console.log('react gmap component loaded')
    window.map = map
    trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(map);
    directionsDisplay = new google.maps.DirectionsRenderer(
      {
        suppressMarkers: true, polylineOptions: {
          strokeColor: 'red'
        }
      });
    directionsService = new google.maps.DirectionsService()
    directionsRenderer = new google.maps.DirectionsRenderer()
    window.geocoder = new google.maps.Geocoder();

    infowindow = new google.maps.InfoWindow({size: new google.maps.Size(100, 100)});
    // initialize();
    await rm_call_api()
  }

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  let latlng = undefined
  // let latlng = pull_latlng();
  // const change_user = (u) => {
  //   setUser(u)
  //   set_start_radius(u)
  // }

  const [home_addr, set_home_addr] = useState((app.user?.home_address_full && app.user.home_address_full()) || app.curr_address)
  const [segment, set_segment] = useState('map')
  const [work_addr] = useState((app.user.work_addr_full && app.user.work_addr_full()) || app.curr_address)
  const [rm_list, setRmList] = useState([]) //rm_list: {latlng: {lat, lng}; addr: raw html}
  const [show_ask_md, set_show_ask_md] = useState(false) //whether Ask Modal should be shown
  const history = useHistory()
  const toggle_inline_list = function () {
    const map_el = $('#map')
    map_el.toggleClass('has_il')
    if (map_el.hasClass('has_il')) $('#map').height('50%')
    else $('#map').css('height', 'calc(100vh - 130px)')

  }
  const il_focus = function ({lat, lng}) {
    lat = Number.parseFloat(lat)
    lng = Number.parseFloat(lng)
    console.log(`latn lng`, lat, lng)
    if (isNaN(lat) || isNaN(lng)) return
    // if (!(map instanceof google.maps.Map)) map = new google.maps.Map(document.getElementById('map'))
    window.map.panTo({lat, lng})
    window.map.setCenter({lat, lng})
    window.map.setZoom(13)
  }
  /**
   * Show popup of contact form
   * @param rm_obj Object {text_hphone, show_hphone, etc...}
   */
  const show_contact = function (rm_obj) {
//        displayInfoWindow(startMarker, matchNum, '<div class="info_window"><strong>' + show_commuterName + '</strong><br>Work Hours:' + startTime + ' - ' + endTime + '<br>' + ((sharesNothing) ? 'Call Commuter Connections to obtain this commuter\'s contact information at <a href="tel:800-745-7433">800-745-7433</a>' : link_email + link_cphone + link_hphone + link_wphone));
  }

  function clear_markers(markers) {
    for (let m of markers) {
      m.setMap(null);
    }
  }

  const change_segment = function (new_segment) {
    console.log(`new segment`, new_segment)
    if (new_segment == 'map') {
      $('#map_list').addClass('ion-hide')
      $('#map').removeClass('ion-hide')
      $('#inline_list').removeClass('ion-hide')
    } else {
      $('#map_list').removeClass('ion-hide')
      $('#map').addClass('ion-hide')
      $('#inline_list').addClass('ion-hide')
    }
    // set_segment(new_segment)
  }

  //Show popup asking for start and end.
  //To be called on routed
  function show_popup_ask_route() {
    stored_map_cent = window.map.getCenter()
    set_stored_map_cent(stored_map_cent)
    console.warn(`stored map center: `, stored_map_cent)
    p(stored_map_cent)
    set_show_ask_md(true)
  }

  useEffect(() => {
    console.log(`RM use_effect called.`)
    let ask_rm_modal_ref_saved = null
    if (ask_rm_modal_ref.current) {
      ask_rm_modal_ref_saved = ask_rm_modal_ref.current
    }
    /*setTimeout(() => {
      if ($('#map').height() < 300) {
        const new_gmap_key = gmap_key + 1
        set_gmap_key(gmap_key => new_gmap_key)
      }
    }, 5000)*/
    rm_init_user()
    // set_home_addr(app.user.home_address_full() || app.curr_address)
    // set_show_ask_md(true)

    populate_vh_rm_manual()

    return function cleanup() {
      console.log(`Cleaning up rm`)
      // setUser({})
      // set_show_ask_md(false)
      ask_rm_modal_ref_saved.dismiss()
    }
  }, [vh_rms])//only re-run the effect if ...
  function populate_ridematch_deeplink(res) {
    let matches
    clear_markers(gmap_markers_ref.current)

    if (is_latlng_ridematch) {
      try {
        if (res.is_valid === true) {
          matches = res.ridematches;
          //todob proper clearing
          setTimeout(function () {
            window.localStorage.removeItem('latlng');
          }, 5000);
        } else {
          console.error('ridematch latlng return bad is_valid');
          return;
        }
      } catch (e) {
        console.error(e);
        return;
      }
    } else {
      matches = res;
    }
    if (matches === null || matches.length === 0) {
      let row = {addr: 'No result found'};
      setRmList([row])
    } else {
      let new_rm_list = []
      for (let i = 0; i < matches.length; i++) {
        let match = matches[i];
        /*
         *   I prefer to ride with
         *   SMK_2_1 smoker
         *   SMK_2_2 non smoker
         *   SMK_2_3 dont care
         */
        let startTime = match.work_start_time;
        let endTime = match.work_end_time;
        let smokingPref = 'None';

        let text_hphone = (match.hphone == "--" ? "" : match.hphone);
        let text_wphone = (match.wphone == "--" ? "" : match.wphone);
        let text_cphone = (match.cphone == "--" ? "" : match.cphone);
        let text_email = (match.email == "--" ? "" : match.email);

        let show_hphone = "";
        let show_wphone = "";
        let show_cphone = "";
        let show_email = "";
        let link_hphone = "";
        let link_wphone = "";
        let link_cphone = "";
        let link_email = "", matchlistURL = ''


        let show_commuterName = 'Anonymous';
        let email = match.email;
        let commuterName = match.commuter_name;
        let commuterId = match.id_commuter;
        let sharesNothing = true;

        if (match.share_hphone === "Y" && text_hphone.length > 0) {
          show_hphone = "Home: " + text_hphone;
          link_hphone = "Home: <a href='tel:" + text_hphone + "'>" + text_hphone + "</a>";
          sharesNothing = false;
        }
        if (match.share_wphone === "Y" && text_wphone.length > 0) {
          show_wphone = "Work: " + text_wphone;
          link_wphone = "Work: <a href='tel:" + text_wphone + "'>" + text_wphone + "</a>";
          matchlistURL = "tel:" + text_wphone;
          sharesNothing = false;
        }
        if (match.share_cphone === "Y" && text_cphone.length > 0) {
          show_cphone = "Cell: " + text_cphone;
          link_cphone = "Cell: <a href='tel:" + text_cphone + "'>" + text_cphone + "</a>";
          sharesNothing = false;
        }

        if (match.share_email === "Y" && text_email.length > 0) {
          show_email = text_email;
          link_email = `<a href="mailto:${text_email}?subject=Would%20you%20like%20to%20share%20a%20ride%20to%20work%3F&body=According%20to%20Commuter%20Connections%2C%20we%27re%20a%20match%20to%20form%20a%20carpool%21%20If%20you%27re%20interested%20in%20discussing%20this%20commute%20opportunity%20further%2C%20please%20reach%20out%20to%20me." target="_blank" class="phone_email">${text_email}</a>`
          sharesNothing = false;
        }
        if (match.share_name === "Y") {
          show_commuterName = commuterName;
        }


        if (match.smoke_pref === "SMK_2_1") {
          smokingPref = "smoker";
        } else if (match.smoke_pref === "SMK_2_3") {
          smokingPref = "I dont care";
        } else {
          smokingPref = "non-smoker";
        }
        let row_html = ((show_commuterName == 'Anonymous') ? 'Commuter #' + commuterId : show_commuterName) +
          link_email + link_cphone + link_wphone + link_hphone;
        if (sharesNothing) row_html += 'Call Commuter Connections to obtain<br />this commuter\'s contact information<br />at <a href="tel:800-745-7433">800-745-7433</a>';
        row_html += '</br>'
        /*
         'Carpool pref: '+match.RCC_1+'<br />' +
         'Vanpool pref: '+match.RCC_2+'<br />' +
         'Flex time: '+match.arriveAfter+' minutes<br />' +
         'Schedule: '+startTime+'-'+endTime+'<br />' +
         'Days: Mon, Tue, Wed, Thu, Fri <br />' +
         'Smoking pref: '+smokingPref;
         */

        new_rm_list.push({
          addr: row_html,
          latlng: {lat: match.addr_lat, lng: match.addr_lng},
          obj: {text_cphone, show_cphone, text_hphone, show_hphone, text_wphone, show_wphone}
        })

        let matchNum = i;
        let point1 = new google.maps.LatLng(match.addr_lat, match.addr_lng);//todob waiting for latlng to return from API (in case of ridematch latlng)
        let startMarker = createStartMarker(point1, matchNum);
        gmap_markers_ref.current.push(startMarker)
        displayInfoWindow(startMarker, matchNum
          , '<div class="info_window"><strong>' + show_commuterName + '</strong><br>Work Hours:' + startTime + ' - ' + endTime + '<br>' + row_html);
      }
      setRmList(new_rm_list)
    }


    //determine what contact method to use
    $('a.list_item_contact').on('click touch', function (e) {
      let $e = $(e.target), contact_link = '', action = '', $ul = $('#contact_options #contact_details');
      $e = $($e.closest('a'));
      let index = $e.data('index');
      if (!$.isNumeric(index)) {
        return;
      }
      let match = matches[index];
      $('#contact_options #match_firstname').html(match.first_name);
      //let first_name = match.hasOwnProperty('firstName') ? match.firstName : (match.hasOwnProperty('member_name') ? match.member_name :'');

      let contact_options = {
        has_hphone: {
          is_available: (match.share_hphone === "Y" && match.hphone.length > 2),
          type: 'phone',
          type_detail: 'Home Phone',
          detail: match.hphone
        },
        has_wphone: {
          is_available: (match.share_wphone === "Y" && match.wphone.length > 2),
          type: 'phone',
          type_detail: 'Work Phone',
          detail: match.wphone
        },
        has_cphone: {
          is_available: (match.share_cphone === "Y" && match.cphone.length > 2),
          type: 'phone',
          type_detail: 'Cell Phone',
          detail: match.cphone
        },
        has_email: {
          is_available: (match.share_email === "Y" && match.email.length > 2),
          type: 'email',
          type_detail: 'Email',
          detail: match.email
        }
      };
      contact_options = _.filter(contact_options, function (v) {
        return v.is_available;
      });
      if (_.size(contact_options) === 0) {
        document.location.href = 'tel:8007457433';
        return;
      }
      if (_.size(contact_options) === 1) {
        let contact = contact_options[Object.keys(contact_options)[0]];//get first object
        if (contact.type == 'email') {
          document.location.href = 'mailto:' + contact.detail;
        }
        if (contact.type == 'phone') {
          document.location.href = 'tel:' + contact.detail;
        }
        return;
      }
      $ul.empty();
      $.each(contact_options, function (i, v) {
        if (!v.is_available) {
          return;
        }
        if (v.type == 'phone') {
          contact_link = 'CALL ' + v.type_detail;
          //contact_link = 'CALL ' + v.type_detail + ' ' + v.detail;
          action = 'tel:' + v.detail;
        } else {
          contact_link = 'SEND EMAIL';
          //contact_link = 'EMAIL ' + v.detail;
          action = 'mailto:' + v.detail;
        }
        $ul.append($('<li>').append('<a href="' + action + '" >' + contact_link + '</a>'));//<li><a href="tel:5593474767"> Call home phone 559</a></li>
      })
      $ul.append(
        <ion-accordion-group>
          <ion-accordion>
            <ion-item slot="header" color="light">
              <ion-label>Christy Wright</ion-label>
            </ion-item>
            <div slot="content" class="f_cen">
              <ion-button size="tiny two_lines">Call<br/>Commuter Connections</ion-button>
              {/*<IonButton size="tiny two_lines">Call<br/>Work</IonButton>*/}
              {/*<IonButton size="tiny two_lines">Call<br/>Mobile</IonButton>*/}
              {/*<IonButton size="tiny two_lines">Call<br/>Home</IonButton>*/}
              {/*<IonButton size="tiny two_lines">Send<br/>Email</IonButton>*/}
            </div>
          </ion-accordion>
        </ion-accordion-group>
      )
      // $('#contact_options').popup();
      // $('#contact_options').popup('open', {transition: 'pop', history: false, positionTo: "window"});
    });
    // hideSpinner();
  }

  /**
   * Populate ridematches to the map
   * @param matches List of matches from API
   */
  function populate_ridematch_manual(matches) {
    console.log(`populate_ridematch_manual called`)
    clear_markers(gmap_markers_ref.current)

    if (matches === null || matches.length === 0) {
      setRmList([])
      Swal.fire({
        title: '',
        html: 'No matches found with your current search criteria.  Click here to try your search again with a wider search radius!',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Adjust Search',
        cancelButtonText: `Close`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          ask_rm_modal_ref?.current?.present()
        }
      })

    } else {
      let new_rm_list = []
      for (let i = 0; i < matches.length; i++) {
        let match = matches[i];
        /*
         *   I prefer to ride with
         *   SMK_2_1 smoker
         *   SMK_2_2 non smoker
         *   SMK_2_3 dont care
         */
        let startTime = match.fromHRS + ":" + match.fromMNS + match.fromAMPM;
        let endTime = match.toHRS + ":" + match.toMNS + match.toAMPM;
        let smokingPref = "None";

        let text_hphone = (match.hphone == "--" ? "" : match.hphone);
        let text_wphone = (match.wphone == "--" ? "" : match.wphone);
        let text_cphone = (match.cphone == "--" ? "" : match.cphone);
        let text_email = (match.email == "--" ? "" : match.email);
        const share_addr = (match.shareAddr == 'Y')

        let show_hphone = "";
        let show_wphone = "";
        let show_cphone = "";
        let show_email = "";
        let link_hphone = "";
        let link_wphone = "";
        let link_cphone = "";
        let link_email = "";


        let show_commuterName = "Anonymous";
        let email = match.email;
        let commuterName = match.commuterName;
        let commuterId = match.idCommuter;
        let sharesNothing = true, matchlistURL = ''


        if (match.shareHPhone === "Y" && text_hphone.length > 0) {
          show_hphone = "Home: " + text_hphone;
          link_hphone = `<ion-button size="tiny two_lines" href="tel:${text_hphone}">Call<br/>Home</ion-button>`
          sharesNothing = false;
        }
        if (match.shareWPhone === "Y" && text_wphone.length > 0) {
          show_wphone = "Work: " + text_wphone;
          link_wphone = `<ion-button size="tiny two_lines" href="tel:${text_wphone}">Call<br/>Work</ion-button>`
          matchlistURL = "tel:" + text_wphone;
          sharesNothing = false;
        }
        if (match.shareCPhone === "Y" && text_cphone.length > 0) {
          show_cphone = "Cell: " + text_cphone;
          link_cphone = `<ion-button size="tiny two_lines" href="tel:${text_cphone}">Call<br/>Mobile</ion-button>`
          sharesNothing = false;
        }

        if (match.shareEmail === "Y" && text_email.length > 0) {
          show_email = text_email;
          link_email = `<ion-button size="tiny two_lines" 
href="mailto:${text_email}?subject=Would%20you%20like%20to%20share%20a%20ride%20to%20work%3F&body=According%20to%20Commuter%20Connections%2C%20we%27re%20a%20match%20to%20form%20a%20carpool%21%20If%20you%27re%20interested%20in%20discussing%20this%20commute%20opportunity%20further%2C%20please%20reach%20out%20to%20me." 
target="_blank">Send<br/>Email</ion-button>`
          sharesNothing = false;
        }
        if (match.shareName === "Y") {
          show_commuterName = commuterName;
        }

        if (match.smokePref === "SMK_2_1") {
          smokingPref = "smoker";
        } else if (match.smokePref === "SMK_2_3") {
          smokingPref = "I dont care";
        } else {
          smokingPref = "non-smoker";
        }
        let email_phones = [link_email, link_cphone, link_wphone, link_hphone].filter(l => l > '').join('<br/>')
        let email_phones_inline = [link_email, link_cphone, link_wphone, link_hphone].filter(l => l > '').join('<br/>')
        let row_html_1 = ((show_commuterName == 'Anonymous') ? 'Commuter #' + commuterId : show_commuterName)
        row_html_1 += '<br>' + match.addrCity + ' ' + match.addrState
        let row_html_2 = ''
        const call_comm = `<ion-button size="tiny two_lines" style="width:100% !important;" href="tel:800-745-7433">Call<br/>Commuter Connections</ion-button>`
        let call_buttons = '' //Up to four buttons Call Work Call Mobile, etc..
        if (link_wphone > ' ') call_buttons += link_wphone
        if (link_cphone > ' ') call_buttons += link_cphone
        if (link_hphone > ' ') call_buttons += link_hphone
        if (link_email > ' ') call_buttons += link_email
        if (call_buttons == '') call_buttons = call_comm

        row_html_2 += //change to sth else
          row_html_1 + `<br/>` + call_buttons
        /*
                  <ion-accordion>
                    <ion-item slot="header" color="light">
                      <ion-label>${row_html_1}</ion-label>
                    </ion-item>
                    <div slot="content" class="f_cen">
                      ${call_buttons}
                    </div>
                  </ion-accordion>
        */


        /*
         'Carpool pref: '+match.RCC_1+'<br />' +
         'Vanpool pref: '+match.RCC_2+'<br />' +
         'Flex time: '+match.arriveAfter+' minutes<br />' +
         'Schedule: '+startTime+'-'+endTime+'<br />' +
         'Days: Mon, Tue, Wed, Thu, Fri <br />' +
         'Smoking pref: '+smokingPref;
         */

        new_rm_list.push({
          row_html_2, latlng: {lat: match.addrLat, lng: match.addrLng},
          obj: {text_cphone, show_cphone, text_hphone, show_hphone, text_wphone, show_wphone}
        })

        let matchNum = i;
        let point1 = new google.maps.LatLng(match.match[3], match.match[2]);//todob waiting for latlng to return from API (in case of ridematch latlng)
        let point2 = new google.maps.LatLng(match.match[6], match.match[5]);
        let startMarker = createStartMarker(point1, matchNum);
        window.map.setCenter(point1)
        window.map.panTo(point1)
        let destnMarker = createDestnMarker(point2, matchNum);
        displayInfoWindow(startMarker, matchNum
          , '<div class="info_window"><strong>' + show_commuterName + '</strong><br>Work Hours: ' + startTime + ' - ' + endTime + '<br>' + call_buttons);
        displayInfoWindow(destnMarker, matchNum
          , '<div class="info_window"><strong>' + show_commuterName + '</strong><br>Work Hours: ' + startTime + ' - ' + endTime + '<br>' + call_buttons);
        gmap_markers_ref.current.push(startMarker)
        gmap_markers_ref.current.push(destnMarker)
      } //end for matches
      setRmList(new_rm_list)
    }

    //determine what contact method to use
    $('a.list_item_contact').on('click touch', function (e) {
      let $e = $(e.target), contact_link = '', action = '', $ul = $('#contact_options #contact_details');
      $e = $($e.closest('a'));
      let index = $e.data('index');
      if (!$.isNumeric(index)) {
        return;
      }
      let match = matches[index];
      let first_name = match.hasOwnProperty('firstName') ? match.firstName : (match.hasOwnProperty('member_name') ? match.member_name : '');
      $('#contact_options #match_firstname').html();
      let contact_options = {
        has_hphone: {
          is_available: (match.shareHPhone === "Y" && match.hphone.length > 2),
          type: 'phone',
          type_detail: 'Home Phone',
          detail: match.hphone
        },
        has_wphone: {
          is_available: (match.shareWPhone === "Y" && match.wphone.length > 2),
          type: 'phone',
          type_detail: 'Work Phone',
          detail: match.wphone
        },
        has_cphone: {
          is_available: (match.shareCPhone === "Y" && match.cphone.length > 2),
          type: 'phone',
          type_detail: 'Cell Phone',
          detail: match.cphone
        },
        has_email: {
          is_available: (match.shareEmail === "Y" && match.email.length > 2),
          type: 'email',
          type_detail: 'Email',
          detail: match.email
        }
      };
      contact_options = _.filter(contact_options, function (v) {
        return v.is_available;
      });
      if (_.size(contact_options) === 0) {
        document.location.href = 'tel:8007457433';
        return;
      }
      if (_.size(contact_options) === 1) {
        let contact = contact_options[Object.keys(contact_options)[0]];//get first object
        if (contact.type == 'email') {
          document.location.href = 'mailto:' + contact.detail;
        }
        if (contact.type == 'phone') {
          document.location.href = 'tel:' + contact.detail;
        }
        return;
      }
      $ul.empty();
      $.each(contact_options, function (i, v) {
        if (!v.is_available) {
          return;
        }
        if (v.type == 'phone') {
          contact_link = 'CALL ' + v.type_detail;
          //contact_link = 'CALL ' + v.type_detail + ' ' + v.detail;
          action = 'tel:' + v.detail;
        } else {
          contact_link = 'SEND EMAIL';
          //contact_link = 'EMAIL ' + v.detail;
          action = 'mailto:' + v.detail;
        }
        $ul.append($('<li>').append('<a href="' + action + '" >' + contact_link + '</a>'));//<li><a href="tel:5593474767"> Call home phone 559</a></li>
      });
      /*$ul.append(
        <ion-accordion-group>
          <ion-accordion>
            <ion-item slot="header" color="light">
              <ion-label>Christy Wright</ion-label>
            </ion-item>
            <div slot="content" class="f_cen">
              <ion-button size="tiny two_lines">Call<br/>Commuter Connections</ion-button>
              {/!*<IonButton size="tiny two_lines">Call<br/>Work</IonButton>*!/}
              {/!*<IonButton size="tiny two_lines">Call<br/>Mobile</IonButton>*!/}
              {/!*<IonButton size="tiny two_lines">Call<br/>Home</IonButton>*!/}
              {/!*<IonButton size="tiny two_lines">Send<br/>Email</IonButton>*!/}
            </div>
          </ion-accordion>
        </ion-accordion-group>
      )*/
      // $('#contact_options').popup();
      // $('#contact_options').popup('open', {transition: 'pop', history: false, positionTo: "window"});
    });
    // hideSpinner();
  }

  //function populate_ridematch_manual end

  /**
   * Populate vh ridematches to the map
   * Each match "id": "154319",
   "contactName": "Steve Higgins @ 202-222-3333",
   "contactNumber": "Steve Higgins @ 202-222-3333",
   "startAddress": "2708 Meadow Drive, Gettysburg, PA 17325",
   "endAddress": "935 Pennsylvania Avenue Northwest, Northwest Washington, Washington, DC 20530",
   "startTime": "9:00AM",
   "endTime": "5:00PM",
   "singleRideCost": "$12",
   "vanpoolId": "1254"
   */
  async function populate_vh_rm_manual() {
    console.log(`populate_vh_rm_manual called`)
    if (vh_rms === null || vh_rms.length === 0) {
      console.info(`No vh_rms yet, exiting..`)
      return false
    }
    for (let i = 0; i < vh_rms.length; i++) {
      let match = vh_rms[i]
      const {
        startAddress: start_addr, contactNumber: name_and_contact_number, endAddress: end_addr, startTime, endTime, singleRideCost: single_ride_cost
        , vanpoolId: vanpool_id, startTime: m_start_time, endTime: m_end_time
      } = match
      const name_contact_number_arr = name_and_contact_number.split(' @ ')
      /*
       *   I prefer to ride with
       *   SMK_2_1 smoker
       *   SMK_2_2 non smoker
       *   SMK_2_3 dont care
       */
      let smokingPref = "None";

      let text_hphone = null
      let text_wphone = null
      let text_cphone = name_contact_number_arr.pop() //last element
      let text_email = null
      const share_addr = 'Y'

      let show_hphone = "";
      let show_wphone = "";
      let show_cphone = "";
      let show_email = "";
      let link_hphone = "";
      let link_wphone = "";
      let link_cphone = "";
      let link_email = "";


      let show_commuterName = "Anonymous";
      let email = match.email;
      let commuterName = name_contact_number_arr.shift() //first element
      let sharesNothing = true

      if (text_cphone.length > 0) {
        sharesNothing = false;
      }

      let matchNum = parseInt(vanpool_id) //vh matches are not numbered per frontend design
      //perform geo-decode to get lat lng
      const {lat: start_lat, lng: start_lng} = await geocode(start_addr)
      // const {lat: end_lat, lng: end_lng} = await geocode(end_addr)
      if (!start_lat || !start_lng) { // || !end_lat || !end_lng) {
        console.error(`Error, cannot geocode ${start_addr} or ${end_addr}`)
      } else {
        let point1 = new google.maps.LatLng(start_lat, start_lng)
        // let point2 = new google.maps.LatLng(end_lat, end_lng)
        let startMarker = createStartMarker(point1, matchNum, 'assets/icon/vh_circle_gm_marker.svg', true)
        // let destnMarker = createDestnMarker(point2, matchNum);
        displayInfoWindow(startMarker, matchNum
          , `<div class="info_window vh">VANPOOL #${vanpool_id}&nbsp;&nbsp;&nbsp;&nbsp; 
<a href="https://commuterconnections.org/commuters/" target="_blank" class="pull-right">Learn More <img class="icon_small" style="width: 16px;" src="assets/icon/caret-forward-outline.svg" alt="learnmore"></a>
<br/>From: ${start_addr}<br/>To: <strong>${end_addr}</strong> <br/>${m_start_time} to ${m_end_time} / Single Ride Cost: ${single_ride_cost}`);
        // displayInfoWindow(destnMarker, matchNum
        //   , '<div class="info_window"><strong>' + show_commuterName + '</strong><br>Work Hours: ' + startTime + ' - ' + endTime + '<br>');
        gmap_markers_ref.current.push(startMarker)
        // gmap_markers_ref.current.push(destnMarker)
      }
    } //end for matches

    //determine what contact method to use
  }

  function pull_latlng() {
    let latlng_obj = window.localStorage.getItem('latlng');
    window.localStorage.removeItem('latlng');
    console.info('Latlng from localstorage: ' + latlng_obj);
    latlng_obj = decodeURI(decodeURI(latlng_obj));
    try {
      latlng_obj = JSON.parse(latlng_obj);
    } catch (e) {
      console.error('Cant pull latlng object');
      console.error(e);
    }
    return latlng_obj;
  }

  /**
   * initialize
   * Grab user data from localstorage. Todob future: use Context instead of ls
   */
  async function rm_init_user() {
    let ls_user = ls('user')
    let u = new User(ls_user)
    await setUser(u)
    set_start_addr_idx(u.first_home_address_idx() || 0)
    set_end_addr_idx(u.first_work_address_idx() || 0)
    console.log(`init finished setting user state`)
    try {
      if (typeof Keyboard == 'object') Keyboard.hide();
    } catch (e) {
      console.info(e);
    }


    const user_ls = ls('user')
    if (typeof user_ls != 'object' || !(user_ls.obj) || !(user_ls.obj.commuter_data) || typeof user_ls.obj.commuter_data != 'object') console.log('Commuter data not found in login information. Timing issue?')
    user_ls.commuter_data = user_ls.obj.commuter_data || {}
    const user_comm_data = user_ls.obj.commuter_data
    let idCommuter = user_comm_data.idCommuter
    let userName = user_comm_data.userName
    let startingTime = user_comm_data.fromHRS + ':' + user_comm_data.fromMNS + ' ' + user_comm_data.fromAMPM //8:00 AM
    let endingTime = user_comm_data.toHRS + ':' + user_comm_data.toMNS + ' ' + user_comm_data.toAMPM//5:00 PM
    let arriveBefore = user_comm_data.arriveBefore
    let leaveBefore = user_comm_data.leaveBefore
    if (arriveBefore > 0 && leaveBefore > 0) set_flex_time_min(Math.max(arriveBefore, leaveBefore))

    let is_latlng_ridematch = false;

    if (IS_DEBUG) {
      is_latlng_ridematch = 1;
      window.localStorage.setItem('latlng', '{"pickup_lat":"38.892949","pickup_lng":"-77.018179","dropoff_lat":"38.899949","dropoff_lng":"-77.011179","pickup_full_address":"5995 Dandridge Ln, San Diego, CA 92115, USA","dropoff_full_address":"4501 Norwood St, San Diego, CA 92115, USA"}');
    }

  }//rm_init_user end

  /**
   * Call API to get list of ride matches
   * Then, call populate_ridematch_manual to show those matches in the map.
   * This function is called from either on_react_map_loaded; or md_perform_search
   * @param u User object
   * @param latlng If being called from deeplink, this variable is set, with pickup_lat and dropoff_lat.
   */
  async function rm_call_api(u, latlng) {
    try {
      present_loading({message: 'Searching for matches...'})
      u = u || user
      if (typeof u !== 'object') {
        setTimeout(dismiss_loading, 2000)
        return false
      }
      let start_time_time_only = date_time_to_rm_time(start_time, 'h:mm A', '09:00')
      if (!start_time_time_only) start_time_time_only = u.startingTime()

      let end_time_time_only = date_time_to_rm_time(end_time, 'h:mm A', '17:00')
      if (!end_time_time_only) end_time_time_only = u.endingTime()

      const rm_params = "&workStartTime=" + start_time_time_only + "&workEndTime=" + end_time_time_only + "&flexibility=" + flex_time_min + "&endRadius=" + end_radius + "&startRadius=" + start_radius;

      // adjustWindowheight($('.fullscreenelement'));
      let ridematch_url = baseUrl + '/json?action=ridematch&idCommuter=' + u.obj.commuter_data.idCommuter + '&userName=' + u.obj.username
        + "&startAddressIndex=" + start_addr_idx + '&endAddressIndex=' + end_addr_idx + rm_params;
      const is_latlng_ridematch = (_.isObject(latlng) && latlng.hasOwnProperty('pickup_lat') && latlng.hasOwnProperty('dropoff_lat'));
      let ridematch_params, matches
      if (is_latlng_ridematch) {
        ridematch_url = ie511_url;
        console.info('Ridematch latlng from deeplink');
        ridematch_params = {
          action: 'findRidematchesRadiusLatLng',
          start_lat: latlng.pickup_lng,//todob here API is wrong
          start_lng: latlng.pickup_lat,
          start_radius,
          end_lat: latlng.dropoff_lng,
          end_lng: latlng.dropoff_lat,
          end_radius,
          carpool: 'RCC_1_5',
          vanpool: 'RCC_2_4',
          work_start_time: start_time_time_only,
          work_end_time: end_time_time_only,
          arrive_before_time: 90,
          arrive_after_time: 90,
          leave_before_time: 90,
          leave_after_time: 90
        };
        if (IS_DEBUG) {//note, this needs to be verified
          matches = []
          populate_ridematch_deeplink(matches)
        } else matches = await $.get(ridematch_url, ridematch_params, populate_ridematch_deeplink, 'json');
      } else {
        ridematch_params = {};
        if (IS_DEBUG) {//note, this needs to be verified
          matches = []
          populate_ridematch_manual(matches)
        } else {
          // matches = await $.get(ridematch_url, ridematch_params, populate_ridematch_manual, 'json');//!!!IMPORTANT: success callback is populate_ridematch_manual
          matches = await axios.get(ridematch_url, ridematch_params)
          if (matches.status != 200) return dismiss_loading()
          matches = matches?.data
          await get_vh_rms()
          populate_ridematch_manual(matches)
        }
        //https://tdm.commuterconnections.org/mwcog//json?action=ridematch
        // &idCommuter=742915&userName=flexmedia&startAddressIndex=0&endAddressIndex=1&workStartTime=9:00 AM&workEndTime=5:00 PM&flexibility=15&endRadius=2&startRadius=3
        //https://tdm.commuterconnections.org/mwcog//json?action=ridematch
        // &idCommuter=742915&userName=flexmedia&startAddressIndex=0&endAddressIndex=1&workStartTime=9:00 AM&workEndTime=1700 PM&flexibility=15&endRadius=15&startRadius=15
      }
      let chosen_start_addr = u.obj.addresses[start_addr_idx]?.latlng,
        chosen_end_addr = u.obj.addresses[end_addr_idx]?.latlng
      // let chosen_start_addr = u.obj.addresses[start_addr_idx]?.full_address_text, chosen_end_addr = u.obj.addresses[end_addr_idx]?.full_address_text
      rm_draw_directions(chosen_start_addr, chosen_end_addr)
      dismiss_loading()
      const map_el = $('#map')
      if (map_el.hasClass('has_il')) $('#map').height('50%')
      else $('#map').css('height', 'calc(100vh - 130px)')
      /*if ($('#map').height < 100){//gmap is somehow hidden / zero height
      set_gmap_key(gmap_key => Math.random())
    }*/
    } catch (e) {
      dismiss_loading()
      console.error(`error:`, e)
    }
  } //end rm_call_api

  $(document).on("mobileinit", function () {
    //brian3t added on 0421 - we are not using $.mobile navigation at all
    $.extend($.mobile, {
      linkBindingEnabled: false,
      ajaxEnabled: false
    });
  });


  function rs_back() {
    /*    event.preventDefault();
        if (is_latlng_ridematch){
            // if (is_latlng_ridematch && typeof navigator.app === "object" && navigator.app.hasOwnProperty('exitApp')){
            // navigator.app.exitApp();
            launchDeepLinkCPN();
        } else {
            window.location.href='search.html';
        }
        is_latlng_ridematch = false;*/
  }

  /**
   * Gmaps helper - create start marker
   * @param point
   * @param index
   * @param imageUrl String Optional; can override img
   * @param no_label boolean
   * @return {google.maps.Marker}
   */
  function createStartMarker(point, index, imageUrl = 'img/marker_circle_light_blue.svg', no_label = false) {
    let marker;
    let i = index + 1
    const label = no_label ? '' : {text: String(i), color: 'white', fontWeight: 'bold'}
    // let imageUrl = "assets/icon/cal.svg";
    marker = new google.maps.Marker({
      position: point,
      map: window.map,
      draggable: false,
      icon: imageUrl,
      label,
      zIndex: 3
    });
    return marker;
  }

  function createDestnMarker(point, index) {
    let marker;
    let i = index + 1;
    let imageUrl = "img/marker_circle_blue.svg";
    marker = new google.maps.Marker({
      position: point,
      map: window.map,
      draggable: false,
      icon: imageUrl,
      label: {text: String(i), color: 'white', fontWeight: 'bold'},
      zIndex: 3
    });
    return marker;
  }

  function displayMarker(point, mrkImage) {

    let marker;
    let imageUrl = baseUrl + "includes/images/" + mrkImage;
    marker = new google.maps.Marker({position: point, map: window.map, draggable: false, icon: imageUrl});
    return marker;
  }

  function displayInfoWindow(marker, count, infoHtl) {
    count = count + 1;
    let message = infoHtl;
    google.maps.event.addListener(marker, 'click', function () {
      if (!infowindow) infowindow = new google.maps.InfoWindow({size: new google.maps.Size(100, 100)});
      infowindow.setContent(message);
      infowindow.open(map, marker);
    });
  }


  function initialize() {
    // showSpinner();

    let lineOptions = {strokeColor: "#0d0d65", zIndex: google.maps.Marker.MAX_ZINDEX + 1};
    let renderOptions = {draggable: false, polylineOptions: lineOptions};
    directionsDisplay.setOptions(renderOptions);

    let mapOptions = {
      zoom: 11,
      zoomControl: true,
      panControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.DEFAULT,
        position: google.maps.ControlPosition.LEFT_TOP
      },
      center: new google.maps.LatLng(38.899960, -77.008975),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    // if (!(map instanceof google.maps.Map)) map = new google.maps.Map(document.getElementById('map'))
    directionsDisplay.setMap(window.map)
    directionsDisplay.setPanel(document.getElementById("route"));
    google.maps.event.addListener(map, 'click', function () {
      infowindow.close();
    });

    let start = "";
    let end = "";
    //deeplink
    if (_.isObject(latlng) && latlng.hasOwnProperty('pickup_lat')) {
      start = new google.maps.LatLng(latlng.pickup_lat, latlng.pickup_lng);
      end = new google.maps.LatLng(latlng.dropoff_lat, latlng.dropoff_lng);
      rm_draw_directions(start, end);
    }

    // let addressArray  = JSON.parse(window.localStorage.getItem("addresses"));

    geocoder.geocode({'address': startAddress}, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        start = results[0].geometry.location;
      } else {
        //alert("Geocode was not successful for the following reason: " + status);
      }
    });
    geocoder.geocode({'address': endAddress}, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        end = results[0].geometry.location;
        //deeplink
        if (_.isObject(latlng) && latlng.hasOwnProperty('pickup_lat')) {
          start = new google.maps.LatLng(latlng.pickup_lat, latlng.pickup_lng);
          end = new google.maps.LatLng(latlng.dropoff_lat, latlng.dropoff_lng);
        }

        window.map.setCenter(end);
        window.map.setZoom(12);

        rm_draw_directions(start, end);

      } else {
        //alert("Geocode was not successful for the following reason: " + status);
      }
    });
  }

  function toggleTraffic() {
    if (toggleState === 0) {
      trafficLayer.setMap(null);
      toggleState = 1;
    } else {
      trafficLayer.setMap(map);
      toggleState = 0;
    }
  }

//$(document).on("pageshow", "#ridematch", resizeMap);
  function resizeMap() {
  }

  $(document).bind('orientationchange', function () {
  });

  /**
   * draw drirection between start and end
   * @param start LatLng
   * @param end LatLng
   */
  function rm_draw_directions(start, end) {
    // if (! (start instanceof google.maps.LatLng)) return
    p(start)
    p(end)
    if (!(directionsService.hasOwnProperty('route'))) {
      console.warn(`Error directionService not ready`)
      directionsService = new google.maps.DirectionsService()
    }
    try {
      directionsService.route({
        // origin: {query: start}, destination: {query: end},
        origin: start, destination: end,
        travelMode: google.maps.TravelMode.DRIVING
      }).then((result) => {
        if (result.status === google.maps.DirectionsStatus.OK) {
          //uncomment if the client requests manual zoom control
          //directionsDisplay.setOptions({ preserveViewport: true });
          // directionsDisplay.setDirections(result); old code 2019
          let lineOptions = {
            strokeColor: "#0d0d65",
            strokeOpacity: 1,
            strokeWeight: 3,
            zIndex: google.maps.Marker.MAX_ZINDEX + 1
          };
          let renderOptions = {suppressMarkers: true, draggable: false, polylineOptions: lineOptions};
          directionsRenderer.setOptions(renderOptions)
          directionsRenderer.setMap(window.map)
          directionsRenderer.setDirections(result);
          //draw route info here
          window.map.pickup_marker = new google.maps.Marker({
            map: window.map,
            position: start,
            labelContent: 'pickup',
            icon: 'img/gmap_marker_a.svg',
            label: {text: ' ', color: 'white', 'fontWeight': 'bold'},
            zIndex: 0
          });
          window.map.dropoff_marker = new google.maps.Marker({
            map: window.map,
            position: end,
            labelContent: 'dropoff',
            icon: 'img/gmap_marker_b.svg',
            label: {text: ' ', color: 'white', 'fontWeight': 'bold'},
            zIndex: 0
          });

        }
      }).catch((e) => {
        console.error(`Directions request failed due to `, e)
      })
    } catch (e) {
      console.error(`Error: `, e)
    }
  }

  function launchDeepLinkCPN() {
    let scheme = 'carpoolnow://';
    // Don't forget to add the org.apache.cordova.device plugin!
    if (typeof device === 'object' && device.platform === 'iOS') {
      scheme = 'carpoolnow://';
    } else if (typeof device === 'object' && device.platform === 'Android') {
      scheme = 'com.mediabeef.carpoolnow';
    }
    if (typeof appAvailability == 'object') return false
    appAvailability.check(
      scheme, // URI Scheme
      function () {
        // Success!
        console.log('CPN app IS installed, launching: ');
        cordova.InAppBrowser.open('carpoolnow://', '_system', 'location=no,clearcache=yes,keyboardDisplayRequiresUserAction=no,toolbar=no,hardwareback=no,hidden=yes');
      },
      function () {
        // Fail!
        if (device.platform === 'iOS') {
          window.open('https://itunes.apple.com/us/app/carpoolnow/id1149824061?mt=8', '_system');
        } else {
          window.open('https://play.google.com/store/apps/details?id=com.mediabeef.carpoolnow', '_system');
        }
      }
    );
  }

  function test_rm() {
    console.log(`test rm clicked`)
    let l = new google.maps.LatLng(38.85, -77.17)
    window.map.panTo(l)
    window.map.setZoom(12)
  }

  /**
   * Get Vanhopper ridematches from API
   * @return void
   */
  async function get_vh_rms() {
    let vh_rms_fr_api = []
    const start_addr = app.user.first_home_address()
    const end_addr = app.user.first_work_address()
    if (!start_addr || !end_addr) return set_vh_rms(vh_rms_fr_api)
    if (!start_addr.latlng || !end_addr.latlng) {
      //Home addr Work addr missing latlng
      start_addr.latlng = await geocode(start_addr.full_address_text)
      end_addr.latlng = await geocode(end_addr.full_address_text)
    }
    //first, get geocode from /mwcog/ API //https://tdm.commuterconnections.org/mwcog/geocode?action=route&
    const geocode_params = {
      action: 'route', originLat: start_addr.latlng.lat, originLng: start_addr.latlng.lng, destinationLat: end_addr.latlng.lat, destinationLng: end_addr.latlng.lng
    }
    const geocode_url = MWCOG_BASE_URL + 'geocode?' + (new URLSearchParams(geocode_params)).toString()
    const geocode_res = await axios.get(geocode_url, {})
    // const geocode_res = {routes: [{overview_polyline: 'cm`iFxe`xM{@v@aFkJxUuWlTo_@~E_KwGkEuM_BwQsT{L{Lod@}i@cV{SiVmNuy@e^sNuKoJkLui@iy@oNoRkn@st@uOiOcJeHsNqIgKwEgQcG{e@_N{TgJoMsH_d@q[kQiK{TwKyZmM_t@a`@mNyFmPqDgJaAsHSmNXid@zEqMB{PsB}PiGqf@qZq`@uXs]eYc_Aky@}T}NyO_HiIiC{w@sQmSeGeNeGqTeNaUuSmlBu}BoIsHmEqCsRsHujBsg@cOqGoJaHgcAieAuLeKqJ}F_J}Ds~Ayf@iKqEwEwC{HwGer@ew@wIiHcHaEyjAyc@qy@yTgVwH_SgIqpBo~@sH{FwHaJqEoIaE{KoD}SgHggAqFcc@iLgk@iFaR_IeRuJuNkFsFuIsG}IqEsL_EqKuBkvAePiQoCmYeHoPeHeHiF_K}KmG}IgOoWsPiW{K}Ny`@me@}IuIoZcW}XeSgPaKsPkI{L{EmOwEgLcC{YoDaM}@_~@mBuTuAmIaBiFeBod@yGaPeDqSaGiHoCuBwAgDmEkAkCeC}Ko@cKEeVqE{c@sIwk@}X{_Dy\\srBoBmSs@aWf@yW|PmqCjEyW|Wm|@dB{H`AyIhGkwAx@wZm@{gAyBqp@pAsTSaFkAkDsCgC{Bm@qDd@iDrCwGzRcFbHmR~LwHpBoH`AiYDqHsA_\\_QwIuDaL_DuRiCyCy@g@qAHkAj@q@|@GdAvAFfImc@M_HqByHrGTbAvB`B'}]}
    if (geocode_res.status != 200) return console.error(`Cannot get mwcog geocode: ${geocode_res.statusText}`)
    const overview_polyline = geocode_res?.data?.routes?.pop()?.overview_polyline
    if (!overview_polyline || overview_polyline < ' ') return null

    const find_vp_matches_params = {
      action: 'findVanpoolMatchesPost', polyline: overview_polyline
    }
    const find_vp_matches_url = `https://tdm.commuterconnections.org/vanpool/ridematch` + '?' + (new URLSearchParams(find_vp_matches_params)).toString()

    vh_rms_fr_api = await axios.post(find_vp_matches_url)
    if (!vh_rms_fr_api || vh_rms_fr_api.status != 200) return console.error(`Cannot get vh rm from API`, vh_rms_fr_api.statusText)
    vh_rms_fr_api = vh_rms_fr_api.data
    if (!vh_rms_fr_api.hasOwnProperty('resultSet'))  return console.error(`Cannot get vh rm from API; missing resultSet`, vh_rms_fr_api)
    const vh_result_set = vh_rms_fr_api.resultSet
    //build list to make html rows
    vh_rms_fr_api = []
    for (const vh_rm of vh_result_set) {
      vh_rm.row_html = ' 1 '
      vh_rm.row_html_vpnum = `VANPOOL #` + vh_rm.id
      vh_rm.row_html_from = `From: ` + vh_rm.startAddress
      vh_rm.row_html_to = `To: ` + vh_rm.endAddress
      vh_rm.row_html_sched_cost = `Mon-Fri, ${vh_rm.startTime} to ${vh_rm.endTime} / Single Ride Cost: ${vh_rm.singleRideCost}`
      vh_rms_fr_api.push(vh_rm)
    }
    const vh_rms_fr_api_new_arr = _.cloneDeep(vh_rms_fr_api)
    set_vh_rms(vh_rms_fr_api_new_arr) //useEffect will capture the change
  }

  async function vh_learn_more() {
    alert('learn more')
  }

  async function schedule_ride_vh() {
    alert('vh ride popup')
  }

  /*  async function get_vanhoppr_reqs() {
      let vh_requests = await api_vp.g('getRequestsList', {
        apiKey: VANPOOL_APIKEY, idVanpool: app.user?.obj?.vanpoolID
      })//apiKey=testchangeme4&idVanpool=1282
      if (typeof vh_requests == 'object') vh_requests = vh_requests['requests']
      if (typeof vh_requests == "object" && vh_requests instanceof Array) {
        const vh_reqs_pend = vh_requests.filter((req) => (req.status == 'P'))
      }
    }*/

  return (
    <IonPage id="page_rm" className="page_routed">
      <Menu title={`Welcome ` + (app.user.first_name && app.user.first_name())} logout={{logout}} app={app}
            app_user={app.user} curpage="rm"></Menu>
      <IonContent fullscreen background="none" id="rm_cont">
        <div id="rm_segment" className="w-90-cen d-flex-cen">
          {/*<IonSegment mode="ios" value={segment} onIonChange={e => change_segment(e.detail.value)}>
            <IonSegmentButton value="map" style={{marginRight: '5px'}}>
              <IonLabel>Map</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="list">
              <IonLabel>List</IonLabel>
            </IonSegmentButton>
          </IonSegment>*/}
          {/*<IonButton onclick={test_rm} size="small" style={{marginLeft: `5px`}}>Test</IonButton>*/}
          <IonButton id="open_rm_modal_btn" size="small" style={{marginLeft: `5px`}}>Update Search</IonButton>
          <IonButton onclick={toggle_inline_list} disabled={rm_list.length == 0} size="small"
                     style={{marginLeft: `15px`}}>Toggle List</IonButton>
        </div>
        <IonModal id="ask_rm_modal" ref={ask_rm_modal_ref} trigger="open_rm_modal_btn" onDidDismiss={ask_md_did_dismiss}
                  onWillPresent={() => {
                    console.log(`Ask md will present..`)
                  }}
                  onDidPresent={() => {
                    console.log(`ask_md did present`) //b3t hack datetime hide year
                    /*const ion_datetime_ny = $('ion-datetime.no_year')
                    if (ion_datetime_ny.length <= 0) return
                    ion_datetime_ny.css('max-width', '100%')
                    const datetime_calendar = $(ion_datetime_ny[0].shadowRoot).find('.datetime-calendar')
                    if (datetime_calendar.length <= 0) return
                    datetime_calendar.css('display', 'none')
                    datetime_calendar.css('height', '0')
                    const datetime_time = $(ion_datetime_ny[0].shadowRoot).find('.datetime-time')
                    if (datetime_time.length > 0) {
                      datetime_time.css('padding-left', '0')
                      datetime_time.css('padding-right', '0')
                    }

                    if (ion_datetime_ny.length < 2) return
                    ion_datetime_ny.css('max-width', '100%')
                    const datetime_calendar_2 = $(ion_datetime_ny[1].shadowRoot).find('.datetime-calendar')
                    if (datetime_calendar_2.length <= 0) return
                    datetime_calendar_2.css('display', 'none')
                    datetime_calendar_2.css('height', '0')
                    const datetime_time_2 = $(ion_datetime_ny[1].shadowRoot).find('.datetime-time')
                    if (datetime_time_2.length > 0) {
                      datetime_time_2.css('padding-left', '0')
                      datetime_time_2.css('padding-right', '0')
                    }*/
                  }
                  } showBackdrop={true}>
          <div id="ask_rm_modal_div" className="p10">
            <div>
              <IonLabel>Start Address
                <IonSelect interface="action-sheet" placeholder="Start Address" value={start_addr_idx}
                           onIonChange={(e) => {
                             set_start_addr_idx(e.detail.value)
                           }} interfaceOptions={{
                  size: "cover", cssClass: "ac_sh_big_button"
                }}>
                  {user?.obj?.addresses && user?.obj?.addresses.map((user_address, i) => (
                    <IonSelectOption key={i} value={i}>{user_address.full_address_text_w_type}</IonSelectOption>
                  ))
                  })
                </IonSelect></IonLabel>
              <IonLabel>Start Radius</IonLabel>
              <IonSelect interface="action-sheet" placeholder="Start Radius" value={start_radius}
                         onIonChange={(e) => set_start_radius(e.target.value)} className="border_bot">
                <IonSelectOption value={1}>1</IonSelectOption>
                <IonSelectOption value={2}>2</IonSelectOption>
                <IonSelectOption value={3}>3</IonSelectOption>
                <IonSelectOption value={4}>4</IonSelectOption>
                <IonSelectOption value={5}>5</IonSelectOption>
                <IonSelectOption value={10}>10</IonSelectOption>
                <IonSelectOption value={15}>15</IonSelectOption>
                <IonSelectOption value={20}>20</IonSelectOption>
                <IonSelectOption value={25}>25</IonSelectOption>
              </IonSelect>
              <br/>
              <IonLabel>End Address
                <IonSelect interface="action-sheet" placeholder="End Address" value={end_addr_idx} onIonChange={(e) => {
                  set_end_addr_idx(e.detail.value)
                }}
                           interfaceOptions={{
                             size: "cover", cssClass: "ac_sh_big_button"
                           }}
                >
                  {user?.obj?.addresses && user?.obj?.addresses.map((user_address, i) => (
                    <IonSelectOption key={i} value={i}>{user_address.full_address_text_w_type}</IonSelectOption>
                  ))
                  })
                </IonSelect></IonLabel>
              <IonLabel>End Radius</IonLabel>
              <IonSelect interface="action-sheet" placeholder="End Radius" value={end_radius}
                         onIonChange={(e) => set_end_radius(e.target.value)}
                         className="border_bot"
              >
                <IonSelectOption value={1}>1</IonSelectOption>
                <IonSelectOption value={2}>2</IonSelectOption>
                <IonSelectOption value={3}>3</IonSelectOption>
                <IonSelectOption value={4}>4</IonSelectOption>
                <IonSelectOption value={5}>5</IonSelectOption>
                <IonSelectOption value={10}>10</IonSelectOption>
                <IonSelectOption value={15}>15</IonSelectOption>
                <IonSelectOption value={20}>20</IonSelectOption>
                <IonSelectOption value={25}>25</IonSelectOption>
              </IonSelect>
              <IonDatetimeS id="start_time" noyear={true} placeholder="Start Time" isDateEnabled={() => false}
                            name="start_time"
                            displayFormat="hh:mm:ss A"
                            pickerFormat="hh:mm:ss A" preferWheel="false" onIonChange={(e) => {
                console.log(`new start time: `, e.target.value)
                set_start_time(e.target.value)

              }
              }
                            minuteValues="0,15,30,45"
                            value={start_time}
              > <span slot="time-label">Start Time</span></IonDatetimeS>
              <IonDatetimeS id="end_time" noyear={true} placeholder="End Time" isDateEnabled={() => false}
                            name="end_time" displayFormat="hh:mm:ss A"
                            pickerFormat="hh:mm:ss A" preferWheel="false" onIonChange={(e) => {
                console.log(`new end time: `, e.target.value)
                set_end_time(e.target.value)
              }
              }
                            minuteValues="0,15,30,45"
                            value={end_time}
              > <span slot="time-label">End Time</span></IonDatetimeS>
              <IonLabel>Flexibility</IonLabel>
              <IonSelect interface="action-sheet" value={flex_time_min}
                         onIonChange={(e) => set_flex_time_min(e.target.value)}
                         className="border_bot"
              >
                <IonSelectOption value={0}>0</IonSelectOption>
                <IonSelectOption value={15}>15</IonSelectOption>
                <IonSelectOption value={30}>30</IonSelectOption>
                <IonSelectOption value={45}>45</IonSelectOption>
                <IonSelectOption value={60}>60</IonSelectOption>
                <IonSelectOption value={75}>75</IonSelectOption>
                <IonSelectOption value={90}>90</IonSelectOption>
                <IonSelectOption value={105}>105</IonSelectOption>
                <IonSelectOption value={120}>120</IonSelectOption>
              </IonSelect>
            </div>
            <br/>
            <IonButton expand="block" onClick={ask_md_perform_search}>
              Find A Ridematch
            </IonButton>
            <IonButton expand="block" onClick={() => {
              ask_rm_modal_ref.current?.dismiss()
            }} className="mt10">
              Cancel
            </IonButton>
          </div>
        </IonModal>
        <div id="below_segment">
          <LoadScript
            id="loaderid-123456789"
            googleMapsApiKey={GMAP_API_KEY}
            region='EN'
            version='weekly'
            onLoad={on_gmap_scr_loaded}
            // onError={onError}
            // loadingElement={Loading}
            // libraries={googleMapsLibraries}
            preventGoogleFontsLoading={false}
          >
          </LoadScript>
          {is_gmap_scr_loaded ? (
            <GoogleMap id="map" key={gmap_key} style={{
              minHeight: '35vh'
            }}
              // mapContainerStyle={containerStyle}
              // center={center}
                       zoom={12}
                       onLoad={(map) => on_react_map_loaded(map)}
                       onUnmount={onUnmount}
                       options={{streetViewControl: false, clickableIcons: false}}
            >
              { /* Child components, such as markers, info windows, etc. */}
              <></>
            </GoogleMap>) : <></>
          }
          {/*<div id="il_wrap">*/}
          {/*<IonAccordionGroup multiple="false">*/}

          <IonList id="inline_list" className={"drider_list"}>
            { //VH_Rm_List
              vh_rms.map((vh_rm, i) => <IonItem key={i} className="tiny vh" text-wrap button detail="false"
                                                onclick={() => il_focus(vh_rm.latlng)}>
                <div className="drider_list_idx"><img src="/assets/icon/vh_circle.svg" alt="vh_match"/></div>
                <IonLabel className="big_anchor">
                  {vh_rm.row_html_vpnum} <br/>
                  {vh_rm.row_html_from}<br/>
                  {vh_rm.row_html_to}<br/>
                  {vh_rm.row_html_sched_cost}<br/>
                  <IonButton class="purple_btn" size="tiny two_lines" onClick={(i) => schedule_ride_vh(i)}>Schedule<br/>Ride</IonButton>
                  <IonButton color="secondary" size="tiny two_lines" onClick={vh_learn_more}>Learn<br/>More</IonButton>
                </IonLabel>
              </IonItem>)
            }
            { //RmList
              rm_list.map((rm, i) => <IonItem key={i} className="tiny" text-wrap button detail="false"
                                              onclick={() => il_focus(rm.latlng)}>
                <div className="drider_list_idx"><span>{i + 1}</span></div>
                <IonLabel className="big_anchor" text-wrap
                          innerHTML={[rm.row_html_1, rm.email_phones_inline, rm.row_html_2].filter(h => h > '').join('<br/>')}></IonLabel>
              </IonItem>)
            }

          </IonList>
          {/*</IonAccordionGroup>*/}

        </div>
        {/*</div>*/}
      </IonContent>
    </IonPage>
  )
}

export default withRouter(Rm);
