import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {Fragment, useEffect, useState} from "react"
import $ from 'jquery'
import Menu from "../components/Menu"
import {withRouter} from "react-router-dom"

// const Create: React.FC = () => {
function Create({app}){
  const [username, setUsername] = useState([])
  const [pw, setPw] = useState([])
  const [remember_pw, setRemember_pw] = useState([])
  useEffect(() => {
    $('ion-tab-bar').hide()
  });
  return (
    <IonPage id="create" className="page_routed">
      <IonContent fullscreen>
        <Menu app={app} title="Create Account" hide_hamburger={true} back_to_login={true}></Menu>
        <div id="content" className="has_pad">
          <div id="login_box">
            <br/>
            Create your new account
            <IonList>
              <IonItem>
                <IonInput value={username} placeholder="Username"></IonInput>
              </IonItem>

              <IonItem>
                <IonInput value={pw} placeholder="Password"></IonInput>
              </IonItem>

              <IonButton expand="block">Create Account</IonButton>

            </IonList>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default withRouter(Create)
