import {
  IonAccordion, IonAccordionGroup,
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToggle,
  useIonAlert,
  useIonToast
} from '@ionic/react';
import {Device} from '@capacitor/device'
import {Fragment, useContext, useEffect, useState} from "react"
import $ from 'jquery'
import {useHistory, useLocation} from "react-router"
import {input_blurred, input_focused} from "../HelperIonic"
import {IonDatetimeS} from "../components/ion-datetime-s-react"
import {BiometryType, NativeBiometric} from "capacitor-native-biometric"
import {MWCOG_BASE_URL} from "../mwcog/core"
import {app_alert, app_toast, goto_create, goto_dash, open_ext_url} from "../Helper"
import {fix_status_bar, performBiometricVerification} from "../App"
import AppContext from "../components/AppContext"

import ls from 'local-storage'
import {isNumber} from "lodash"
import {off, on, once, trigger} from "../event"
import {PN_SERVER} from "../env";
import APISocal from '../apis'

const apisocal = new APISocal()
apisocal.setup({url: PN_SERVER})

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import {HttpStatusCode} from "axios";

window.lsm = ls

const BASE_IE511_URL = 'https://ie511.org/iecommuter/integrate';
const COMMUTER_URL = 'https://tdm.commuterconnections.org/mwcog/'
// const GMAP_KEY = 'AIzaSyBY-L-HhMKsNOeMDqH1kJZP7hS3G2SATWQ';//iecommuter key
const GMAP_KEY = 'AIzaSyC1RpnsU0y0yPoQSg1G_GyvmBmO5i1UH5E';//carpoolnow key
const GEOLOCATION_OPTIONS = {enableHighAccuracy: true};
const is_login_and_commute_log = false
const IS_DEBUG = false
// const IS_DEBUG = true
const IS_LOCAL = false
const Fingerprint = null
const BIO_METHS = {0: '', 1: 'Touch', 2: 'FaceID', 3: 'Fingerprint', 4: 'Face Authentication'}
const GEOOPTIONS = {enableHighAccuracy: true}
const MWCOG_URL = "com.mediabeef.mwcog"

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment the previous state like here
  // is better than directly setting `setValue(value + 1)`
}

let refresh_count = 0
// const Login: React.FC = () => {
const Login = (props) => {
  const {app, Capp, location, match} = props
  const [biometric_avail, set_biometric_avail] = useState(false)
  const [biometric_meth, set_biometric_meth] = useState('FaceID')
  const [username, setUsername] = useState('')
  const [pw, setPw] = useState('')
  const [remember, setRemember] = useState(false)
  const [present_toast, dismiss_toast] = useIonToast()
  const [toWorkLegAlternateDepartureTimeString, set_toWorkLegAlternateDepartureTimeString] = useState('1888-08-08T08:08:08+00:00')
  const history = useHistory()
  // const location = useLocation()
  const context = useContext(AppContext)
  const force_update = useForceUpdate()

  const zsdf = async function () {
    /*ls('zsdf', true)
    ls('zsdf', 1)
    ls('csdf', '1')*/
    console.log(`zsdf called`)
    return 'zsdf'

    const result = await NativeBiometric.isAvailable();
    console.warn(`native bio avail: `, result)
    app_alert(app, 'native biometric avail: ' + result ? 'True' : 'False')
    if (!result.isAvailable) return;

    //const isFaceID = result.biometryType == BiometryType.FACE_ID;
    console.warn(`Biometric method `, result.biometryType)
    // app_alert(app, `Biometric method ` + result.biometryType)
    const verified = await NativeBiometric.verifyIdentity({
      reason: "Please log in using your fingerprint or face recognition",
      title: "Log in",
      subtitle: "MWCOG",
      description: "",
    })
      .then(() => true)
      .catch(() => false);
    console.warn(`verified `, verified)
    app_alert(app, 'Verification result' + verified ? 'Successful' : 'Unsuccessful')
  }

  const zsdf2 = async function () {
    console.log(`zsdf2 called`)
    await fix_status_bar()
    setRemember(false)
  }

  const zsdf3 = async function () {
    console.log(`zsdf3 called`)
    setRemember(true)
  }

  const zsdf4 = async function () {
    console.log(`zsdf4 called`)
    app_alert(app, `disable use bio called`)
  }

  const state_change_cb = async function (isActive) {
    console.log(`app.state was changed, isActive: `, isActive)
  }

  /**
   * Request permission to use push notifications
   * iOS will prompt user and return if they granted permission or not
   * Android will just grant without prompting
   **/
  const register_noti = async function () {
    console.log(`register noti called`)
    const device_info = await Device.getInfo()
    if (device_info.platform !== 'ios' && device_info.platform !== 'android') {
      console.info(`device not ios not android,skip register noti.`, device_info.platform)
      return false
    }
    //zsdf save web user
    /*let exist_user = await apisocal.g('user', {commuterId: 135791, devPlatform: 'ios'})
    if (exist_user.status == HttpStatusCode.NoContent) {//no content
      //create
      const user_save_res = await apisocal.c('user',
        {commuterId: 135791, username: 'ngxtri01', devPlatform: 'ios', pushToken: 'tokenvalue_zsdf'})
      console.log(`user save res: `, user_save_res)
    } else if (exist_user.status == 200) {
      //update
      exist_user = exist_user.data || []
      exist_user = exist_user.pop()
      const user_upd_res = await apisocal.p('user',
        {id: exist_user.id, commuterId: 135791, devPlatform: 'ios', pushToken: 'tokenvalue_zsdf02'})
      console.log(`user update result: `, user_upd_res)
    }*/
    //zsdf save web user END
    try {
      const push_perm_check_res = await PushNotifications.checkPermissions()
      console.log(`push perm check res:`, push_perm_check_res)
      const push_perm_status = push_perm_check_res?.receive
      if (push_perm_status != 'granted') {
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
          async (token) => {
            // app_toast(app, 'Push registration success' + token?.value)
            console.log('Push registration success, token:', token?.value)
            const commuter_id = app?.user?.obj?.commuter || -1
            const comm_user_name = app?.user?.obj?.commuter_data?.userName || ''
            const dev_platform = device_info?.platform || '' //ios | android | web
            //call PN_SERVER to save token
            let exist_user = await apisocal.g('user', {commuterId: commuter_id, devPlatform: dev_platform})
            if (exist_user.status == HttpStatusCode.NoContent) {//no content
              //create
              const user_save_res = await apisocal.c('user',
                {commuterId: commuter_id, username: comm_user_name, devPlatform: dev_platform, pushToken: token?.value})
              console.log(`user save res: `, user_save_res)
            } else if (exist_user.status == 200) {
              //update
              exist_user = exist_user.data || []
              exist_user = exist_user.pop()
              const user_upd_res = await apisocal.p('user',
                {id: exist_user.id, commuterId: commuter_id, devPlatform: dev_platform, pushToken: token?.value})
              console.log(`user update result: `, user_upd_res)
            }
          }
        )

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
          (error) => {
            app_toast(app, 'Error on Push Notification registration:' + JSON.stringify(error))
            console.error('Error on registration: ', error)
          }
        );

        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register()
          } else {
            console.error(`failed to request permission`)
          }
        })
      }

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification) => {
          app_toast(app, 'Push notification received: ' + JSON.stringify(notification))
          console.log('Push received: ', notification)
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification) => {
          app_toast(app, 'Push notification action performed: ' + JSON.stringify(notification))
          console.log('Push action performed: ', notification)
        }
      );
    } catch (e) {
      app_toast(app, 'Error while registering push notification: ' + JSON.stringify(e))
      console.warn(`Error in noti: `, e)
    }
  }

  useEffect(async () => {
    console.log(`use effect called`)
    console.log(`context: `)
    console.log(context.login_preload_context)
    $('ion-tab-bar').hide()
    $('ion-toolbar').addClass('hidden')
    let bio_avail = {}
    try {
      if (IS_DEBUG) bio_avail.isAvailable = true
      else bio_avail = await NativeBiometric.isAvailable()
    } catch (e) {
      //ignore bio isAvail not avail in web
    }
    // console.log(app, `Biometric type: ` + bio_avail.BiometryType)
    set_biometric_avail(bio_avail.isAvailable)
    // const isFaceID = bio_avail.biometryType == BiometryType.FACE_ID
    set_biometric_meth(BIO_METHS[bio_avail.biometryType])

    let remember = ls('remember')
    remember = (remember != '')
    setRemember(remember)
    if (remember) {
      if (ls('rem_username') > ' ') setUsername(ls('rem_username'))
      if (ls('rem_password') > ' ') setPw(ls('rem_password'))
    }
    app.initialize(remember)

  }, [match]);

// App logic.

  const change_username = function (e) {
    if (ls('remember')) ls('rem_username', e.target.value)
    // setUsername(username => e.target.value)
    input_blurred(e)
  }

  const change_pw = function (e) {
    if (ls('remember')) ls('rem_password', e.target.value)
    // setPw(pw => e.target.value)
    input_blurred(e)
  }

  const [present] = useIonAlert()
  document.querySelector('#login_box') && document.querySelector('#login_box').addEventListener('keypress', function (e) {
    if (e.key === 'Enter') {
      // code for enter
      console.log(`login box enter pressed`)
      // $('#login_btn').trigger('click')
    }
  })

  const loadcred = async function () {
    const bio_cred = await NativeBiometric.getCredentials({server: MWCOG_URL})
    app_toast(app, `bio cred ` + bio_cred.username + bio_cred.password, 5000)
  }

  return (
    <IonPage id="page_login" className="page_routed">
      <IonHeader>
        <img id="logo" src="/assets/img/big_logo.png" alt="logo"></img>
      </IonHeader>
      <IonContent fullscreen>
        <img id="hero_img" src="/assets/img/hero.jpg" alt="hero"></img>
        {/*<textarea value={ `Use_bio_to_login: ` + (use_bio_to_login ? 'Yes': 'No') } onChange={() => {}}></textarea>*/}
        <div id="content" className="has_pad">
          <div id="login_box">
            <h2>Hello!</h2>
            <span className="hidden">{refresh_count}</span>
            <IonList>
              <IonItem>
                <IonInput value={username} placeholder="Username" name="username" id="username"
                          onIonFocus={input_focused}
                          onBlur={input_blurred}
                          onKeyUp={change_username}>
                </IonInput>
              </IonItem>

              <IonItem>
                <IonInput value={pw} placeholder="Password" type="password" name="password" id="password"
                          onIonFocus={input_focused} onBlur={input_blurred}
                          onKeyUp={change_pw}></IonInput>
              </IonItem>
              <IonItem className="no_border" lines="none">
                <IonToggle checked={remember} onIonChange={(e) => {
                }}/>&nbsp;
                <IonLabel className="small_text">Remember Password</IonLabel>
              </IonItem>
              <IonButton id="login_btn" expand="block" onClick={async (e) => {
                const login_res = await app.login(e, remember, false, null, username, pw) //todob here debug why login_res does not return. Make sure register_noti is always called
                if (login_res) {
                  await register_noti()
                  goto_dash(app, history)
                }
              }}>LOG IN</IonButton>
              {/*<IonButton onClick={zsdf2}>2</IonButton>*/}
              {/*<IonButton onClick={zsdf4} id="disable_use_bio_btn">disable use bio</IonButton>*/}
            </IonList>
            {/*<IonItem href="https://tdm.commuterconnections.org/mwcog/CCRecoverPassword.jsp" target="_blank" lines="none" className="color_pri">
              <IonLabel>Forgot Password</IonLabel>
            </IonItem>*/}
            <IonItem routerLink="/forget" lines="none" className="color_pri w-50 dib small_text" detail="false">Forgot
              Password</IonItem>
            {/*<IonItem routerLink="/create" lines="none" className="color_pri dib small_text" detail="false">Create Account</IonItem>*/}
            <IonItem href="https://tdm.commuterconnections.org/mwcog/CCRegistration.jsp" rel="external" target="_blank"
                     lines="none" className="color_pri dib small_text" detail="false">Create Account</IonItem>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};


export default Login;
