import { registerPlugin } from '@capacitor/core';

import type { StatusBarMBPlugin } from './definitions';

const StatusBarMB = registerPlugin<StatusBarMBPlugin>('StatusBarMB', {
  web: () => import('./web').then(m => new m.StatusBarMBWeb()),
});

export * from './definitions';
export { StatusBarMB };
