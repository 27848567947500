// import './ExploreContainer.css';
/* tss
interface ContainerProps {
  name: string;
}*/

// const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
import {IonAlert, IonButton, IonLabel, getPlatforms} from "@ionic/react";
import APISocal from "../apis";
import {app_toast} from "../Helper";
import {useEffect, useState} from "react";

const api_vp = new APISocal()
api_vp.setup({url: `https://tdm.commuterconnections.org/vanhopprCore/vanpoolQueueApi/`})
const VANPOOL_APIKEY = 'testchangeme4'

//constants for Rider Request actions
const RR_APPROVE = 1
const RR_DENY = -1
const RR_REMOVE = -2
const RR_STATUSES = {}
RR_STATUSES[RR_APPROVE] = ['A', 'approve']
RR_STATUSES[RR_DENY] = ['D', 'deny']
RR_STATUSES[RR_REMOVE] = ['T', 'remove']

const VhRider = ({app, rider, cb}) => {
  const [addr_formatted_for_href, set_addr_formatted_for_href] = useState('')

  const [is_confirm_approve_alert_open, set_is_confirm_approve_alert_open] = useState(false)
  const [is_confirm_deny_alert_open, set_is_confirm_deny_alert_open] = useState(false)
  const [is_confirm_remove_alert_open, set_is_confirm_remove_alert_open] = useState(false)
  /**
   * Approve, deny or remove a VH req
   * @param rider
   * @param approve_deny_remove integer RR_APPROVE etc...
   */
  const approve_req = async function (rider, approve_deny_remove = RR_APPROVE) {
    const req_obj = {
      apiKey: VANPOOL_APIKEY, idRequest: rider.idRequest, idVanpool: app.user?.obj?.vanpoolID
      , status: RR_STATUSES[approve_deny_remove][0] //apiKey=testchangeme4&idRequest=2&idVanpool=1282&status=P
    }
    let update_req_status_url = `updateRequestStatus?` + (new URLSearchParams(req_obj)).toString()
    const approve_deny_remove_res = await api_vp.c(update_req_status_url, {}, true)
    if (approve_deny_remove_res) {
      await app_toast(app, `Successfully ` + RR_STATUSES[approve_deny_remove][1] + ` rider request`)
      return cb(approve_deny_remove_res)
    }
  }

  useEffect(() => {
    console.log('vhrider useeffect called')
    let new_addr_formatted_for_href
    const platform = getPlatforms()
    if (platform.includes('ios'))
      new_addr_formatted_for_href = `http://maps.apple.com/?saddr=` + rider.origin + '&daddr=' + rider.destination //or maybe maps://
    else if (platform.includes('android'))
      new_addr_formatted_for_href = `https://maps.google.com?saddr=${rider.origin}&daddr=${rider.destination}`
    else
      new_addr_formatted_for_href = `https://google.com/maps/dir/?api=1&origin=${rider.origin}&destination=${rider.destination}`
    set_addr_formatted_for_href(new_addr_formatted_for_href)
  }, [rider]);

  return (<><IonLabel>
      <b><span className="rider_name">{rider.firstName} {rider.lastName}</span></b>
      <br/>
      <div>
        <IonButton size="small" target="_blank" href={addr_formatted_for_href}
                   className="button-nano">
          MAP&nbsp;</IonButton>&nbsp;&nbsp;
        <span className="vert_align_text_top">{rider.origin} - {rider.destination}</span></div>
      <div><IonButton size="small" target="_blank" href={`tel:${rider.phone}`}
                      class="ios button button-nano button-solid ion-activatable ion-focusable ">
        CALL</IonButton>&nbsp;&nbsp;<span className="vert_align_text_top">{rider.phone}</span></div>
      {rider.status == 'P' ?
        <div className="text_center">
          <IonButton onClick={(event) =>
            set_is_confirm_approve_alert_open(true)
          } class="side_by_side left">Approve Request</IonButton>
          <IonButton onClick={(event) => set_is_confirm_deny_alert_open(true)} color="warning"
                     className="side_by_side right">
            Deny Request</IonButton>
          {/*<IonButton onClick={(event) => set_is_confirm_remove_alert_open(true)} color="danger"
                     className="side_by_side right">
            Remove Request</IonButton>*/}</div>
        : <div className="text_center">
          <IonButton onClick={(event) => set_is_confirm_remove_alert_open(true)} color="medium" className="w99">REMOVE RIDER FROM QUEUE</IonButton>
        </div>
      }
    </IonLabel>
      <IonAlert
        isOpen={is_confirm_approve_alert_open}
        header="Confirmation"
        message="Are you sure you want to APPROVE this ride request?"
        buttons={[{
          text: 'Yes',
          role: 'confirm',
          handler: () => {
          },
        }, {
          text: 'No',
          role: 'cancel',
        }]
        }
        onDidDismiss={async ({detail}) => {
          set_is_confirm_approve_alert_open(false)
          if (detail.role == 'confirm') await approve_req(rider, RR_APPROVE)
        }}
      ></IonAlert>
      <IonAlert
        isOpen={is_confirm_deny_alert_open}
        header="Confirmation"
        message="Are you sure you want to DENY this ride request?"
        buttons={[{
          text: 'Yes',
          role: 'confirm',
          handler: () => {
          },
        }, {
          text: 'No',
          role: 'cancel',
        }]
        }
        onDidDismiss={async ({detail}) => {
          set_is_confirm_deny_alert_open(false)
          if (detail.role == 'confirm') await approve_req(rider, RR_DENY)
        }}
      ></IonAlert>
      <IonAlert
        isOpen={is_confirm_remove_alert_open}
        header="Confirmation"
        message="Are you sure you want to REMOVE this ride request?"
        buttons={[{
          text: 'Yes',
          role: 'confirm',
          handler: () => {
          },
        }, {
          text: 'No',
          role: 'cancel',
        }]
        }
        onDidDismiss={async ({detail}) => {
          set_is_confirm_remove_alert_open(false)
          if (detail.role == 'confirm') await approve_req(rider, RR_REMOVE)
        }}
      ></IonAlert>
    </>
  )
};

export default VhRider;
