import {NativeBiometric} from "capacitor-native-biometric"
import moment from "moment"
import lsm from 'local-storage'
const BIO_SPAM_THOLD = 3 //secs
// const BIO_LAST_SUCCESS_SEC = 120 // 2 mins
const BIO_LAST_SUCCESS_SEC = 10
import {MWCOG_URL} from "./App";
import {IS_DEBUG, IS_VERBOSE} from "./env";
import {app_toast} from "./Helper";

export async function bio_set_cred(new_cred) {
  try {
    await NativeBiometric.setCredentials(new_cred)
  } catch (e) {
    console.error(`error `)
  }
  //now verifying
  const cred = await NativeBiometric.getCredentials({server: new_cred.server})
  console.log(`Verified saved cred: `, JSON.stringify(cred))
}

/**
 * performBiometricVerification
 * @return (boolean, string, {username, password})[]
 */
export async function performBiometricVerification(app){
  //to get around the stup*d exception when there's no credential: if the credential is empty, set it to csdf/csdf
  let old_last_bio_req_at = lsm('last_bio_req_at')
  if (! old_last_bio_req_at) old_last_bio_req_at = moment().subtract(5, 'minutes')
  else old_last_bio_req_at = moment(old_last_bio_req_at)
  lsm('last_bio_req_at', moment().format())
  if (moment().diff(old_last_bio_req_at, 'seconds') < BIO_SPAM_THOLD) {
    console.log(`I just performed bio last ${BIO_SPAM_THOLD} seconds; I won't perform again. Maybe stup*d Android think the app just resumed?`)
    // return [false, `You must wait ${BIO_SPAM_THOLD} seconds before trying biometric again`, {username: '', password: ''}]
    return [true, ``, {username: '', password: ''}]
    /*return [wls.getItem('last_bio_success') || false, `You must wait ${BIO_SPAM_THOLD} seconds before trying biometric again`, {
      username: wls.getItem('last_bio_username') || '', password: wls.getItem('last_bio_pw') || ''
    }]*/
  }
  let bio_creds, bio_auth_res = true //assume auth success, unless there's an exception thrown
  const last_bio_success = moment(lsm('last_bio_success'))
  if (last_bio_success.isValid()) {
    const time_since_last_success = moment().diff(last_bio_success, 'seconds')
    if (IS_DEBUG) {
      app_toast(app, `time since last success: `, time_since_last_success)
    }
    if (IS_VERBOSE) console.log(`time since last success: `, time_since_last_success)
    if (time_since_last_success < BIO_LAST_SUCCESS_SEC) {
      const last_bio_username = lsm('last_bio_username')
      const last_bio_pw = lsm('last_bio_pw')
      lsm('last_bio_success', moment().format()) //even though bio is skipped; mark it as successful
        // This helps prevent the stupid app_state_change event triggering bio login AFTER immediately succeeded
      return [true, `No need to verify, biometric succeeded since the last ${BIO_LAST_SUCCESS_SEC} seconds`, {username: last_bio_username, password: last_bio_pw}];
    }
  }
  //if bio succeeded in the last 5 minutes, just login
  try {
    bio_creds = await NativeBiometric.getCredentials({server: MWCOG_URL})
    if (! bio_creds || ! bio_creds.username || ! bio_creds.password) {
      console.log(`Setting csdf username`)
      await NativeBiometric.deleteCredentials({server: MWCOG_URL})
      await NativeBiometric.setCredentials({username: 'csdf', password: 'csdf', server: MWCOG_URL})
    }
  } catch (e) {
    //ignore. Maybe just missing credential, no big deal
    if (! bio_creds || ! bio_creds.username || ! bio_creds.password) {
      console.log(`Setting csdf username`)
      try {
        await NativeBiometric.deleteCredentials({server: MWCOG_URL})
        await NativeBiometric.setCredentials({username: 'csdf', password: 'csdf', server: MWCOG_URL})
      } catch (e) {
        console.warn(`Error resetting bio cred`, e)
      }
    }
  }
  try {
    // if (IS_DEBUG) return [false, 'sample failed message here', null]
    // if (IS_DEBUG && IS_DEBUG === true) return [true, null, {username: 'mediabeef2', password: 'changeme4'}] //zsdf
    // const isFaceID = result.biometryType == BiometryType.FACE_ID;
    // console.warn(`Biometric method `, isFaceID)
    // swl('isFaceID', isFaceID ? 'Has face id' : 'Has fingerprint')
    const username = $('#username').val()
    const verified = await NativeBiometric.verifyIdentity({
      reason: "Please log in using your fingerprint or face recognition",
      title: "Biometric Login",
      subtitle: `Sign in`,
      // subtitle: `Sign in for ${username}`,
      description: "",
    })
      .then((bio_verify_then) => {
        console.log(`Bio verify then `, bio_verify_then)
      })
      .catch((bio_verify_exception) => {
        console.error(`Error `, bio_verify_exception)
        bio_auth_res = false
        return [false, `Error verifying biometric finished`, {username: null, password: null}]
      });

    if (bio_auth_res == false) return [false, ``, {username: null, password: null}]
    // console.warn(`verified `, verified)
    // swl('Verification result', verified ? 'Successful' : 'Unsuccessful')

    // return [verified, ''];

    const credentials = await NativeBiometric.getCredentials({
      server: MWCOG_URL,
    });
    console.warn(`credentials `, credentials)
    lsm('last_bio_success', moment().format())
    if (credentials?.username !== 'csdf') lsm('last_bio_username', credentials?.username)
    if (credentials?.password !== 'csdf') lsm('last_bio_pw', credentials?.password)
    return [true, null, credentials]
  } catch (e) {
    lsm.remove('last_bio_success')
    lsm.remove('last_bio_username')
    lsm.remove('last_bio_pw')
    return [false, e?.toString(), {username: null, password: null}]
  }
  // return [true, `Biometric verification finished`, credentials.username, credentials.password]
}
