import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonMenu, IonMenuButton, IonTitle, IonToolbar, useIonAlert} from "@ionic/react"
import {useHistory} from "react-router"
import {app_toast, goto_dash, goto_home, logout} from "../Helper"
import {menuController} from "@ionic/core/components"
import {chevronBack} from "ionicons/icons"
import ls from "local-storage"

const Menu = function ({
                         app, title, curpage, hide_hamburger = false
                         , back_to_login = false//if true, just immediately to go home (login page). For example, at page `Create Account`, if hit `Back`, just go back to login
                         , back_to_dashboard = true
                       }) {
  const [present] = useIonAlert()
  const history = useHistory()
  return (
    <>
      <IonHeader curpage={curpage}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => {
              if (back_to_login) return goto_home(app, history)
              if (back_to_dashboard) return goto_dash(app, history)
              //else, we are probably at dashboard page. If user hits back, confirm and log out
              return present({
                cssClass: 'my-css',
                header: 'Log Out',
                message: 'Are you sure you want to log out?',
                buttons: [
                  'Cancel',
                  {
                    text: 'OK', handler: (d) => {
                      console.log('logout ok pressed')
                      ls.remove('user')
                      logout(app)
                      goto_home(app, history)
                    }
                  },
                ],
              })
              //if does not need to confirm
            }}>

              <IonIcon icon={chevronBack}></IonIcon>
            </IonButton>
            {/*<IonBackButton>*/}
            {/*  <ionicon slot="icon-only" ios="ellipsis-horizontal" md="ellipsis-vertical"></ionicon>*/}
            {/*</IonBackButton>*/}
          </IonButtons>
          <IonTitle id="menu_title" className={app.user?.obj?.isVanpoolDriver ? 'antique_white' : ''}>{title}</IonTitle>
          {(!hide_hamburger) && <IonButtons slot="primary">
            <IonMenuButton auto-hide="false" menu="top_non_dash"></IonMenuButton>
          </IonButtons>}
        </IonToolbar>
      </IonHeader>
    </>
  )
}

export default Menu
