import $ from "jquery"
import _ from "lodash"
import moment from "moment"
import ls from 'local-storage'
import { isPlatform } from '@ionic/react';


/**
 * Dismiss existing toast. Wait for dismiss to finish (300 ms?). Then spawn new toast
 * @param app
 * @param msg
 * @param duration
 * @param timeout
 * @param hide_others boolean True if you want to hide everything else - blocking toast
 * @return Object loadingController intance
 */
export async function iapp_toast(app, msg, duration = 4000, timeout = 0, hide_others = false){
  app.dismiss_toast()
  let loading = {}
  if (hide_others) {
    loading = await app.present_load({
      message: msg,
      duration: duration + timeout
    });
  }
  setTimeout(async () => {
    if (hide_others) {
      loading.present()
      return loading
    } else app.present_toast({
      buttons: [{text: 'hide', handler: () => app.dismiss_toast()}],
      message: msg,
      position: 'middle',
      duration,
      // onDidDismiss: () => console.log('toast dismissed'),
      // onWillDismiss: () => console.log('toast will dismiss'),
    })
  }, 300 + timeout)
  return loading
}

/**
 * When input is focused, make space for the popped up Keyboard
 * @param e
 * @return {string}
 */
export function input_focused(e){
  const input = e.target
  if (true != input instanceof HTMLElement) return `No element??`
  const rect = input.getBoundingClientRect();
  console.log(rect.top, rect.right, rect.bottom, rect.left);
  const offset = (window.innerHeight - rect.bottom)
  if (offset > 50) {
    if (!(isPlatform('ios')))
      $('body').addClass('offset_input')
  }
}

/**
 * When input is blurred, remove space for the popped up Keyboard
 * @param e
 * @return {string}
 */
export function input_blurred(e){
  const input = e.target
  if (true != input instanceof HTMLElement) return `No element??`
  const rect = input.getBoundingClientRect()
  console.log(rect.top, rect.right, rect.bottom, rect.left)
  const offset = (560 - rect.bottom)
  //if offset > 50
  $('body').removeClass('offset_input')
}

