import $ from "jquery"
import _ from "lodash"
import moment from "moment"
import ls from 'local-storage'
import {loadingController} from "@ionic/core"
import {menuController} from "@ionic/core/components"
import {NativeBiometric} from "capacitor-native-biometric";
import app from "./App";
import {performBiometricVerification} from "./BioHelper"
import {AppLauncher} from "@capacitor/app-launcher";
import {Device} from "@capacitor/device";

const CHEAT = "mbmb";
const MWCOG_URL = "com.mediabeef.mwcog"

let kb_input = "";
document.body.addEventListener('keypress', function (ev) {
  kb_input += ev.key
  kb_input = kb_input.trim(20)
  console.log(`Keyboard entered: `, kb_input)
  if (kb_input == CHEAT) {
    const ls_user = ls('user')
    swl('User info', JSON.stringify({
      commute_type: ls('commute_type'),
      username: ls_user?.obj.username,
      password: ls_user?.obj.password,
      idCommuter: ls_user?.obj?.commuter_data.idCommuter,
      idPool: (('undefined' != typeof app) && app?.user?.pool_id),
    }).replaceAll('"', ''))
    kb_input = "";
  }
});

// reset input when pressing esc
document.body.addEventListener('keyup', function (ev) {
  if (ev.key == '/') {
    kb_input = ""
    console.log(`Keypress cleared`)
  }
})

export async function app_confirm(app, header = '', message = '', custom_class = '', buttons = null) {
  let response = null;
  if (app.IS_LOCAL) {
    response = confirm(message);
    return response
  }
  if (app.is_notification_active) {
    console.log(`app.is_notification_active = true, refuse to show additional alert`)
    return true
  }
  const default_buttons = [
    'Cancel',
    {
      text: 'Ok', handler: (d) => {
        console.log(`ok clicked`);
        return true
      }
    },
  ]

  app.is_notification_active = true;
  return new Promise(async (resolve) => {
    const conf_btns = [{
      text: 'Yes', role: 'confirm', handler: () => {
        app.is_notification_active = false
        return resolve('yes')
      }
    }, {
      text: 'No', role: 'cancel', handler: () => {
        app.is_notification_active = false
        return resolve('no')
      }
    }, {
      text: "Don't ask again", handler: () => {
        app.is_notification_active = false
        return resolve('dont_ask_again')
      }
    }]
    const conf_res = await app.present_ion_alert({
      header,
      message,
      className: custom_class,
      buttons: conf_btns,
      onDidDismiss: (e) => {
        app.is_notification_active = false
        return 'no'
      },
    })
  })

}

/**
 * @constructor
 * @return bool
 */
export function app_alert(app, message, alertCallback = null, title = 'Alert', buttonName = 'OK') {
  app_toast(app, message, 3000, 0, false, 'warning')
}

/**
 * Set local storage. Also trigger
 * @param key
 * @param new_val
 */
export function ls_set(key, new_val) {
  ls(key, new_val)
  window.dispatchEvent(new Event('ls_set', {key}))
}

/**
 * Converts date_time to Ridematch time
 * 2022-01-01T10:00:00-08:00 to 9:00 AM
 * Strips off date and timezone
 * @param date_time
 * @param output_format
 * @param default_time
 */
export function date_time_to_rm_time(date_time, output_format = 'h:mm A', default_time = '09:00') {
  let date_time_obj, time_portion = default_time
  if (typeof date_time === 'string') {
    //strips off date and timezone
    const regexp_matches = date_time.match(/\d\d\d\d-\d\d-\d\dT(\d\d:\d\d):\d\d-\d\d:\d\d/i)
    if (regexp_matches && regexp_matches.length === 2) time_portion = regexp_matches[1] //10:00
  }
  /*if (typeof date_time === 'object') date_time_obj = Object.assign({}, ...date_time)
  else date_time_obj = date_time*/
  if (!(date_time_obj instanceof moment)) date_time_obj = new moment(time_portion, 'HH:mm').utc(true) //ignore tz
  if (false === date_time_obj.isValid()) return false
  return date_time_obj.format(output_format)
}

/**
 * - 01: When people toggle from `not using bio` to `using bio`: Behind the scene, we grab username/pw from bio.
 - 01a: if there is username/pw: proceed setting localstorage to `using bio`. DONE
 - 01b: If there's no username/pw: try grabbing from localstorage
 - 01ba: if there is username/pw from ls: set them to html inputs. proceed setting ls to `using bio`. DONE.
 - 01bb: if there's no username/pw from ls : try grabbing from html input
 - 01bba: if there's no username/pw from html inputs: throw alert: `Please enter username and password`. RETURN FALSE.
 - -01-: When people toggle from `using bio` to `not using bio`: set ls `using bio` to ‘0’

 02: Login using bio. First, authen using bio. If failed, return false. If success, grab username/pw from bio.
 02b: if there is user/pw: set html inputs value. return true and let login() handle normal logging in.

 03: login normal: When success, if bio_enabled is success, set localstorage `using bio` to true.
 * @return boolean
 * @param new_use_bio_to_login boolean
 */
export async function change_use_bio_to_login(new_use_bio_to_login, app) {
  const old_use_bio_to_login = ls('BIO_DONT_ASK_AGAIN')
  if (new_use_bio_to_login == true) {
    //01: When people toggle from `not using bio` to `using bio`: Behind the scene, we grab username/pw from bio.
    const [fingerprint_verify_res, fingerprint_verify_msg, fingerprint_verify_cred] = await performBiometricVerification(app)
    if (!fingerprint_verify_res) {
      app_alert(app, 'Authentication failed. Please try again. ' + fingerprint_verify_msg)
      ls_set('use_bio_to_login', false)
      return false
    }
    ls_set('use_bio_to_login', true)
    return true

    /*

    const {username: bio_username, password: bio_password} = fingerprint_verify_cred
    // const cred_fr_bio = await NativeBiometric.getCredentials({server: MWCOG_URL})
    //01a: if there is username/pw: proceed setting localstorage to `using bio`. DONE
    if (fingerprint_verify_cred
      && fingerprint_verify_cred.username && fingerprint_verify_cred.username > ' ' && fingerprint_verify_cred.username != 'csdf'
      && fingerprint_verify_cred.password && fingerprint_verify_cred.password > ' ' && fingerprint_verify_cred.password != 'csdf') {
      return
    }
    //01b: If there's no username/pw: try grabbing from localstorage
    const ls_username = ls('username')
    const ls_password = ls('password')
    if (ls_username && ls_username > ' ' && ls_password && ls_password > ' ') {
      ls_set('use_bio_to_login', true)
      return
    }
    //01bb: if there's no username/pw from ls :
    //01bba: try grabbing from react states
    if (username && username > ' ' && pw && pw > ' ') {
      app_alert(app, `Biometric ready`)
      ls_set('use_bio_to_login', true)
      await change_use_bio_to_login_state(true)
      const login_res = await app.login(e, remember, use_bio_to_login, change_use_bio_to_login_state, true, username, pw)
      if (login_res) {
        goto_dash(app, history)
      }
      return
    }*/
  }

  if (new_use_bio_to_login == false) {
    ls_set('use_bio_to_login', false)
    try {
      await NativeBiometric.deleteCredentials({server: MWCOG_URL}) // here debug why deleteCred doesn't seem to work
    } catch (e) {
      console.warn(`Error disabling bio`, e)
    }
    ls.remove('last_bio_success')
    ls.remove('last_bio_username')
    ls.remove('last_bio_pw')
    ls.remove('rem_username')
    ls.remove('rem_password')
    ls_set('use_bio_to_login', false)

    return true
  }
} //end change_use_bio_to_login

/**
 * Fix ionic form
 * All ion-checkbox has a child input.aux-input. Must fix them
 * @param form_obj
 */
export function fix_ionic_form(form_obj) {
  $(form_obj).find('ion-checkbox').each((i, e) => {
    let input_aux = $(e).find('input.aux-input')
    input_aux.val($(e).attr('aria-checked') == 'true')
  })
}

export function goto_home(app, history) {
  if ($('ion-modal[is-open="true"]').length > 0) return false //if modal is present, refuse navigation
  history.replace({
    pathname: '/login/88' //88 means I just came back to home screen from another screen; that means I just logged out
    , state: {prev_path: history.location?.pathname}
  })
  $('#goto_home_link').trigger('click')
  const ls = window.localStorage
  $('ion-tab-bar').hide()
  $('ion-toolbar').addClass('hidden')
  let remember = ls.getItem('remember')
  remember = (remember != '')
  app.initialize(remember)
}

export function goto_back(app, history) {
  if ($('ion-modal[is-open="true"]').length > 0) return false //if modal is present, refuse navigation
  history.push('/')
  $('ion-tab-bar').hide()
  const ls = window.localStorage
  // $('ion-tab-bar').hide()
  $('ion-toolbar').addClass('hidden')
  let remember = ls.getItem('remember')
  remember = (remember != '')
  app.initialize(remember)
}

export function goto_create(app, history) {
  if ($('ion-modal[is-open="true"]').length > 0) return false //if modal is present, refuse navigation
  // history.push('/dash')
  history.push('/create')
  $('ion-tab-bar').hide()
  return false
}

export function goto_dash(app, history) {
  if ($('ion-modal[is-open="true"]').length > 0) return false //if modal is present, refuse navigation
  // history.push('/dash')
  history.replace('/dash')
  $('ion-tab-bar').show()
  // menuController.close('top_non_dash')
  $('ion-toolbar').removeClass('hidden')
}

export function goto_dash_force(app, history) {
  if ($('ion-modal[is-open="true"]').length > 0) return false //if modal is present, refuse navigation
  // history.push('/dash')
  history.push('/dash')
  $('ion-tab-bar').show()
  // menuController.close('top_non_dash')
  $('ion-toolbar').removeClass('hidden')
  $('#tab-button-dash').trigger('click')
}

export function goto_rm(app, history) {
  if ($('ion-modal[is-open="true"]').length > 0) return false //if modal is present, refuse navigation
  history.replace('/rm')
  $('ion-tab-bar').show()
  // menuController.close('top_non_dash')
  $('ion-toolbar').removeClass('hidden')
}

/**
 * Dismiss existing toast. Wait for dismiss to finish (300 ms?). Then spawn new toast
 * @param app
 * @param msg
 * @param duration
 * @param timeout
 * @param hide_others boolean True if you want to hide everything else - blocking toast
 * @param toast_color See https://ionicframework.com/docs/api/toast
 * @return Object loadingController instance
 */
export async function app_toast(app, msg, duration = 3000, timeout = 0, hide_others = false, toast_color = 'primary') {
  app.dismiss_toast()
  let loading = {}
  if (hide_others) {
    loading = await loadingController.create({
      message: msg,
      duration: duration + timeout
    });
  }
  setTimeout(async () => {
    if (hide_others) {
      loading.present()
      return loading
    } else app.present_toast({
      buttons: [{text: 'Hide', handler: () => app.dismiss_toast()}],
      message: msg,
      position: 'middle',
      duration,
      color: toast_color
      // onDidDismiss: () => console.log('toast dismissed'),
      // onWillDismiss: () => console.log('toast will dismiss'),
    })
  }, 300 + timeout)
  return loading
}

/**
 * Open external app
 * @param package_name_andr e.g. com.futuremobilitylabs.incentrip
 * @param ios_custom_url e.g. carpoolnow (no need for the ://)
 * @param package_name_ios e.g. com.futuremobilitylabs.app.incentrip. Not necessarily same as android package name
 * @param itunes_app_url string E.g. incentrip/id1370576636
 * @param app
 * @return {Promise<void>}
 */
export async function open_ext_url(package_name_andr, ios_custom_url, package_name_ios, itunes_app_url, app) {
  const device_os = await Device.getInfo()
  const platform = device_os?.platform || 'web'
  console.log(`platform: `, platform)
  let package_name_or_custom_url = package_name_andr,
    store_url = `https://play.google.com/store/apps/details?id=` + package_name_andr
  let url_open_res
  switch (platform) {
    case 'android':
      url_open_res = await AppLauncher.openUrl({url: `${package_name_or_custom_url}://`})
      if (!url_open_res || typeof url_open_res !== 'object' || !url_open_res.completed) {
        console.warn(`Failed opening external package. package not installed?`)
        window.open(store_url, '_system', 'location=yes')
      } else {
        app_toast(app, `Opening external app..`)
      }
      break
    case 'ios':
      // store_url = 'https://apps.apple.com/us/app/' + itunes_app_url;
      store_url = 'itms-apps://itunes.apple.com/app/' + itunes_app_url
      url_open_res = false
      let can_open_url
      try {
        can_open_url = await AppLauncher.canOpenUrl({url: `${ios_custom_url}`})
        if (can_open_url && can_open_url.value)
          url_open_res = await AppLauncher.openUrl({url: `${ios_custom_url}`})
      } catch (e) {
        console.warn(`Failed opening external package. package not installed?`)
        window.open(store_url, '_system', 'location=yes')
      }
      if (!url_open_res || typeof url_open_res !== 'object' || !url_open_res.completed) {
        console.warn(`Failed opening external package. package not installed?`)
        window.open(store_url, '_system', 'location=yes')
      }
      app_toast(app, `Opening external app..`)
      break;
    case 'web':
      app_toast(app, `Opening external app..`)
      window.open(store_url, '_system', 'location=yes')
      break;
    default:
      break;
  }
}


export function swl(title, msg, duration = 3000) {
  Swal.fire({
    title: title,
    html: msg,
    timer: duration,
    didOpen: () => {
      Swal.showLoading()
    },
    willClose: () => {
    }
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log('Sweet alert was closed by the timer')
    }
  })

}

export function app_confirm_old(app, message, header = 'Please confirm', callback) {
  const alert_e = document.createElement('ion-alert');
  alert_e.header = '[[header]]';
  alert_e.message = '[[message]]'
  alert_e.buttons = [
    {
      text: 'Cancel',
      role: 'cancel',
      cssClass: 'secondary',
      id: 'cancel-button',
      handler: () => {
        console.log('Confirm Cancelled');
      }
    }, {
      text: 'OK',
      id: 'confirm-button',
      handler: () => {
        console.log('Confirm Okay')
      }
    }
  ];

  document.body.appendChild(alert_e);
  alert_e.present();
}

/*export function app_confirm(app, message, header = 'Please confirm'){
  const alert_e = document.createElement('ion-alert');
  alert_e.header = '[[header]]';
  alert_e.message = '[[message]]'
  alert_e.buttons = [
    {
      text: 'Cancel',
      role: 'cancel',
      cssClass: 'secondary',
      id: 'cancel-button',
      handler: () => {
        console.log('Confirm Cancelled');
      }
    }, {
      text: 'OK',
      id: 'confirm-button',
      handler: () => {
        console.log('Confirm Okay')
      }
    }
  ];

  document.body.appendChild(alert_e);
  return alert_e.present()
}*/


export function format_phone_num(phone_num) {
  if (!(typeof phone_num !== 'undefined' && phone_num !== '--' && phone_num !== '')) {
    return false;
  }
  return phone_num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

}

export function hide_loading() {
  console.log(`todo`)
}

export function hide_top_menu() {
  if (menuController.isOpen('top_non_dash')) menuController.close('top_non_dash')
}

export function dismiss_active_modals() {
  const popoverOrModals = $('ion-modal.show-modal');
  if (popoverOrModals) {
    for (const p of popoverOrModals) {
      p.dismiss()
    }
  }
}

export function isdef(var_name) {
  return (typeof window[var_name] != "undefined");
}

/**
 * Returns non-empty string, e.g. not null, not ''
 * @param str
 * @returns {boolean}
 */
export function is_nonempty_str(str) {
  return (typeof str !== "undefined") && (str !== null) &&
    (typeof str.valueOf() === "string") && (str.length > 0);
}

/**
 * Detect whether the element is within view. For example, is the video element fully in view?
 * @param allowance_top Extra top in pixel. For example, element can scroll off the top for an extra 50px and still be considered in view
 * @param allowance_bottom Extra bottom
 * @param ele Element. Default to the caller (this)
 * @returns {boolean}
 */
export function isOnScreen(allowance_top = 0, allowance_bottom = allowance_top, ele) {
  if (typeof this == "object" && (!ele || typeof ele !== "object")) ele = this
  const win = $(window);
  if (!ele || !ele[0]) return false //element doesn't even exist

  const rect = ele[0].getBoundingClientRect()
  if (rect.top < (0 - allowance_top)) return false // top is over-the-top
  const vh = win.height()
  if ((rect.top + ele.height()) > (vh + allowance_bottom)) return false //bottom is under-the-bottom
  return true
}

/**
 * @param time
 * @returns moment
 */
export function military_time_to_moment(time) {
  if (typeof time !== 'string') {
    return false;
  }
  time = time.trim();
  return moment(time, 'hhmma');
}

/**
 * @param time 2300
 * @returns moment
 */
export function mil_time_to_moment(time) {
  if (typeof time !== 'string') {
    return false;
  }
  time = time.trim();
  return moment(time, 'HHmm');
}

/**
 * @param time 2300
 * @returns moment
 */
export function mil_time_to_iso(time) {
  const m = mil_time_to_moment(time)
  if (m === false) return false
  return m.format()
}

export function logout(app) {
  const ls = window.localStorage
  ls.setItem('justLoggedIn', 0);
  ls.setItem('commuter_data', '');
  ls.setItem('user', '');
  ls.setItem('username', '')
  ls.setItem('password', '')
  ls.setItem('hashedPassword', '')
  $('#start_address').val('').text('');
  $('#end_address').val('').text('');
  $('body').removeClass('offset_input')
  if (app && app.user && app.user.reset) {
    app.user.reset();
    app.user = {}
  }
  // if (navigator && navigator.geolocation && navigator.geolocation.getCurrentPosition) navigator.geolocation.getCurrentPosition(app.geolocation.onSuccess, app.geolocation.onError, window.GEOLOCATION_OPTIONS);
  //11/4/22 b3r temp disable geolocation
}

//print a LatLng object
export function p(latlng) {
  if (typeof latlng !== 'object' || !(latlng instanceof google?.maps?.LatLng)) {
    console.log(`latlng not a LatLng object`)
    return false
  }
  console.log(`Lat: `, latlng.lat(), `; Lng: `, latlng.lng())
  return true
}

export function Reachability() {
  this.IsNotConnected = function () {
    return false
  };
}

export function show_loading() {
  console.log(`todo show loading`)
}

/**
 * show a toast msg
 * @param msg
 * @param duration
 */
export function show_loading_toast(msg, duration = 1) {
  console.log(`todo show loading`)
}

export function showAlert() {
  this.alertController.create({
    header: 'Alert',
    subHeader: 'Subtitle for alert',
    message: 'This is an alert message.',
    buttons: ['OK']
  }).then(res => {

    res.present();

  });

}

export function title_case(str) {
  return str.replace(/\w+/g, _.capitalize)
}

/**
 * geocode
 * @param addr_str String
 * @return {Promise<Object>}
 */
export async function geocode(addr_str) {
  if (!(google?.maps?.Geocoder)) return {lat: false, lng: false}
  if (!(window.geocoder instanceof google.maps.Geocoder)) window.geocoder = new google.maps.Geocoder()
  return new Promise((resolve, reject) => {
    window.geocoder.geocode({address: addr_str}, (result) => {
      result = result.pop()
      if (typeof result !== "object" || !result.hasOwnProperty('geometry')) return resolve({lat: false, lng: false})
      let geo = result.geometry
      if (!geo.hasOwnProperty('location')) return resolve({lat: false, lng: false})
      let location = geo.location
      return resolve({lat: location.lat(), lng: location.lng()})
    })
  })
}
